// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1TeamUsersUseridMutationResponse,
  DeleteV1TeamUsersUseridPathParams,
  DeleteV1TeamUsersUseridHeaderParams,
  DeleteV1TeamUsersUserid401,
  DeleteV1TeamUsersUserid403,
} from '../models/DeleteV1TeamUsersUserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1TeamUsersUseridClient = typeof client<
  DeleteV1TeamUsersUseridMutationResponse,
  DeleteV1TeamUsersUserid401 | DeleteV1TeamUsersUserid403,
  never
>
type DeleteV1TeamUsersUserid = {
  data: DeleteV1TeamUsersUseridMutationResponse
  error: DeleteV1TeamUsersUserid401 | DeleteV1TeamUsersUserid403
  request: never
  pathParams: DeleteV1TeamUsersUseridPathParams
  queryParams: never
  headerParams: DeleteV1TeamUsersUseridHeaderParams
  response: Awaited<ReturnType<DeleteV1TeamUsersUseridClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1TeamUsersUseridClient>[0]>
    return: Awaited<ReturnType<DeleteV1TeamUsersUseridClient>>
  }
}
/**
 * @link /v1/team/users/:userId
 */
export function useDeleteV1TeamUsersUserid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1TeamUsersUserid['response'],
      DeleteV1TeamUsersUserid['error'],
      {
        userId: DeleteV1TeamUsersUseridPathParams['userId']
        headers?: DeleteV1TeamUsersUserid['headerParams']
      }
    >
    client?: DeleteV1TeamUsersUserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ userId, headers }) => {
      const res = await client<
        DeleteV1TeamUsersUserid['data'],
        DeleteV1TeamUsersUserid['error'],
        DeleteV1TeamUsersUserid['request']
      >({
        method: 'delete',
        url: `/v1/team/users/${userId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
