// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1BiMembershipsResultsMutationRequest,
  PostV1BiMembershipsResultsMutationResponse,
  PostV1BiMembershipsResultsHeaderParams,
  PostV1BiMembershipsResults401,
  PostV1BiMembershipsResults403,
} from '../models/PostV1BiMembershipsResults'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1BiMembershipsResultsClient = typeof client<
  PostV1BiMembershipsResultsMutationResponse,
  PostV1BiMembershipsResults401 | PostV1BiMembershipsResults403,
  PostV1BiMembershipsResultsMutationRequest
>
type PostV1BiMembershipsResults = {
  data: PostV1BiMembershipsResultsMutationResponse
  error: PostV1BiMembershipsResults401 | PostV1BiMembershipsResults403
  request: PostV1BiMembershipsResultsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1BiMembershipsResultsHeaderParams
  response: Awaited<ReturnType<PostV1BiMembershipsResultsClient>>
  client: {
    parameters: Partial<Parameters<PostV1BiMembershipsResultsClient>[0]>
    return: Awaited<ReturnType<PostV1BiMembershipsResultsClient>>
  }
}
export const postV1BiMembershipsResultsQueryKey = (
  data: PostV1BiMembershipsResults['request'],
) => [{ url: '/v1/bi/memberships/results' }, ...(data ? [data] : [])] as const
export type PostV1BiMembershipsResultsQueryKey = ReturnType<
  typeof postV1BiMembershipsResultsQueryKey
>
export function postV1BiMembershipsResultsQueryOptions(
  data: PostV1BiMembershipsResults['request'],
  headers?: PostV1BiMembershipsResults['headerParams'],
  options: PostV1BiMembershipsResults['client']['parameters'] = {},
) {
  const queryKey = postV1BiMembershipsResultsQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiMembershipsResults['data'],
        PostV1BiMembershipsResults['error']
      >({
        method: 'post',
        url: `/v1/bi/memberships/results`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/memberships/results
 */
export function usePostV1BiMembershipsResults<
  TData = PostV1BiMembershipsResults['response'],
  TQueryData = PostV1BiMembershipsResults['response'],
  TQueryKey extends QueryKey = PostV1BiMembershipsResultsQueryKey,
>(
  data: PostV1BiMembershipsResults['request'],
  headers?: PostV1BiMembershipsResults['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1BiMembershipsResults['response'],
        PostV1BiMembershipsResults['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1BiMembershipsResults['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1BiMembershipsResults['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1BiMembershipsResultsQueryKey(data)
  const query = useQuery({
    ...(postV1BiMembershipsResultsQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1BiMembershipsResults['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1BiMembershipsResultsSuspenseQueryKey = (
  data: PostV1BiMembershipsResults['request'],
) => [{ url: '/v1/bi/memberships/results' }, ...(data ? [data] : [])] as const
export type PostV1BiMembershipsResultsSuspenseQueryKey = ReturnType<
  typeof postV1BiMembershipsResultsSuspenseQueryKey
>
export function postV1BiMembershipsResultsSuspenseQueryOptions(
  data: PostV1BiMembershipsResults['request'],
  headers?: PostV1BiMembershipsResults['headerParams'],
  options: PostV1BiMembershipsResults['client']['parameters'] = {},
) {
  const queryKey = postV1BiMembershipsResultsSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiMembershipsResults['data'],
        PostV1BiMembershipsResults['error']
      >({
        method: 'post',
        url: `/v1/bi/memberships/results`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/memberships/results
 */
export function usePostV1BiMembershipsResultsSuspense<
  TData = PostV1BiMembershipsResults['response'],
  TQueryKey extends QueryKey = PostV1BiMembershipsResultsSuspenseQueryKey,
>(
  data: PostV1BiMembershipsResults['request'],
  headers?: PostV1BiMembershipsResults['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1BiMembershipsResults['response'],
        PostV1BiMembershipsResults['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1BiMembershipsResults['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1BiMembershipsResults['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1BiMembershipsResultsSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1BiMembershipsResultsSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1BiMembershipsResults['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
