// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1BillingsAccountidSubscriptionsQueryResponse,
  GetV1BillingsAccountidSubscriptionsPathParams,
  GetV1BillingsAccountidSubscriptions401,
  GetV1BillingsAccountidSubscriptions403,
} from '../models/GetV1BillingsAccountidSubscriptions'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1BillingsAccountidSubscriptionsClient = typeof client<
  GetV1BillingsAccountidSubscriptionsQueryResponse,
  | GetV1BillingsAccountidSubscriptions401
  | GetV1BillingsAccountidSubscriptions403,
  never
>
type GetV1BillingsAccountidSubscriptions = {
  data: GetV1BillingsAccountidSubscriptionsQueryResponse
  error:
    | GetV1BillingsAccountidSubscriptions401
    | GetV1BillingsAccountidSubscriptions403
  request: never
  pathParams: GetV1BillingsAccountidSubscriptionsPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1BillingsAccountidSubscriptionsClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1BillingsAccountidSubscriptionsClient>[0]
    >
    return: Awaited<ReturnType<GetV1BillingsAccountidSubscriptionsClient>>
  }
}
export const getV1BillingsAccountidSubscriptionsQueryKey = (
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
) =>
  [
    {
      url: '/v1/billings/:accountId/subscriptions',
      params: { accountId: accountId },
    },
  ] as const
export type GetV1BillingsAccountidSubscriptionsQueryKey = ReturnType<
  typeof getV1BillingsAccountidSubscriptionsQueryKey
>
export function getV1BillingsAccountidSubscriptionsQueryOptions(
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
  options: GetV1BillingsAccountidSubscriptions['client']['parameters'] = {},
) {
  const queryKey = getV1BillingsAccountidSubscriptionsQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BillingsAccountidSubscriptions['data'],
        GetV1BillingsAccountidSubscriptions['error']
      >({
        method: 'get',
        url: `/v1/billings/${accountId}/subscriptions`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/billings/:accountId/subscriptions
 */
export function useGetV1BillingsAccountidSubscriptions<
  TData = GetV1BillingsAccountidSubscriptions['response'],
  TQueryData = GetV1BillingsAccountidSubscriptions['response'],
  TQueryKey extends QueryKey = GetV1BillingsAccountidSubscriptionsQueryKey,
>(
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1BillingsAccountidSubscriptions['response'],
        GetV1BillingsAccountidSubscriptions['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1BillingsAccountidSubscriptions['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1BillingsAccountidSubscriptions['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1BillingsAccountidSubscriptionsQueryKey(accountId)
  const query = useQuery({
    ...(getV1BillingsAccountidSubscriptionsQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1BillingsAccountidSubscriptions['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1BillingsAccountidSubscriptionsSuspenseQueryKey = (
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
) =>
  [
    {
      url: '/v1/billings/:accountId/subscriptions',
      params: { accountId: accountId },
    },
  ] as const
export type GetV1BillingsAccountidSubscriptionsSuspenseQueryKey = ReturnType<
  typeof getV1BillingsAccountidSubscriptionsSuspenseQueryKey
>
export function getV1BillingsAccountidSubscriptionsSuspenseQueryOptions(
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
  options: GetV1BillingsAccountidSubscriptions['client']['parameters'] = {},
) {
  const queryKey =
    getV1BillingsAccountidSubscriptionsSuspenseQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BillingsAccountidSubscriptions['data'],
        GetV1BillingsAccountidSubscriptions['error']
      >({
        method: 'get',
        url: `/v1/billings/${accountId}/subscriptions`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/billings/:accountId/subscriptions
 */
export function useGetV1BillingsAccountidSubscriptionsSuspense<
  TData = GetV1BillingsAccountidSubscriptions['response'],
  TQueryKey extends
    QueryKey = GetV1BillingsAccountidSubscriptionsSuspenseQueryKey,
>(
  accountId: GetV1BillingsAccountidSubscriptionsPathParams['accountId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1BillingsAccountidSubscriptions['response'],
        GetV1BillingsAccountidSubscriptions['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1BillingsAccountidSubscriptions['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1BillingsAccountidSubscriptions['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1BillingsAccountidSubscriptionsSuspenseQueryKey(accountId)
  const query = useSuspenseQuery({
    ...(getV1BillingsAccountidSubscriptionsSuspenseQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1BillingsAccountidSubscriptions['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
