// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsResponseRateBenchmarkMutationRequest,
  PostV1ResultsResponseRateBenchmarkMutationResponse,
  PostV1ResultsResponseRateBenchmarkHeaderParams,
  PostV1ResultsResponseRateBenchmark401,
  PostV1ResultsResponseRateBenchmark403,
} from '../models/PostV1ResultsResponseRateBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsResponseRateBenchmarkClient = typeof client<
  PostV1ResultsResponseRateBenchmarkMutationResponse,
  PostV1ResultsResponseRateBenchmark401 | PostV1ResultsResponseRateBenchmark403,
  PostV1ResultsResponseRateBenchmarkMutationRequest
>
type PostV1ResultsResponseRateBenchmark = {
  data: PostV1ResultsResponseRateBenchmarkMutationResponse
  error:
    | PostV1ResultsResponseRateBenchmark401
    | PostV1ResultsResponseRateBenchmark403
  request: PostV1ResultsResponseRateBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsResponseRateBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsResponseRateBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsResponseRateBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsResponseRateBenchmarkClient>>
  }
}
export const postV1ResultsResponseRateBenchmarkQueryKey = (
  data: PostV1ResultsResponseRateBenchmark['request'],
) =>
  [
    { url: '/v1/results/response-rate/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsResponseRateBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsResponseRateBenchmarkQueryKey
>
export function postV1ResultsResponseRateBenchmarkQueryOptions(
  data: PostV1ResultsResponseRateBenchmark['request'],
  headers?: PostV1ResultsResponseRateBenchmark['headerParams'],
  options: PostV1ResultsResponseRateBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsResponseRateBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsResponseRateBenchmark['data'],
        PostV1ResultsResponseRateBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/response-rate/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/response-rate/benchmark
 */
export function usePostV1ResultsResponseRateBenchmark<
  TData = PostV1ResultsResponseRateBenchmark['response'],
  TQueryData = PostV1ResultsResponseRateBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsResponseRateBenchmarkQueryKey,
>(
  data: PostV1ResultsResponseRateBenchmark['request'],
  headers?: PostV1ResultsResponseRateBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsResponseRateBenchmark['response'],
        PostV1ResultsResponseRateBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsResponseRateBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsResponseRateBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsResponseRateBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsResponseRateBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsResponseRateBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsResponseRateBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsResponseRateBenchmark['request'],
) =>
  [
    { url: '/v1/results/response-rate/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsResponseRateBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsResponseRateBenchmarkSuspenseQueryKey
>
export function postV1ResultsResponseRateBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsResponseRateBenchmark['request'],
  headers?: PostV1ResultsResponseRateBenchmark['headerParams'],
  options: PostV1ResultsResponseRateBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsResponseRateBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsResponseRateBenchmark['data'],
        PostV1ResultsResponseRateBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/response-rate/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/response-rate/benchmark
 */
export function usePostV1ResultsResponseRateBenchmarkSuspense<
  TData = PostV1ResultsResponseRateBenchmark['response'],
  TQueryKey extends
    QueryKey = PostV1ResultsResponseRateBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsResponseRateBenchmark['request'],
  headers?: PostV1ResultsResponseRateBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsResponseRateBenchmark['response'],
        PostV1ResultsResponseRateBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsResponseRateBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1ResultsResponseRateBenchmark['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsResponseRateBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsResponseRateBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsResponseRateBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
