// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidQueryResponse,
  GetV1SurveysSurveyidPathParams,
  GetV1SurveysSurveyidHeaderParams,
  GetV1SurveysSurveyid401,
  GetV1SurveysSurveyid403,
} from '../models/GetV1SurveysSurveyid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidClient = typeof client<
  GetV1SurveysSurveyidQueryResponse,
  GetV1SurveysSurveyid401 | GetV1SurveysSurveyid403,
  never
>
type GetV1SurveysSurveyid = {
  data: GetV1SurveysSurveyidQueryResponse
  error: GetV1SurveysSurveyid401 | GetV1SurveysSurveyid403
  request: never
  pathParams: GetV1SurveysSurveyidPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysSurveyidClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysSurveyidClient>>
  }
}
export const getV1SurveysSurveyidQueryKey = (
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
) => [{ url: '/v1/surveys/:surveyId', params: { surveyId: surveyId } }] as const
export type GetV1SurveysSurveyidQueryKey = ReturnType<
  typeof getV1SurveysSurveyidQueryKey
>
export function getV1SurveysSurveyidQueryOptions(
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
  headers?: GetV1SurveysSurveyid['headerParams'],
  options: GetV1SurveysSurveyid['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyid['data'],
        GetV1SurveysSurveyid['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId
 */
export function useGetV1SurveysSurveyid<
  TData = GetV1SurveysSurveyid['response'],
  TQueryData = GetV1SurveysSurveyid['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidQueryKey,
>(
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
  headers?: GetV1SurveysSurveyid['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyid['response'],
        GetV1SurveysSurveyid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SurveysSurveyidQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
) => [{ url: '/v1/surveys/:surveyId', params: { surveyId: surveyId } }] as const
export type GetV1SurveysSurveyidSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidSuspenseQueryKey
>
export function getV1SurveysSurveyidSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
  headers?: GetV1SurveysSurveyid['headerParams'],
  options: GetV1SurveysSurveyid['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyid['data'],
        GetV1SurveysSurveyid['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId
 */
export function useGetV1SurveysSurveyidSuspense<
  TData = GetV1SurveysSurveyid['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidPathParams['surveyId'],
  headers?: GetV1SurveysSurveyid['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyid['response'],
        GetV1SurveysSurveyid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysSurveyid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SurveysSurveyidSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1SurveysSurveyid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
