// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1ManualDataCollectionsDatacollectionidMutationResponse,
  DeleteV1ManualDataCollectionsDatacollectionidPathParams,
  DeleteV1ManualDataCollectionsDatacollectionidHeaderParams,
  DeleteV1ManualDataCollectionsDatacollectionid401,
  DeleteV1ManualDataCollectionsDatacollectionid403,
} from '../models/DeleteV1ManualDataCollectionsDatacollectionid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1ManualDataCollectionsDatacollectionidClient = typeof client<
  DeleteV1ManualDataCollectionsDatacollectionidMutationResponse,
  | DeleteV1ManualDataCollectionsDatacollectionid401
  | DeleteV1ManualDataCollectionsDatacollectionid403,
  never
>
type DeleteV1ManualDataCollectionsDatacollectionid = {
  data: DeleteV1ManualDataCollectionsDatacollectionidMutationResponse
  error:
    | DeleteV1ManualDataCollectionsDatacollectionid401
    | DeleteV1ManualDataCollectionsDatacollectionid403
  request: never
  pathParams: DeleteV1ManualDataCollectionsDatacollectionidPathParams
  queryParams: never
  headerParams: DeleteV1ManualDataCollectionsDatacollectionidHeaderParams
  response: Awaited<
    ReturnType<DeleteV1ManualDataCollectionsDatacollectionidClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1ManualDataCollectionsDatacollectionidClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1ManualDataCollectionsDatacollectionidClient>
    >
  }
}
/**
 * @link /v1/manual-data-collections/:dataCollectionId
 */
export function useDeleteV1ManualDataCollectionsDatacollectionid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1ManualDataCollectionsDatacollectionid['response'],
      DeleteV1ManualDataCollectionsDatacollectionid['error'],
      {
        dataCollectionId: DeleteV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId']
        headers?: DeleteV1ManualDataCollectionsDatacollectionid['headerParams']
      }
    >
    client?: DeleteV1ManualDataCollectionsDatacollectionid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ dataCollectionId, headers }) => {
      const res = await client<
        DeleteV1ManualDataCollectionsDatacollectionid['data'],
        DeleteV1ManualDataCollectionsDatacollectionid['error'],
        DeleteV1ManualDataCollectionsDatacollectionid['request']
      >({
        method: 'delete',
        url: `/v1/manual-data-collections/${dataCollectionId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
