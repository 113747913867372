// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionDateMutationRequest,
  PutV1SurveysSurveyidQuestionDateMutationResponse,
  PutV1SurveysSurveyidQuestionDatePathParams,
  PutV1SurveysSurveyidQuestionDateHeaderParams,
  PutV1SurveysSurveyidQuestionDate401,
  PutV1SurveysSurveyidQuestionDate403,
} from '../models/PutV1SurveysSurveyidQuestionDate'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionDateClient = typeof client<
  PutV1SurveysSurveyidQuestionDateMutationResponse,
  PutV1SurveysSurveyidQuestionDate401 | PutV1SurveysSurveyidQuestionDate403,
  PutV1SurveysSurveyidQuestionDateMutationRequest
>
type PutV1SurveysSurveyidQuestionDate = {
  data: PutV1SurveysSurveyidQuestionDateMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionDate401
    | PutV1SurveysSurveyidQuestionDate403
  request: PutV1SurveysSurveyidQuestionDateMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionDatePathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionDateHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionDateClient>>
  client: {
    parameters: Partial<Parameters<PutV1SurveysSurveyidQuestionDateClient>[0]>
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionDateClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/date
 */
export function usePutV1SurveysSurveyidQuestionDate(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionDate['response'],
      PutV1SurveysSurveyidQuestionDate['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionDatePathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionDate['headerParams']
        data?: PutV1SurveysSurveyidQuestionDate['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionDate['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers, data }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionDate['data'],
        PutV1SurveysSurveyidQuestionDate['error'],
        PutV1SurveysSurveyidQuestionDate['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/date`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
