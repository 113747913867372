// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultListsCommentListMutationRequest,
  PostV1ResultListsCommentListMutationResponse,
  PostV1ResultListsCommentListHeaderParams,
  PostV1ResultListsCommentList401,
  PostV1ResultListsCommentList403,
} from '../models/PostV1ResultListsCommentList'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultListsCommentListClient = typeof client<
  PostV1ResultListsCommentListMutationResponse,
  PostV1ResultListsCommentList401 | PostV1ResultListsCommentList403,
  PostV1ResultListsCommentListMutationRequest
>
type PostV1ResultListsCommentList = {
  data: PostV1ResultListsCommentListMutationResponse
  error: PostV1ResultListsCommentList401 | PostV1ResultListsCommentList403
  request: PostV1ResultListsCommentListMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultListsCommentListHeaderParams
  response: Awaited<ReturnType<PostV1ResultListsCommentListClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultListsCommentListClient>[0]>
    return: Awaited<ReturnType<PostV1ResultListsCommentListClient>>
  }
}
export const postV1ResultListsCommentListQueryKey = (
  data: PostV1ResultListsCommentList['request'],
) =>
  [{ url: '/v1/result-lists/comment-list' }, ...(data ? [data] : [])] as const
export type PostV1ResultListsCommentListQueryKey = ReturnType<
  typeof postV1ResultListsCommentListQueryKey
>
export function postV1ResultListsCommentListQueryOptions(
  data: PostV1ResultListsCommentList['request'],
  headers?: PostV1ResultListsCommentList['headerParams'],
  options: PostV1ResultListsCommentList['client']['parameters'] = {},
) {
  const queryKey = postV1ResultListsCommentListQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultListsCommentList['data'],
        PostV1ResultListsCommentList['error']
      >({
        method: 'post',
        url: `/v1/result-lists/comment-list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/result-lists/comment-list
 */
export function usePostV1ResultListsCommentList<
  TData = PostV1ResultListsCommentList['response'],
  TQueryData = PostV1ResultListsCommentList['response'],
  TQueryKey extends QueryKey = PostV1ResultListsCommentListQueryKey,
>(
  data: PostV1ResultListsCommentList['request'],
  headers?: PostV1ResultListsCommentList['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultListsCommentList['response'],
        PostV1ResultListsCommentList['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultListsCommentList['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultListsCommentList['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultListsCommentListQueryKey(data)
  const query = useQuery({
    ...(postV1ResultListsCommentListQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultListsCommentList['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultListsCommentListSuspenseQueryKey = (
  data: PostV1ResultListsCommentList['request'],
) =>
  [{ url: '/v1/result-lists/comment-list' }, ...(data ? [data] : [])] as const
export type PostV1ResultListsCommentListSuspenseQueryKey = ReturnType<
  typeof postV1ResultListsCommentListSuspenseQueryKey
>
export function postV1ResultListsCommentListSuspenseQueryOptions(
  data: PostV1ResultListsCommentList['request'],
  headers?: PostV1ResultListsCommentList['headerParams'],
  options: PostV1ResultListsCommentList['client']['parameters'] = {},
) {
  const queryKey = postV1ResultListsCommentListSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultListsCommentList['data'],
        PostV1ResultListsCommentList['error']
      >({
        method: 'post',
        url: `/v1/result-lists/comment-list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/result-lists/comment-list
 */
export function usePostV1ResultListsCommentListSuspense<
  TData = PostV1ResultListsCommentList['response'],
  TQueryKey extends QueryKey = PostV1ResultListsCommentListSuspenseQueryKey,
>(
  data: PostV1ResultListsCommentList['request'],
  headers?: PostV1ResultListsCommentList['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultListsCommentList['response'],
        PostV1ResultListsCommentList['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultListsCommentList['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultListsCommentList['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultListsCommentListSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultListsCommentListSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultListsCommentList['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
