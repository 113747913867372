// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1TestTestqueryQueryResponse,
  GetV1TestTestqueryQueryParams,
} from '../models/GetV1TestTestquery'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TestTestqueryClient = typeof client<
  GetV1TestTestqueryQueryResponse,
  Error,
  never
>
type GetV1TestTestquery = {
  data: GetV1TestTestqueryQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: GetV1TestTestqueryQueryParams
  headerParams: never
  response: Awaited<ReturnType<GetV1TestTestqueryClient>>
  client: {
    parameters: Partial<Parameters<GetV1TestTestqueryClient>[0]>
    return: Awaited<ReturnType<GetV1TestTestqueryClient>>
  }
}
export const getV1TestTestqueryQueryKey = (
  params?: GetV1TestTestquery['queryParams'],
) => [{ url: '/v1/test/testquery' }, ...(params ? [params] : [])] as const
export type GetV1TestTestqueryQueryKey = ReturnType<
  typeof getV1TestTestqueryQueryKey
>
export function getV1TestTestqueryQueryOptions(
  params?: GetV1TestTestquery['queryParams'],
  options: GetV1TestTestquery['client']['parameters'] = {},
) {
  const queryKey = getV1TestTestqueryQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TestTestquery['data'],
        GetV1TestTestquery['error']
      >({
        method: 'get',
        url: `/v1/test/testquery`,
        params,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/testquery
 */
export function useGetV1TestTestquery<
  TData = GetV1TestTestquery['response'],
  TQueryData = GetV1TestTestquery['response'],
  TQueryKey extends QueryKey = GetV1TestTestqueryQueryKey,
>(
  params?: GetV1TestTestquery['queryParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TestTestquery['response'],
        GetV1TestTestquery['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TestTestquery['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TestTestquery['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TestTestqueryQueryKey(params)
  const query = useQuery({
    ...(getV1TestTestqueryQueryOptions(
      params,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1TestTestquery['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TestTestquerySuspenseQueryKey = (
  params?: GetV1TestTestquery['queryParams'],
) => [{ url: '/v1/test/testquery' }, ...(params ? [params] : [])] as const
export type GetV1TestTestquerySuspenseQueryKey = ReturnType<
  typeof getV1TestTestquerySuspenseQueryKey
>
export function getV1TestTestquerySuspenseQueryOptions(
  params?: GetV1TestTestquery['queryParams'],
  options: GetV1TestTestquery['client']['parameters'] = {},
) {
  const queryKey = getV1TestTestquerySuspenseQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TestTestquery['data'],
        GetV1TestTestquery['error']
      >({
        method: 'get',
        url: `/v1/test/testquery`,
        params,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/testquery
 */
export function useGetV1TestTestquerySuspense<
  TData = GetV1TestTestquery['response'],
  TQueryKey extends QueryKey = GetV1TestTestquerySuspenseQueryKey,
>(
  params?: GetV1TestTestquery['queryParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TestTestquery['response'],
        GetV1TestTestquery['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TestTestquery['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1TestTestquery['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TestTestquerySuspenseQueryKey(params)
  const query = useSuspenseQuery({
    ...(getV1TestTestquerySuspenseQueryOptions(
      params,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1TestTestquery['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
