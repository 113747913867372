// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1TournamentsEventsFiltersQueryResponse,
  GetV1TournamentsEventsFiltersHeaderParams,
  GetV1TournamentsEventsFilters401,
  GetV1TournamentsEventsFilters403,
} from '../models/GetV1TournamentsEventsFilters'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TournamentsEventsFiltersClient = typeof client<
  GetV1TournamentsEventsFiltersQueryResponse,
  GetV1TournamentsEventsFilters401 | GetV1TournamentsEventsFilters403,
  never
>
type GetV1TournamentsEventsFilters = {
  data: GetV1TournamentsEventsFiltersQueryResponse
  error: GetV1TournamentsEventsFilters401 | GetV1TournamentsEventsFilters403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1TournamentsEventsFiltersHeaderParams
  response: Awaited<ReturnType<GetV1TournamentsEventsFiltersClient>>
  client: {
    parameters: Partial<Parameters<GetV1TournamentsEventsFiltersClient>[0]>
    return: Awaited<ReturnType<GetV1TournamentsEventsFiltersClient>>
  }
}
export const getV1TournamentsEventsFiltersQueryKey = () =>
  [{ url: '/v1/tournaments/events/filters' }] as const
export type GetV1TournamentsEventsFiltersQueryKey = ReturnType<
  typeof getV1TournamentsEventsFiltersQueryKey
>
export function getV1TournamentsEventsFiltersQueryOptions(
  headers?: GetV1TournamentsEventsFilters['headerParams'],
  options: GetV1TournamentsEventsFilters['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsEventsFiltersQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsEventsFilters['data'],
        GetV1TournamentsEventsFilters['error']
      >({
        method: 'get',
        url: `/v1/tournaments/events/filters`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/events/filters
 */
export function useGetV1TournamentsEventsFilters<
  TData = GetV1TournamentsEventsFilters['response'],
  TQueryData = GetV1TournamentsEventsFilters['response'],
  TQueryKey extends QueryKey = GetV1TournamentsEventsFiltersQueryKey,
>(
  headers?: GetV1TournamentsEventsFilters['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TournamentsEventsFilters['response'],
        GetV1TournamentsEventsFilters['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsEventsFilters['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TournamentsEventsFilters['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TournamentsEventsFiltersQueryKey()
  const query = useQuery({
    ...(getV1TournamentsEventsFiltersQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1TournamentsEventsFilters['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TournamentsEventsFiltersSuspenseQueryKey = () =>
  [{ url: '/v1/tournaments/events/filters' }] as const
export type GetV1TournamentsEventsFiltersSuspenseQueryKey = ReturnType<
  typeof getV1TournamentsEventsFiltersSuspenseQueryKey
>
export function getV1TournamentsEventsFiltersSuspenseQueryOptions(
  headers?: GetV1TournamentsEventsFilters['headerParams'],
  options: GetV1TournamentsEventsFilters['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsEventsFiltersSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsEventsFilters['data'],
        GetV1TournamentsEventsFilters['error']
      >({
        method: 'get',
        url: `/v1/tournaments/events/filters`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/events/filters
 */
export function useGetV1TournamentsEventsFiltersSuspense<
  TData = GetV1TournamentsEventsFilters['response'],
  TQueryKey extends QueryKey = GetV1TournamentsEventsFiltersSuspenseQueryKey,
>(
  headers?: GetV1TournamentsEventsFilters['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TournamentsEventsFilters['response'],
        GetV1TournamentsEventsFilters['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsEventsFilters['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1TournamentsEventsFilters['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TournamentsEventsFiltersSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TournamentsEventsFiltersSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1TournamentsEventsFilters['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
