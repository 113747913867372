// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionContactinfoMutationRequest,
  PutV1SurveysSurveyidQuestionContactinfoMutationResponse,
  PutV1SurveysSurveyidQuestionContactinfoPathParams,
  PutV1SurveysSurveyidQuestionContactinfoHeaderParams,
  PutV1SurveysSurveyidQuestionContactinfo401,
  PutV1SurveysSurveyidQuestionContactinfo403,
} from '../models/PutV1SurveysSurveyidQuestionContactinfo'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionContactinfoClient = typeof client<
  PutV1SurveysSurveyidQuestionContactinfoMutationResponse,
  | PutV1SurveysSurveyidQuestionContactinfo401
  | PutV1SurveysSurveyidQuestionContactinfo403,
  PutV1SurveysSurveyidQuestionContactinfoMutationRequest
>
type PutV1SurveysSurveyidQuestionContactinfo = {
  data: PutV1SurveysSurveyidQuestionContactinfoMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionContactinfo401
    | PutV1SurveysSurveyidQuestionContactinfo403
  request: PutV1SurveysSurveyidQuestionContactinfoMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionContactinfoPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionContactinfoHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionContactinfoClient>>
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionContactinfoClient>[0]
    >
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionContactinfoClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/contactinfo
 */
export function usePutV1SurveysSurveyidQuestionContactinfo(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionContactinfo['response'],
      PutV1SurveysSurveyidQuestionContactinfo['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionContactinfoPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionContactinfo['headerParams']
        data?: PutV1SurveysSurveyidQuestionContactinfo['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionContactinfo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers, data }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionContactinfo['data'],
        PutV1SurveysSurveyidQuestionContactinfo['error'],
        PutV1SurveysSurveyidQuestionContactinfo['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/contactinfo`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
