// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidQuestionTypesQueryResponse,
  GetV1SurveysSurveyidQuestionTypesPathParams,
  GetV1SurveysSurveyidQuestionTypesHeaderParams,
  GetV1SurveysSurveyidQuestionTypes401,
  GetV1SurveysSurveyidQuestionTypes403,
} from '../models/GetV1SurveysSurveyidQuestionTypes'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidQuestionTypesClient = typeof client<
  GetV1SurveysSurveyidQuestionTypesQueryResponse,
  GetV1SurveysSurveyidQuestionTypes401 | GetV1SurveysSurveyidQuestionTypes403,
  never
>
type GetV1SurveysSurveyidQuestionTypes = {
  data: GetV1SurveysSurveyidQuestionTypesQueryResponse
  error:
    | GetV1SurveysSurveyidQuestionTypes401
    | GetV1SurveysSurveyidQuestionTypes403
  request: never
  pathParams: GetV1SurveysSurveyidQuestionTypesPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidQuestionTypesHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidQuestionTypesClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysSurveyidQuestionTypesClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysSurveyidQuestionTypesClient>>
  }
}
export const getV1SurveysSurveyidQuestionTypesQueryKey = (
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/question-types',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidQuestionTypesQueryKey = ReturnType<
  typeof getV1SurveysSurveyidQuestionTypesQueryKey
>
export function getV1SurveysSurveyidQuestionTypesQueryOptions(
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidQuestionTypes['headerParams'],
  options: GetV1SurveysSurveyidQuestionTypes['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidQuestionTypesQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidQuestionTypes['data'],
        GetV1SurveysSurveyidQuestionTypes['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/question-types`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question-types
 */
export function useGetV1SurveysSurveyidQuestionTypes<
  TData = GetV1SurveysSurveyidQuestionTypes['response'],
  TQueryData = GetV1SurveysSurveyidQuestionTypes['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidQuestionTypesQueryKey,
>(
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidQuestionTypes['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidQuestionTypes['response'],
        GetV1SurveysSurveyidQuestionTypes['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidQuestionTypes['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidQuestionTypes['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidQuestionTypesQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidQuestionTypesQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyidQuestionTypes['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidQuestionTypesSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/question-types',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidQuestionTypesSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidQuestionTypesSuspenseQueryKey
>
export function getV1SurveysSurveyidQuestionTypesSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidQuestionTypes['headerParams'],
  options: GetV1SurveysSurveyidQuestionTypes['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidQuestionTypesSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidQuestionTypes['data'],
        GetV1SurveysSurveyidQuestionTypes['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/question-types`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/question-types
 */
export function useGetV1SurveysSurveyidQuestionTypesSuspense<
  TData = GetV1SurveysSurveyidQuestionTypes['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidQuestionTypesSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidQuestionTypesPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidQuestionTypes['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidQuestionTypes['response'],
        GetV1SurveysSurveyidQuestionTypes['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidQuestionTypes['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysSurveyidQuestionTypes['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidQuestionTypesSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidQuestionTypesSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidQuestionTypes['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
