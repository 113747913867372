// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1BiSettingsQueryResponse,
  GetV1BiSettingsHeaderParams,
  GetV1BiSettings401,
  GetV1BiSettings403,
} from '../models/GetV1BiSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1BiSettingsClient = typeof client<
  GetV1BiSettingsQueryResponse,
  GetV1BiSettings401 | GetV1BiSettings403,
  never
>
type GetV1BiSettings = {
  data: GetV1BiSettingsQueryResponse
  error: GetV1BiSettings401 | GetV1BiSettings403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1BiSettingsHeaderParams
  response: Awaited<ReturnType<GetV1BiSettingsClient>>
  client: {
    parameters: Partial<Parameters<GetV1BiSettingsClient>[0]>
    return: Awaited<ReturnType<GetV1BiSettingsClient>>
  }
}
export const getV1BiSettingsQueryKey = () =>
  [{ url: '/v1/bi/settings' }] as const
export type GetV1BiSettingsQueryKey = ReturnType<typeof getV1BiSettingsQueryKey>
export function getV1BiSettingsQueryOptions(
  headers?: GetV1BiSettings['headerParams'],
  options: GetV1BiSettings['client']['parameters'] = {},
) {
  const queryKey = getV1BiSettingsQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BiSettings['data'],
        GetV1BiSettings['error']
      >({
        method: 'get',
        url: `/v1/bi/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/settings
 */
export function useGetV1BiSettings<
  TData = GetV1BiSettings['response'],
  TQueryData = GetV1BiSettings['response'],
  TQueryKey extends QueryKey = GetV1BiSettingsQueryKey,
>(
  headers?: GetV1BiSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1BiSettings['response'],
        GetV1BiSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1BiSettings['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1BiSettings['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1BiSettingsQueryKey()
  const query = useQuery({
    ...(getV1BiSettingsQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1BiSettings['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1BiSettingsSuspenseQueryKey = () =>
  [{ url: '/v1/bi/settings' }] as const
export type GetV1BiSettingsSuspenseQueryKey = ReturnType<
  typeof getV1BiSettingsSuspenseQueryKey
>
export function getV1BiSettingsSuspenseQueryOptions(
  headers?: GetV1BiSettings['headerParams'],
  options: GetV1BiSettings['client']['parameters'] = {},
) {
  const queryKey = getV1BiSettingsSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BiSettings['data'],
        GetV1BiSettings['error']
      >({
        method: 'get',
        url: `/v1/bi/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/settings
 */
export function useGetV1BiSettingsSuspense<
  TData = GetV1BiSettings['response'],
  TQueryKey extends QueryKey = GetV1BiSettingsSuspenseQueryKey,
>(
  headers?: GetV1BiSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1BiSettings['response'],
        GetV1BiSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1BiSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1BiSettings['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1BiSettingsSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1BiSettingsSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1BiSettings['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
