// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1ProfilePictureMutationResponse,
  DeleteV1ProfilePicture401,
  DeleteV1ProfilePicture403,
} from '../models/DeleteV1ProfilePicture'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1ProfilePictureClient = typeof client<
  DeleteV1ProfilePictureMutationResponse,
  DeleteV1ProfilePicture401 | DeleteV1ProfilePicture403,
  never
>
type DeleteV1ProfilePicture = {
  data: DeleteV1ProfilePictureMutationResponse
  error: DeleteV1ProfilePicture401 | DeleteV1ProfilePicture403
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<DeleteV1ProfilePictureClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1ProfilePictureClient>[0]>
    return: Awaited<ReturnType<DeleteV1ProfilePictureClient>>
  }
}
/**
 * @link /v1/profile-picture
 */
export function useDeleteV1ProfilePicture(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1ProfilePicture['response'],
      DeleteV1ProfilePicture['error']
    >
    client?: DeleteV1ProfilePicture['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({}) => {
      const res = await client<
        DeleteV1ProfilePicture['data'],
        DeleteV1ProfilePicture['error'],
        void
      >({
        method: 'delete',
        url: `/v1/profile-picture`,
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
