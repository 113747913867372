// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1TeamMaoNotificationsUseridMutationRequest,
  PutV1TeamMaoNotificationsUseridMutationResponse,
  PutV1TeamMaoNotificationsUseridPathParams,
  PutV1TeamMaoNotificationsUseridHeaderParams,
  PutV1TeamMaoNotificationsUserid401,
  PutV1TeamMaoNotificationsUserid403,
} from '../models/PutV1TeamMaoNotificationsUserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1TeamMaoNotificationsUseridClient = typeof client<
  PutV1TeamMaoNotificationsUseridMutationResponse,
  PutV1TeamMaoNotificationsUserid401 | PutV1TeamMaoNotificationsUserid403,
  PutV1TeamMaoNotificationsUseridMutationRequest
>
type PutV1TeamMaoNotificationsUserid = {
  data: PutV1TeamMaoNotificationsUseridMutationResponse
  error: PutV1TeamMaoNotificationsUserid401 | PutV1TeamMaoNotificationsUserid403
  request: PutV1TeamMaoNotificationsUseridMutationRequest
  pathParams: PutV1TeamMaoNotificationsUseridPathParams
  queryParams: never
  headerParams: PutV1TeamMaoNotificationsUseridHeaderParams
  response: Awaited<ReturnType<PutV1TeamMaoNotificationsUseridClient>>
  client: {
    parameters: Partial<Parameters<PutV1TeamMaoNotificationsUseridClient>[0]>
    return: Awaited<ReturnType<PutV1TeamMaoNotificationsUseridClient>>
  }
}
/**
 * @link /v1/team/mao-notifications/:userId
 */
export function usePutV1TeamMaoNotificationsUserid(
  options: {
    mutation?: UseMutationOptions<
      PutV1TeamMaoNotificationsUserid['response'],
      PutV1TeamMaoNotificationsUserid['error'],
      {
        userId: PutV1TeamMaoNotificationsUseridPathParams['userId']
        headers?: PutV1TeamMaoNotificationsUserid['headerParams']
        data: PutV1TeamMaoNotificationsUserid['request']
      }
    >
    client?: PutV1TeamMaoNotificationsUserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ userId, data, headers }) => {
      const res = await client<
        PutV1TeamMaoNotificationsUserid['data'],
        PutV1TeamMaoNotificationsUserid['error'],
        PutV1TeamMaoNotificationsUserid['request']
      >({
        method: 'put',
        url: `/v1/team/mao-notifications/${userId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
