// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1IntegrationsProvideridSettingsMutationRequest,
  PutV1IntegrationsProvideridSettingsMutationResponse,
  PutV1IntegrationsProvideridSettingsPathParams,
  PutV1IntegrationsProvideridSettingsHeaderParams,
  PutV1IntegrationsProvideridSettings401,
  PutV1IntegrationsProvideridSettings403,
} from '../models/PutV1IntegrationsProvideridSettings'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1IntegrationsProvideridSettingsClient = typeof client<
  PutV1IntegrationsProvideridSettingsMutationResponse,
  | PutV1IntegrationsProvideridSettings401
  | PutV1IntegrationsProvideridSettings403,
  PutV1IntegrationsProvideridSettingsMutationRequest
>
type PutV1IntegrationsProvideridSettings = {
  data: PutV1IntegrationsProvideridSettingsMutationResponse
  error:
    | PutV1IntegrationsProvideridSettings401
    | PutV1IntegrationsProvideridSettings403
  request: PutV1IntegrationsProvideridSettingsMutationRequest
  pathParams: PutV1IntegrationsProvideridSettingsPathParams
  queryParams: never
  headerParams: PutV1IntegrationsProvideridSettingsHeaderParams
  response: Awaited<ReturnType<PutV1IntegrationsProvideridSettingsClient>>
  client: {
    parameters: Partial<
      Parameters<PutV1IntegrationsProvideridSettingsClient>[0]
    >
    return: Awaited<ReturnType<PutV1IntegrationsProvideridSettingsClient>>
  }
}
/**
 * @link /v1/integrations/:providerId/settings
 */
export function usePutV1IntegrationsProvideridSettings(
  options: {
    mutation?: UseMutationOptions<
      PutV1IntegrationsProvideridSettings['response'],
      PutV1IntegrationsProvideridSettings['error'],
      {
        providerId: PutV1IntegrationsProvideridSettingsPathParams['providerId']
        headers?: PutV1IntegrationsProvideridSettings['headerParams']
        data: PutV1IntegrationsProvideridSettings['request']
      }
    >
    client?: PutV1IntegrationsProvideridSettings['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ providerId, data, headers }) => {
      const res = await client<
        PutV1IntegrationsProvideridSettings['data'],
        PutV1IntegrationsProvideridSettings['error'],
        PutV1IntegrationsProvideridSettings['request']
      >({
        method: 'put',
        url: `/v1/integrations/${providerId}/settings`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
