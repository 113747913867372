// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1AccountPictureLogoMutationResponse,
  PostV1AccountPictureLogoHeaderParams,
  PostV1AccountPictureLogo401,
  PostV1AccountPictureLogo403,
} from '../models/PostV1AccountPictureLogo'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1AccountPictureLogoClient = typeof client<
  PostV1AccountPictureLogoMutationResponse,
  PostV1AccountPictureLogo401 | PostV1AccountPictureLogo403,
  FormData
>
type PostV1AccountPictureLogo = {
  data: PostV1AccountPictureLogoMutationResponse
  error: PostV1AccountPictureLogo401 | PostV1AccountPictureLogo403
  request: FormData
  pathParams: never
  queryParams: never
  headerParams: PostV1AccountPictureLogoHeaderParams
  response: Awaited<ReturnType<PostV1AccountPictureLogoClient>>
  client: {
    parameters: Partial<Parameters<PostV1AccountPictureLogoClient>[0]>
    return: Awaited<ReturnType<PostV1AccountPictureLogoClient>>
  }
}
/**
 * @link /v1/account-picture/logo
 */
export function usePostV1AccountPictureLogo(
  options: {
    mutation?: UseMutationOptions<
      PostV1AccountPictureLogo['response'],
      PostV1AccountPictureLogo['error'],
      {
        headers?: PostV1AccountPictureLogo['headerParams']
        data?: PostV1AccountPictureLogo['request']
      }
    >
    client?: PostV1AccountPictureLogo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ headers, data }) => {
      const formData = new FormData()
      if (data) {
        Object.keys(data).forEach((key) => {
          const value = data[key]
          if (
            typeof key === 'string' &&
            (typeof value === 'string' || value instanceof Blob)
          ) {
            formData.append(key, value)
          }
        })
      }
      const res = await client<
        PostV1AccountPictureLogo['data'],
        PostV1AccountPictureLogo['error'],
        PostV1AccountPictureLogo['request']
      >({
        method: 'post',
        url: `/v1/account-picture/logo`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
