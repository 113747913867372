// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ProductProductidSettingsTrialsleftQueryResponse,
  GetV1ProductProductidSettingsTrialsleftPathParams,
  GetV1ProductProductidSettingsTrialsleftHeaderParams,
  GetV1ProductProductidSettingsTrialsleft401,
  GetV1ProductProductidSettingsTrialsleft403,
} from '../models/GetV1ProductProductidSettingsTrialsleft'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ProductProductidSettingsTrialsleftClient = typeof client<
  GetV1ProductProductidSettingsTrialsleftQueryResponse,
  | GetV1ProductProductidSettingsTrialsleft401
  | GetV1ProductProductidSettingsTrialsleft403,
  never
>
type GetV1ProductProductidSettingsTrialsleft = {
  data: GetV1ProductProductidSettingsTrialsleftQueryResponse
  error:
    | GetV1ProductProductidSettingsTrialsleft401
    | GetV1ProductProductidSettingsTrialsleft403
  request: never
  pathParams: GetV1ProductProductidSettingsTrialsleftPathParams
  queryParams: never
  headerParams: GetV1ProductProductidSettingsTrialsleftHeaderParams
  response: Awaited<ReturnType<GetV1ProductProductidSettingsTrialsleftClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1ProductProductidSettingsTrialsleftClient>[0]
    >
    return: Awaited<ReturnType<GetV1ProductProductidSettingsTrialsleftClient>>
  }
}
export const getV1ProductProductidSettingsTrialsleftQueryKey = (
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
) =>
  [
    {
      url: '/v1/product/:productId/settings/trialsleft',
      params: { productId: productId },
    },
  ] as const
export type GetV1ProductProductidSettingsTrialsleftQueryKey = ReturnType<
  typeof getV1ProductProductidSettingsTrialsleftQueryKey
>
export function getV1ProductProductidSettingsTrialsleftQueryOptions(
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
  headers?: GetV1ProductProductidSettingsTrialsleft['headerParams'],
  options: GetV1ProductProductidSettingsTrialsleft['client']['parameters'] = {},
) {
  const queryKey = getV1ProductProductidSettingsTrialsleftQueryKey(productId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ProductProductidSettingsTrialsleft['data'],
        GetV1ProductProductidSettingsTrialsleft['error']
      >({
        method: 'get',
        url: `/v1/product/${productId}/settings/trialsleft`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/product/:productId/settings/trialsleft
 */
export function useGetV1ProductProductidSettingsTrialsleft<
  TData = GetV1ProductProductidSettingsTrialsleft['response'],
  TQueryData = GetV1ProductProductidSettingsTrialsleft['response'],
  TQueryKey extends QueryKey = GetV1ProductProductidSettingsTrialsleftQueryKey,
>(
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
  headers?: GetV1ProductProductidSettingsTrialsleft['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ProductProductidSettingsTrialsleft['response'],
        GetV1ProductProductidSettingsTrialsleft['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ProductProductidSettingsTrialsleft['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1ProductProductidSettingsTrialsleft['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ProductProductidSettingsTrialsleftQueryKey(productId)
  const query = useQuery({
    ...(getV1ProductProductidSettingsTrialsleftQueryOptions(
      productId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1ProductProductidSettingsTrialsleft['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ProductProductidSettingsTrialsleftSuspenseQueryKey = (
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
) =>
  [
    {
      url: '/v1/product/:productId/settings/trialsleft',
      params: { productId: productId },
    },
  ] as const
export type GetV1ProductProductidSettingsTrialsleftSuspenseQueryKey =
  ReturnType<typeof getV1ProductProductidSettingsTrialsleftSuspenseQueryKey>
export function getV1ProductProductidSettingsTrialsleftSuspenseQueryOptions(
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
  headers?: GetV1ProductProductidSettingsTrialsleft['headerParams'],
  options: GetV1ProductProductidSettingsTrialsleft['client']['parameters'] = {},
) {
  const queryKey =
    getV1ProductProductidSettingsTrialsleftSuspenseQueryKey(productId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ProductProductidSettingsTrialsleft['data'],
        GetV1ProductProductidSettingsTrialsleft['error']
      >({
        method: 'get',
        url: `/v1/product/${productId}/settings/trialsleft`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/product/:productId/settings/trialsleft
 */
export function useGetV1ProductProductidSettingsTrialsleftSuspense<
  TData = GetV1ProductProductidSettingsTrialsleft['response'],
  TQueryKey extends
    QueryKey = GetV1ProductProductidSettingsTrialsleftSuspenseQueryKey,
>(
  productId: GetV1ProductProductidSettingsTrialsleftPathParams['productId'],
  headers?: GetV1ProductProductidSettingsTrialsleft['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ProductProductidSettingsTrialsleft['response'],
        GetV1ProductProductidSettingsTrialsleft['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ProductProductidSettingsTrialsleft['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1ProductProductidSettingsTrialsleft['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ProductProductidSettingsTrialsleftSuspenseQueryKey(productId)
  const query = useSuspenseQuery({
    ...(getV1ProductProductidSettingsTrialsleftSuspenseQueryOptions(
      productId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1ProductProductidSettingsTrialsleft['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
