// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1AccountadminAccountidListdataListtypeQueryResponse,
  GetV1AccountadminAccountidListdataListtypePathParams,
  GetV1AccountadminAccountidListdataListtype401,
  GetV1AccountadminAccountidListdataListtype403,
} from '../models/GetV1AccountadminAccountidListdataListtype'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1AccountadminAccountidListdataListtypeClient = typeof client<
  GetV1AccountadminAccountidListdataListtypeQueryResponse,
  | GetV1AccountadminAccountidListdataListtype401
  | GetV1AccountadminAccountidListdataListtype403,
  never
>
type GetV1AccountadminAccountidListdataListtype = {
  data: GetV1AccountadminAccountidListdataListtypeQueryResponse
  error:
    | GetV1AccountadminAccountidListdataListtype401
    | GetV1AccountadminAccountidListdataListtype403
  request: never
  pathParams: GetV1AccountadminAccountidListdataListtypePathParams
  queryParams: never
  headerParams: never
  response: Awaited<
    ReturnType<GetV1AccountadminAccountidListdataListtypeClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1AccountadminAccountidListdataListtypeClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1AccountadminAccountidListdataListtypeClient>
    >
  }
}
export const getV1AccountadminAccountidListdataListtypeQueryKey = (
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
) =>
  [
    {
      url: '/v1/accountadmin/:accountId/listdata/:listType',
      params: { accountId: accountId, listType: listType },
    },
  ] as const
export type GetV1AccountadminAccountidListdataListtypeQueryKey = ReturnType<
  typeof getV1AccountadminAccountidListdataListtypeQueryKey
>
export function getV1AccountadminAccountidListdataListtypeQueryOptions(
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
  options: GetV1AccountadminAccountidListdataListtype['client']['parameters'] = {},
) {
  const queryKey = getV1AccountadminAccountidListdataListtypeQueryKey(
    accountId,
    listType,
  )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountadminAccountidListdataListtype['data'],
        GetV1AccountadminAccountidListdataListtype['error']
      >({
        method: 'get',
        url: `/v1/accountadmin/${accountId}/listdata/${listType}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accountadmin/:accountId/listdata/:listType
 */
export function useGetV1AccountadminAccountidListdataListtype<
  TData = GetV1AccountadminAccountidListdataListtype['response'],
  TQueryData = GetV1AccountadminAccountidListdataListtype['response'],
  TQueryKey extends
    QueryKey = GetV1AccountadminAccountidListdataListtypeQueryKey,
>(
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1AccountadminAccountidListdataListtype['response'],
        GetV1AccountadminAccountidListdataListtype['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1AccountadminAccountidListdataListtype['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1AccountadminAccountidListdataListtype['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1AccountadminAccountidListdataListtypeQueryKey(accountId, listType)
  const query = useQuery({
    ...(getV1AccountadminAccountidListdataListtypeQueryOptions(
      accountId,
      listType,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1AccountadminAccountidListdataListtype['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1AccountadminAccountidListdataListtypeSuspenseQueryKey = (
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
) =>
  [
    {
      url: '/v1/accountadmin/:accountId/listdata/:listType',
      params: { accountId: accountId, listType: listType },
    },
  ] as const
export type GetV1AccountadminAccountidListdataListtypeSuspenseQueryKey =
  ReturnType<typeof getV1AccountadminAccountidListdataListtypeSuspenseQueryKey>
export function getV1AccountadminAccountidListdataListtypeSuspenseQueryOptions(
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
  options: GetV1AccountadminAccountidListdataListtype['client']['parameters'] = {},
) {
  const queryKey = getV1AccountadminAccountidListdataListtypeSuspenseQueryKey(
    accountId,
    listType,
  )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountadminAccountidListdataListtype['data'],
        GetV1AccountadminAccountidListdataListtype['error']
      >({
        method: 'get',
        url: `/v1/accountadmin/${accountId}/listdata/${listType}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accountadmin/:accountId/listdata/:listType
 */
export function useGetV1AccountadminAccountidListdataListtypeSuspense<
  TData = GetV1AccountadminAccountidListdataListtype['response'],
  TQueryKey extends
    QueryKey = GetV1AccountadminAccountidListdataListtypeSuspenseQueryKey,
>(
  accountId: GetV1AccountadminAccountidListdataListtypePathParams['accountId'],
  listType: GetV1AccountadminAccountidListdataListtypePathParams['listType'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1AccountadminAccountidListdataListtype['response'],
        GetV1AccountadminAccountidListdataListtype['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1AccountadminAccountidListdataListtype['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1AccountadminAccountidListdataListtype['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1AccountadminAccountidListdataListtypeSuspenseQueryKey(
      accountId,
      listType,
    )
  const query = useSuspenseQuery({
    ...(getV1AccountadminAccountidListdataListtypeSuspenseQueryOptions(
      accountId,
      listType,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1AccountadminAccountidListdataListtype['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
