// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1InvitesInviteidQueryResponse,
  GetV1InvitesInviteidPathParams,
} from '../models/GetV1InvitesInviteid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1InvitesInviteidClient = typeof client<
  GetV1InvitesInviteidQueryResponse,
  Error,
  never
>
type GetV1InvitesInviteid = {
  data: GetV1InvitesInviteidQueryResponse
  error: Error
  request: never
  pathParams: GetV1InvitesInviteidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1InvitesInviteidClient>>
  client: {
    parameters: Partial<Parameters<GetV1InvitesInviteidClient>[0]>
    return: Awaited<ReturnType<GetV1InvitesInviteidClient>>
  }
}
export const getV1InvitesInviteidQueryKey = (
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
) => [{ url: '/v1/invites/:inviteId', params: { inviteId: inviteId } }] as const
export type GetV1InvitesInviteidQueryKey = ReturnType<
  typeof getV1InvitesInviteidQueryKey
>
export function getV1InvitesInviteidQueryOptions(
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
  options: GetV1InvitesInviteid['client']['parameters'] = {},
) {
  const queryKey = getV1InvitesInviteidQueryKey(inviteId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1InvitesInviteid['data'],
        GetV1InvitesInviteid['error']
      >({
        method: 'get',
        url: `/v1/invites/${inviteId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/invites/:inviteId
 */
export function useGetV1InvitesInviteid<
  TData = GetV1InvitesInviteid['response'],
  TQueryData = GetV1InvitesInviteid['response'],
  TQueryKey extends QueryKey = GetV1InvitesInviteidQueryKey,
>(
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1InvitesInviteid['response'],
        GetV1InvitesInviteid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1InvitesInviteid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1InvitesInviteid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1InvitesInviteidQueryKey(inviteId)
  const query = useQuery({
    ...(getV1InvitesInviteidQueryOptions(
      inviteId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1InvitesInviteid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1InvitesInviteidSuspenseQueryKey = (
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
) => [{ url: '/v1/invites/:inviteId', params: { inviteId: inviteId } }] as const
export type GetV1InvitesInviteidSuspenseQueryKey = ReturnType<
  typeof getV1InvitesInviteidSuspenseQueryKey
>
export function getV1InvitesInviteidSuspenseQueryOptions(
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
  options: GetV1InvitesInviteid['client']['parameters'] = {},
) {
  const queryKey = getV1InvitesInviteidSuspenseQueryKey(inviteId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1InvitesInviteid['data'],
        GetV1InvitesInviteid['error']
      >({
        method: 'get',
        url: `/v1/invites/${inviteId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/invites/:inviteId
 */
export function useGetV1InvitesInviteidSuspense<
  TData = GetV1InvitesInviteid['response'],
  TQueryKey extends QueryKey = GetV1InvitesInviteidSuspenseQueryKey,
>(
  inviteId: GetV1InvitesInviteidPathParams['inviteId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1InvitesInviteid['response'],
        GetV1InvitesInviteid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1InvitesInviteid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1InvitesInviteid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1InvitesInviteidSuspenseQueryKey(inviteId)
  const query = useSuspenseQuery({
    ...(getV1InvitesInviteidSuspenseQueryOptions(
      inviteId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1InvitesInviteid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
