// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidEmailTestsMutationRequest,
  PostV1SurveysSurveyidEmailTestsMutationResponse,
  PostV1SurveysSurveyidEmailTestsPathParams,
  PostV1SurveysSurveyidEmailTestsHeaderParams,
  PostV1SurveysSurveyidEmailTests401,
  PostV1SurveysSurveyidEmailTests403,
} from '../models/PostV1SurveysSurveyidEmailTests'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidEmailTestsClient = typeof client<
  PostV1SurveysSurveyidEmailTestsMutationResponse,
  PostV1SurveysSurveyidEmailTests401 | PostV1SurveysSurveyidEmailTests403,
  PostV1SurveysSurveyidEmailTestsMutationRequest
>
type PostV1SurveysSurveyidEmailTests = {
  data: PostV1SurveysSurveyidEmailTestsMutationResponse
  error: PostV1SurveysSurveyidEmailTests401 | PostV1SurveysSurveyidEmailTests403
  request: PostV1SurveysSurveyidEmailTestsMutationRequest
  pathParams: PostV1SurveysSurveyidEmailTestsPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidEmailTestsHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidEmailTestsClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveysSurveyidEmailTestsClient>[0]>
    return: Awaited<ReturnType<PostV1SurveysSurveyidEmailTestsClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/email-tests
 */
export function usePostV1SurveysSurveyidEmailTests(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidEmailTests['response'],
      PostV1SurveysSurveyidEmailTests['error'],
      {
        surveyId: PostV1SurveysSurveyidEmailTestsPathParams['surveyId']
        headers?: PostV1SurveysSurveyidEmailTests['headerParams']
        data: PostV1SurveysSurveyidEmailTests['request']
      }
    >
    client?: PostV1SurveysSurveyidEmailTests['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidEmailTests['data'],
        PostV1SurveysSurveyidEmailTests['error'],
        PostV1SurveysSurveyidEmailTests['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/email-tests`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
