// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1AccountPictureCoverMutationResponse,
  DeleteV1AccountPictureCoverHeaderParams,
  DeleteV1AccountPictureCover401,
  DeleteV1AccountPictureCover403,
} from '../models/DeleteV1AccountPictureCover'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1AccountPictureCoverClient = typeof client<
  DeleteV1AccountPictureCoverMutationResponse,
  DeleteV1AccountPictureCover401 | DeleteV1AccountPictureCover403,
  never
>
type DeleteV1AccountPictureCover = {
  data: DeleteV1AccountPictureCoverMutationResponse
  error: DeleteV1AccountPictureCover401 | DeleteV1AccountPictureCover403
  request: never
  pathParams: never
  queryParams: never
  headerParams: DeleteV1AccountPictureCoverHeaderParams
  response: Awaited<ReturnType<DeleteV1AccountPictureCoverClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1AccountPictureCoverClient>[0]>
    return: Awaited<ReturnType<DeleteV1AccountPictureCoverClient>>
  }
}
/**
 * @link /v1/account-picture/cover
 */
export function useDeleteV1AccountPictureCover(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1AccountPictureCover['response'],
      DeleteV1AccountPictureCover['error'],
      {
        headers?: DeleteV1AccountPictureCover['headerParams']
      }
    >
    client?: DeleteV1AccountPictureCover['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ headers }) => {
      const res = await client<
        DeleteV1AccountPictureCover['data'],
        DeleteV1AccountPictureCover['error'],
        DeleteV1AccountPictureCover['request']
      >({
        method: 'delete',
        url: `/v1/account-picture/cover`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
