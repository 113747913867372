// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse,
  DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams,
  DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridHeaderParams,
  DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid401,
  DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid403,
} from '../models/DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridClient =
  typeof client<
    DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse,
    | DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid401
    | DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid403,
    never
  >
type DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid = {
  data: DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse
  error:
    | DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid401
    | DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid403
  request: never
  pathParams: DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams
  queryParams: never
  headerParams: DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridHeaderParams
  response: Awaited<
    ReturnType<DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridClient>
    >
  }
}
/**
 * @link /v1/integrations/:providerId/notification-users/:notificationUserId
 */
export function useDeleteV1IntegrationsProvideridNotificationUsersNotificationuserid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['response'],
      DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['error'],
      {
        providerId: DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams['providerId']
        notificationUserId: DeleteV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams['notificationUserId']
        headers?: DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['headerParams']
      }
    >
    client?: DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ providerId, notificationUserId, headers }) => {
      const res = await client<
        DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['data'],
        DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['error'],
        DeleteV1IntegrationsProvideridNotificationUsersNotificationuserid['request']
      >({
        method: 'delete',
        url: `/v1/integrations/${providerId}/notification-users/${notificationUserId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
