// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidGolfGeniusIntegrationMutationRequest,
  PostV1SurveysSurveyidGolfGeniusIntegrationMutationResponse,
  PostV1SurveysSurveyidGolfGeniusIntegrationPathParams,
  PostV1SurveysSurveyidGolfGeniusIntegrationHeaderParams,
  PostV1SurveysSurveyidGolfGeniusIntegration401,
  PostV1SurveysSurveyidGolfGeniusIntegration403,
} from '../models/PostV1SurveysSurveyidGolfGeniusIntegration'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidGolfGeniusIntegrationClient = typeof client<
  PostV1SurveysSurveyidGolfGeniusIntegrationMutationResponse,
  | PostV1SurveysSurveyidGolfGeniusIntegration401
  | PostV1SurveysSurveyidGolfGeniusIntegration403,
  PostV1SurveysSurveyidGolfGeniusIntegrationMutationRequest
>
type PostV1SurveysSurveyidGolfGeniusIntegration = {
  data: PostV1SurveysSurveyidGolfGeniusIntegrationMutationResponse
  error:
    | PostV1SurveysSurveyidGolfGeniusIntegration401
    | PostV1SurveysSurveyidGolfGeniusIntegration403
  request: PostV1SurveysSurveyidGolfGeniusIntegrationMutationRequest
  pathParams: PostV1SurveysSurveyidGolfGeniusIntegrationPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidGolfGeniusIntegrationHeaderParams
  response: Awaited<
    ReturnType<PostV1SurveysSurveyidGolfGeniusIntegrationClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidGolfGeniusIntegrationClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1SurveysSurveyidGolfGeniusIntegrationClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/golf-genius/integration
 */
export function usePostV1SurveysSurveyidGolfGeniusIntegration(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidGolfGeniusIntegration['response'],
      PostV1SurveysSurveyidGolfGeniusIntegration['error'],
      {
        surveyId: PostV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId']
        headers?: PostV1SurveysSurveyidGolfGeniusIntegration['headerParams']
        data: PostV1SurveysSurveyidGolfGeniusIntegration['request']
      }
    >
    client?: PostV1SurveysSurveyidGolfGeniusIntegration['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidGolfGeniusIntegration['data'],
        PostV1SurveysSurveyidGolfGeniusIntegration['error'],
        PostV1SurveysSurveyidGolfGeniusIntegration['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/golf-genius/integration`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
