// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1GenericAccountmetricsDataentriesDataentryidQueryResponse,
  GetV1GenericAccountmetricsDataentriesDataentryidPathParams,
  GetV1GenericAccountmetricsDataentriesDataentryidHeaderParams,
  GetV1GenericAccountmetricsDataentriesDataentryid401,
  GetV1GenericAccountmetricsDataentriesDataentryid403,
} from '../models/GetV1GenericAccountmetricsDataentriesDataentryid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1GenericAccountmetricsDataentriesDataentryidClient = typeof client<
  GetV1GenericAccountmetricsDataentriesDataentryidQueryResponse,
  | GetV1GenericAccountmetricsDataentriesDataentryid401
  | GetV1GenericAccountmetricsDataentriesDataentryid403,
  never
>
type GetV1GenericAccountmetricsDataentriesDataentryid = {
  data: GetV1GenericAccountmetricsDataentriesDataentryidQueryResponse
  error:
    | GetV1GenericAccountmetricsDataentriesDataentryid401
    | GetV1GenericAccountmetricsDataentriesDataentryid403
  request: never
  pathParams: GetV1GenericAccountmetricsDataentriesDataentryidPathParams
  queryParams: never
  headerParams: GetV1GenericAccountmetricsDataentriesDataentryidHeaderParams
  response: Awaited<
    ReturnType<GetV1GenericAccountmetricsDataentriesDataentryidClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1GenericAccountmetricsDataentriesDataentryidClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1GenericAccountmetricsDataentriesDataentryidClient>
    >
  }
}
export const getV1GenericAccountmetricsDataentriesDataentryidQueryKey = (
  dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
) =>
  [
    {
      url: '/v1/generic/accountmetrics/dataentries/:dataEntryId',
      params: { dataEntryId: dataEntryId },
    },
  ] as const
export type GetV1GenericAccountmetricsDataentriesDataentryidQueryKey =
  ReturnType<typeof getV1GenericAccountmetricsDataentriesDataentryidQueryKey>
export function getV1GenericAccountmetricsDataentriesDataentryidQueryOptions(
  dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
  headers?: GetV1GenericAccountmetricsDataentriesDataentryid['headerParams'],
  options: GetV1GenericAccountmetricsDataentriesDataentryid['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsDataentriesDataentryidQueryKey(dataEntryId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsDataentriesDataentryid['data'],
        GetV1GenericAccountmetricsDataentriesDataentryid['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/dataentries/${dataEntryId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/dataentries/:dataEntryId
 */
export function useGetV1GenericAccountmetricsDataentriesDataentryid<
  TData = GetV1GenericAccountmetricsDataentriesDataentryid['response'],
  TQueryData = GetV1GenericAccountmetricsDataentriesDataentryid['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsDataentriesDataentryidQueryKey,
>(
  dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
  headers?: GetV1GenericAccountmetricsDataentriesDataentryid['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1GenericAccountmetricsDataentriesDataentryid['response'],
        GetV1GenericAccountmetricsDataentriesDataentryid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsDataentriesDataentryid['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1GenericAccountmetricsDataentriesDataentryid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsDataentriesDataentryidQueryKey(dataEntryId)
  const query = useQuery({
    ...(getV1GenericAccountmetricsDataentriesDataentryidQueryOptions(
      dataEntryId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1GenericAccountmetricsDataentriesDataentryid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey =
  (
    dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/dataentries/:dataEntryId',
        params: { dataEntryId: dataEntryId },
      },
    ] as const
export type GetV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey
  >
export function getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryOptions(
  dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
  headers?: GetV1GenericAccountmetricsDataentriesDataentryid['headerParams'],
  options: GetV1GenericAccountmetricsDataentriesDataentryid['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey(
      dataEntryId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsDataentriesDataentryid['data'],
        GetV1GenericAccountmetricsDataentriesDataentryid['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/dataentries/${dataEntryId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/dataentries/:dataEntryId
 */
export function useGetV1GenericAccountmetricsDataentriesDataentryidSuspense<
  TData = GetV1GenericAccountmetricsDataentriesDataentryid['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey,
>(
  dataEntryId: GetV1GenericAccountmetricsDataentriesDataentryidPathParams['dataEntryId'],
  headers?: GetV1GenericAccountmetricsDataentriesDataentryid['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1GenericAccountmetricsDataentriesDataentryid['response'],
        GetV1GenericAccountmetricsDataentriesDataentryid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsDataentriesDataentryid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1GenericAccountmetricsDataentriesDataentryid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryKey(
      dataEntryId,
    )
  const query = useSuspenseQuery({
    ...(getV1GenericAccountmetricsDataentriesDataentryidSuspenseQueryOptions(
      dataEntryId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1GenericAccountmetricsDataentriesDataentryid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
