// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1TournamentsDataCollectionsSettingsQueryResponse,
  GetV1TournamentsDataCollectionsSettingsHeaderParams,
  GetV1TournamentsDataCollectionsSettings401,
  GetV1TournamentsDataCollectionsSettings403,
} from '../models/GetV1TournamentsDataCollectionsSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TournamentsDataCollectionsSettingsClient = typeof client<
  GetV1TournamentsDataCollectionsSettingsQueryResponse,
  | GetV1TournamentsDataCollectionsSettings401
  | GetV1TournamentsDataCollectionsSettings403,
  never
>
type GetV1TournamentsDataCollectionsSettings = {
  data: GetV1TournamentsDataCollectionsSettingsQueryResponse
  error:
    | GetV1TournamentsDataCollectionsSettings401
    | GetV1TournamentsDataCollectionsSettings403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1TournamentsDataCollectionsSettingsHeaderParams
  response: Awaited<ReturnType<GetV1TournamentsDataCollectionsSettingsClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1TournamentsDataCollectionsSettingsClient>[0]
    >
    return: Awaited<ReturnType<GetV1TournamentsDataCollectionsSettingsClient>>
  }
}
export const getV1TournamentsDataCollectionsSettingsQueryKey = () =>
  [{ url: '/v1/tournaments/data-collections/settings' }] as const
export type GetV1TournamentsDataCollectionsSettingsQueryKey = ReturnType<
  typeof getV1TournamentsDataCollectionsSettingsQueryKey
>
export function getV1TournamentsDataCollectionsSettingsQueryOptions(
  headers?: GetV1TournamentsDataCollectionsSettings['headerParams'],
  options: GetV1TournamentsDataCollectionsSettings['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsDataCollectionsSettingsQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsDataCollectionsSettings['data'],
        GetV1TournamentsDataCollectionsSettings['error']
      >({
        method: 'get',
        url: `/v1/tournaments/data-collections/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/data-collections/settings
 */
export function useGetV1TournamentsDataCollectionsSettings<
  TData = GetV1TournamentsDataCollectionsSettings['response'],
  TQueryData = GetV1TournamentsDataCollectionsSettings['response'],
  TQueryKey extends QueryKey = GetV1TournamentsDataCollectionsSettingsQueryKey,
>(
  headers?: GetV1TournamentsDataCollectionsSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TournamentsDataCollectionsSettings['response'],
        GetV1TournamentsDataCollectionsSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsDataCollectionsSettings['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TournamentsDataCollectionsSettings['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TournamentsDataCollectionsSettingsQueryKey()
  const query = useQuery({
    ...(getV1TournamentsDataCollectionsSettingsQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1TournamentsDataCollectionsSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TournamentsDataCollectionsSettingsSuspenseQueryKey = () =>
  [{ url: '/v1/tournaments/data-collections/settings' }] as const
export type GetV1TournamentsDataCollectionsSettingsSuspenseQueryKey =
  ReturnType<typeof getV1TournamentsDataCollectionsSettingsSuspenseQueryKey>
export function getV1TournamentsDataCollectionsSettingsSuspenseQueryOptions(
  headers?: GetV1TournamentsDataCollectionsSettings['headerParams'],
  options: GetV1TournamentsDataCollectionsSettings['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsDataCollectionsSettingsSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsDataCollectionsSettings['data'],
        GetV1TournamentsDataCollectionsSettings['error']
      >({
        method: 'get',
        url: `/v1/tournaments/data-collections/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/data-collections/settings
 */
export function useGetV1TournamentsDataCollectionsSettingsSuspense<
  TData = GetV1TournamentsDataCollectionsSettings['response'],
  TQueryKey extends
    QueryKey = GetV1TournamentsDataCollectionsSettingsSuspenseQueryKey,
>(
  headers?: GetV1TournamentsDataCollectionsSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TournamentsDataCollectionsSettings['response'],
        GetV1TournamentsDataCollectionsSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsDataCollectionsSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1TournamentsDataCollectionsSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1TournamentsDataCollectionsSettingsSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TournamentsDataCollectionsSettingsSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1TournamentsDataCollectionsSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
