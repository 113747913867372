// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultListsSponsorListMutationRequest,
  PostV1ResultListsSponsorListMutationResponse,
  PostV1ResultListsSponsorListHeaderParams,
  PostV1ResultListsSponsorList401,
  PostV1ResultListsSponsorList403,
} from '../models/PostV1ResultListsSponsorList'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultListsSponsorListClient = typeof client<
  PostV1ResultListsSponsorListMutationResponse,
  PostV1ResultListsSponsorList401 | PostV1ResultListsSponsorList403,
  PostV1ResultListsSponsorListMutationRequest
>
type PostV1ResultListsSponsorList = {
  data: PostV1ResultListsSponsorListMutationResponse
  error: PostV1ResultListsSponsorList401 | PostV1ResultListsSponsorList403
  request: PostV1ResultListsSponsorListMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultListsSponsorListHeaderParams
  response: Awaited<ReturnType<PostV1ResultListsSponsorListClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultListsSponsorListClient>[0]>
    return: Awaited<ReturnType<PostV1ResultListsSponsorListClient>>
  }
}
export const postV1ResultListsSponsorListQueryKey = (
  data: PostV1ResultListsSponsorList['request'],
) =>
  [{ url: '/v1/result-lists/sponsor-list' }, ...(data ? [data] : [])] as const
export type PostV1ResultListsSponsorListQueryKey = ReturnType<
  typeof postV1ResultListsSponsorListQueryKey
>
export function postV1ResultListsSponsorListQueryOptions(
  data: PostV1ResultListsSponsorList['request'],
  headers?: PostV1ResultListsSponsorList['headerParams'],
  options: PostV1ResultListsSponsorList['client']['parameters'] = {},
) {
  const queryKey = postV1ResultListsSponsorListQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultListsSponsorList['data'],
        PostV1ResultListsSponsorList['error']
      >({
        method: 'post',
        url: `/v1/result-lists/sponsor-list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/result-lists/sponsor-list
 */
export function usePostV1ResultListsSponsorList<
  TData = PostV1ResultListsSponsorList['response'],
  TQueryData = PostV1ResultListsSponsorList['response'],
  TQueryKey extends QueryKey = PostV1ResultListsSponsorListQueryKey,
>(
  data: PostV1ResultListsSponsorList['request'],
  headers?: PostV1ResultListsSponsorList['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultListsSponsorList['response'],
        PostV1ResultListsSponsorList['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultListsSponsorList['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultListsSponsorList['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultListsSponsorListQueryKey(data)
  const query = useQuery({
    ...(postV1ResultListsSponsorListQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultListsSponsorList['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultListsSponsorListSuspenseQueryKey = (
  data: PostV1ResultListsSponsorList['request'],
) =>
  [{ url: '/v1/result-lists/sponsor-list' }, ...(data ? [data] : [])] as const
export type PostV1ResultListsSponsorListSuspenseQueryKey = ReturnType<
  typeof postV1ResultListsSponsorListSuspenseQueryKey
>
export function postV1ResultListsSponsorListSuspenseQueryOptions(
  data: PostV1ResultListsSponsorList['request'],
  headers?: PostV1ResultListsSponsorList['headerParams'],
  options: PostV1ResultListsSponsorList['client']['parameters'] = {},
) {
  const queryKey = postV1ResultListsSponsorListSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultListsSponsorList['data'],
        PostV1ResultListsSponsorList['error']
      >({
        method: 'post',
        url: `/v1/result-lists/sponsor-list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/result-lists/sponsor-list
 */
export function usePostV1ResultListsSponsorListSuspense<
  TData = PostV1ResultListsSponsorList['response'],
  TQueryKey extends QueryKey = PostV1ResultListsSponsorListSuspenseQueryKey,
>(
  data: PostV1ResultListsSponsorList['request'],
  headers?: PostV1ResultListsSponsorList['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultListsSponsorList['response'],
        PostV1ResultListsSponsorList['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultListsSponsorList['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultListsSponsorList['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultListsSponsorListSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultListsSponsorListSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultListsSponsorList['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
