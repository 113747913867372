// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1GenericAccountmetricsAccountmetricidResultsSettingsQueryResponse,
  GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams,
  GetV1GenericAccountmetricsAccountmetricidResultsSettingsHeaderParams,
  GetV1GenericAccountmetricsAccountmetricidResultsSettings401,
  GetV1GenericAccountmetricsAccountmetricidResultsSettings403,
} from '../models/GetV1GenericAccountmetricsAccountmetricidResultsSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1GenericAccountmetricsAccountmetricidResultsSettingsClient =
  typeof client<
    GetV1GenericAccountmetricsAccountmetricidResultsSettingsQueryResponse,
    | GetV1GenericAccountmetricsAccountmetricidResultsSettings401
    | GetV1GenericAccountmetricsAccountmetricidResultsSettings403,
    never
  >
type GetV1GenericAccountmetricsAccountmetricidResultsSettings = {
  data: GetV1GenericAccountmetricsAccountmetricidResultsSettingsQueryResponse
  error:
    | GetV1GenericAccountmetricsAccountmetricidResultsSettings401
    | GetV1GenericAccountmetricsAccountmetricidResultsSettings403
  request: never
  pathParams: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams
  queryParams: never
  headerParams: GetV1GenericAccountmetricsAccountmetricidResultsSettingsHeaderParams
  response: Awaited<
    ReturnType<GetV1GenericAccountmetricsAccountmetricidResultsSettingsClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1GenericAccountmetricsAccountmetricidResultsSettingsClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1GenericAccountmetricsAccountmetricidResultsSettingsClient>
    >
  }
}
export const getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/results/settings',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidResultsSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/results/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/results/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidResultsSettings<
  TData = GetV1GenericAccountmetricsAccountmetricidResultsSettings['response'],
  TQueryData = GetV1GenericAccountmetricsAccountmetricidResultsSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryKey(
      accountMetricId,
    )
  const query = useQuery({
    ...(getV1GenericAccountmetricsAccountmetricidResultsSettingsQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/results/settings',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidResultsSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/results/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/results/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidResultsSettingsSuspense<
  TData = GetV1GenericAccountmetricsAccountmetricidResultsSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidResultsSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidResultsSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidResultsSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryKey(
      accountMetricId,
    )
  const query = useSuspenseQuery({
    ...(getV1GenericAccountmetricsAccountmetricidResultsSettingsSuspenseQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidResultsSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
