// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1CommentsMutationRequest,
  PostV1CommentsMutationResponse,
  PostV1CommentsHeaderParams,
  PostV1Comments401,
  PostV1Comments403,
} from '../models/PostV1Comments'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1CommentsClient = typeof client<
  PostV1CommentsMutationResponse,
  PostV1Comments401 | PostV1Comments403,
  PostV1CommentsMutationRequest
>
type PostV1Comments = {
  data: PostV1CommentsMutationResponse
  error: PostV1Comments401 | PostV1Comments403
  request: PostV1CommentsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1CommentsHeaderParams
  response: Awaited<ReturnType<PostV1CommentsClient>>
  client: {
    parameters: Partial<Parameters<PostV1CommentsClient>[0]>
    return: Awaited<ReturnType<PostV1CommentsClient>>
  }
}
export const postV1CommentsQueryKey = (data: PostV1Comments['request']) =>
  [{ url: '/v1/comments' }, ...(data ? [data] : [])] as const
export type PostV1CommentsQueryKey = ReturnType<typeof postV1CommentsQueryKey>
export function postV1CommentsQueryOptions(
  data: PostV1Comments['request'],
  headers?: PostV1Comments['headerParams'],
  options: PostV1Comments['client']['parameters'] = {},
) {
  const queryKey = postV1CommentsQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<PostV1Comments['data'], PostV1Comments['error']>(
        {
          method: 'post',
          url: `/v1/comments`,
          data,
          headers: {
            'Content-Type': 'application/*+json',
            ...headers,
            ...options.headers,
          },
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/comments
 */
export function usePostV1Comments<
  TData = PostV1Comments['response'],
  TQueryData = PostV1Comments['response'],
  TQueryKey extends QueryKey = PostV1CommentsQueryKey,
>(
  data: PostV1Comments['request'],
  headers?: PostV1Comments['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1Comments['response'],
        PostV1Comments['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1Comments['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1Comments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? postV1CommentsQueryKey(data)
  const query = useQuery({
    ...(postV1CommentsQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1Comments['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1CommentsSuspenseQueryKey = (
  data: PostV1Comments['request'],
) => [{ url: '/v1/comments' }, ...(data ? [data] : [])] as const
export type PostV1CommentsSuspenseQueryKey = ReturnType<
  typeof postV1CommentsSuspenseQueryKey
>
export function postV1CommentsSuspenseQueryOptions(
  data: PostV1Comments['request'],
  headers?: PostV1Comments['headerParams'],
  options: PostV1Comments['client']['parameters'] = {},
) {
  const queryKey = postV1CommentsSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<PostV1Comments['data'], PostV1Comments['error']>(
        {
          method: 'post',
          url: `/v1/comments`,
          data,
          headers: {
            'Content-Type': 'application/*+json',
            ...headers,
            ...options.headers,
          },
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/comments
 */
export function usePostV1CommentsSuspense<
  TData = PostV1Comments['response'],
  TQueryKey extends QueryKey = PostV1CommentsSuspenseQueryKey,
>(
  data: PostV1Comments['request'],
  headers?: PostV1Comments['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1Comments['response'],
        PostV1Comments['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1Comments['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1Comments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1CommentsSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1CommentsSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1Comments['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
