// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryResponse,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidHeaderParams,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403,
} from '../models/GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient =
  typeof client<
    GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryResponse,
    | GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401
    | GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403,
    never
  >
type GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid = {
  data: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryResponse
  error:
    | GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401
    | GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403
  request: never
  pathParams: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams
  queryParams: never
  headerParams: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidHeaderParams
  response: Awaited<
    ReturnType<GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>[0]
    >
    return: Awaited<
      ReturnType<GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>
    >
  }
}
export const getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey =
  (
    surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
    dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  ) =>
    [
      {
        url: '/v2/surveys/:surveyId/automated-data-collections/:dataCollectionId',
        params: { surveyId: surveyId, dataCollectionId: dataCollectionId },
      },
    ] as const
export type GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey =
  ReturnType<
    typeof getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey
  >
export function getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryOptions(
  surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
  dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['headerParams'],
  options: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['client']['parameters'] = {},
) {
  const queryKey =
    getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey(
      surveyId,
      dataCollectionId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['data'],
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
      >({
        method: 'get',
        url: `/v2/surveys/${surveyId}/automated-data-collections/${dataCollectionId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/automated-data-collections/:dataCollectionId
 */
export function useGetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid<
  TData = GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
  TQueryData = GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
  TQueryKey extends
    QueryKey = GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey,
>(
  surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
  dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryKey(
      surveyId,
      dataCollectionId,
    )
  const query = useQuery({
    ...(getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidQueryOptions(
      surveyId,
      dataCollectionId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey =
  (
    surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
    dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  ) =>
    [
      {
        url: '/v2/surveys/:surveyId/automated-data-collections/:dataCollectionId',
        params: { surveyId: surveyId, dataCollectionId: dataCollectionId },
      },
    ] as const
export type GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey =
  ReturnType<
    typeof getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey
  >
export function getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryOptions(
  surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
  dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['headerParams'],
  options: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['client']['parameters'] = {},
) {
  const queryKey =
    getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey(
      surveyId,
      dataCollectionId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['data'],
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
      >({
        method: 'get',
        url: `/v2/surveys/${surveyId}/automated-data-collections/${dataCollectionId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/automated-data-collections/:dataCollectionId
 */
export function useGetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspense<
  TData = GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
  TQueryKey extends
    QueryKey = GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey,
>(
  surveyId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId'],
  dataCollectionId: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
        GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryKey(
      surveyId,
      dataCollectionId,
    )
  const query = useSuspenseQuery({
    ...(getV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidSuspenseQueryOptions(
      surveyId,
      dataCollectionId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
