// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1EconomicsAccountsQueryResponse,
  GetV1EconomicsAccountsHeaderParams,
  GetV1EconomicsAccounts401,
  GetV1EconomicsAccounts403,
} from '../models/GetV1EconomicsAccounts'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1EconomicsAccountsClient = typeof client<
  GetV1EconomicsAccountsQueryResponse,
  GetV1EconomicsAccounts401 | GetV1EconomicsAccounts403,
  never
>
type GetV1EconomicsAccounts = {
  data: GetV1EconomicsAccountsQueryResponse
  error: GetV1EconomicsAccounts401 | GetV1EconomicsAccounts403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1EconomicsAccountsHeaderParams
  response: Awaited<ReturnType<GetV1EconomicsAccountsClient>>
  client: {
    parameters: Partial<Parameters<GetV1EconomicsAccountsClient>[0]>
    return: Awaited<ReturnType<GetV1EconomicsAccountsClient>>
  }
}
export const getV1EconomicsAccountsQueryKey = () =>
  [{ url: '/v1/economics/accounts' }] as const
export type GetV1EconomicsAccountsQueryKey = ReturnType<
  typeof getV1EconomicsAccountsQueryKey
>
export function getV1EconomicsAccountsQueryOptions(
  headers?: GetV1EconomicsAccounts['headerParams'],
  options: GetV1EconomicsAccounts['client']['parameters'] = {},
) {
  const queryKey = getV1EconomicsAccountsQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1EconomicsAccounts['data'],
        GetV1EconomicsAccounts['error']
      >({
        method: 'get',
        url: `/v1/economics/accounts`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/economics/accounts
 */
export function useGetV1EconomicsAccounts<
  TData = GetV1EconomicsAccounts['response'],
  TQueryData = GetV1EconomicsAccounts['response'],
  TQueryKey extends QueryKey = GetV1EconomicsAccountsQueryKey,
>(
  headers?: GetV1EconomicsAccounts['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1EconomicsAccounts['response'],
        GetV1EconomicsAccounts['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1EconomicsAccounts['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1EconomicsAccounts['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1EconomicsAccountsQueryKey()
  const query = useQuery({
    ...(getV1EconomicsAccountsQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1EconomicsAccounts['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1EconomicsAccountsSuspenseQueryKey = () =>
  [{ url: '/v1/economics/accounts' }] as const
export type GetV1EconomicsAccountsSuspenseQueryKey = ReturnType<
  typeof getV1EconomicsAccountsSuspenseQueryKey
>
export function getV1EconomicsAccountsSuspenseQueryOptions(
  headers?: GetV1EconomicsAccounts['headerParams'],
  options: GetV1EconomicsAccounts['client']['parameters'] = {},
) {
  const queryKey = getV1EconomicsAccountsSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1EconomicsAccounts['data'],
        GetV1EconomicsAccounts['error']
      >({
        method: 'get',
        url: `/v1/economics/accounts`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/economics/accounts
 */
export function useGetV1EconomicsAccountsSuspense<
  TData = GetV1EconomicsAccounts['response'],
  TQueryKey extends QueryKey = GetV1EconomicsAccountsSuspenseQueryKey,
>(
  headers?: GetV1EconomicsAccounts['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1EconomicsAccounts['response'],
        GetV1EconomicsAccounts['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1EconomicsAccounts['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1EconomicsAccounts['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1EconomicsAccountsSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1EconomicsAccountsSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1EconomicsAccounts['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
