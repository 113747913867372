// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1TournamentsDataCollectionsDatacollectionidMutationResponse,
  DeleteV1TournamentsDataCollectionsDatacollectionidPathParams,
  DeleteV1TournamentsDataCollectionsDatacollectionidQueryParams,
  DeleteV1TournamentsDataCollectionsDatacollectionidHeaderParams,
  DeleteV1TournamentsDataCollectionsDatacollectionid401,
  DeleteV1TournamentsDataCollectionsDatacollectionid403,
} from '../models/DeleteV1TournamentsDataCollectionsDatacollectionid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1TournamentsDataCollectionsDatacollectionidClient = typeof client<
  DeleteV1TournamentsDataCollectionsDatacollectionidMutationResponse,
  | DeleteV1TournamentsDataCollectionsDatacollectionid401
  | DeleteV1TournamentsDataCollectionsDatacollectionid403,
  never
>
type DeleteV1TournamentsDataCollectionsDatacollectionid = {
  data: DeleteV1TournamentsDataCollectionsDatacollectionidMutationResponse
  error:
    | DeleteV1TournamentsDataCollectionsDatacollectionid401
    | DeleteV1TournamentsDataCollectionsDatacollectionid403
  request: never
  pathParams: DeleteV1TournamentsDataCollectionsDatacollectionidPathParams
  queryParams: DeleteV1TournamentsDataCollectionsDatacollectionidQueryParams
  headerParams: DeleteV1TournamentsDataCollectionsDatacollectionidHeaderParams
  response: Awaited<
    ReturnType<DeleteV1TournamentsDataCollectionsDatacollectionidClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1TournamentsDataCollectionsDatacollectionidClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1TournamentsDataCollectionsDatacollectionidClient>
    >
  }
}
/**
 * @link /v1/tournaments/data-collections/:dataCollectionId
 */
export function useDeleteV1TournamentsDataCollectionsDatacollectionid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1TournamentsDataCollectionsDatacollectionid['response'],
      DeleteV1TournamentsDataCollectionsDatacollectionid['error'],
      {
        dataCollectionId: DeleteV1TournamentsDataCollectionsDatacollectionidPathParams['dataCollectionId']
        params?: DeleteV1TournamentsDataCollectionsDatacollectionid['queryParams']
        headers?: DeleteV1TournamentsDataCollectionsDatacollectionid['headerParams']
      }
    >
    client?: DeleteV1TournamentsDataCollectionsDatacollectionid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ dataCollectionId, params, headers }) => {
      const res = await client<
        DeleteV1TournamentsDataCollectionsDatacollectionid['data'],
        DeleteV1TournamentsDataCollectionsDatacollectionid['error'],
        DeleteV1TournamentsDataCollectionsDatacollectionid['request']
      >({
        method: 'delete',
        url: `/v1/tournaments/data-collections/${dataCollectionId}`,
        params,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
