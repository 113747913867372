// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TeamMaoNotificationsUseridMutationRequest,
  PostV1TeamMaoNotificationsUseridMutationResponse,
  PostV1TeamMaoNotificationsUseridPathParams,
  PostV1TeamMaoNotificationsUseridHeaderParams,
  PostV1TeamMaoNotificationsUserid401,
  PostV1TeamMaoNotificationsUserid403,
} from '../models/PostV1TeamMaoNotificationsUserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TeamMaoNotificationsUseridClient = typeof client<
  PostV1TeamMaoNotificationsUseridMutationResponse,
  PostV1TeamMaoNotificationsUserid401 | PostV1TeamMaoNotificationsUserid403,
  PostV1TeamMaoNotificationsUseridMutationRequest
>
type PostV1TeamMaoNotificationsUserid = {
  data: PostV1TeamMaoNotificationsUseridMutationResponse
  error:
    | PostV1TeamMaoNotificationsUserid401
    | PostV1TeamMaoNotificationsUserid403
  request: PostV1TeamMaoNotificationsUseridMutationRequest
  pathParams: PostV1TeamMaoNotificationsUseridPathParams
  queryParams: never
  headerParams: PostV1TeamMaoNotificationsUseridHeaderParams
  response: Awaited<ReturnType<PostV1TeamMaoNotificationsUseridClient>>
  client: {
    parameters: Partial<Parameters<PostV1TeamMaoNotificationsUseridClient>[0]>
    return: Awaited<ReturnType<PostV1TeamMaoNotificationsUseridClient>>
  }
}
/**
 * @link /v1/team/mao-notifications/:userId
 */
export function usePostV1TeamMaoNotificationsUserid(
  options: {
    mutation?: UseMutationOptions<
      PostV1TeamMaoNotificationsUserid['response'],
      PostV1TeamMaoNotificationsUserid['error'],
      {
        userId: PostV1TeamMaoNotificationsUseridPathParams['userId']
        headers?: PostV1TeamMaoNotificationsUserid['headerParams']
        data: PostV1TeamMaoNotificationsUserid['request']
      }
    >
    client?: PostV1TeamMaoNotificationsUserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ userId, data, headers }) => {
      const res = await client<
        PostV1TeamMaoNotificationsUserid['data'],
        PostV1TeamMaoNotificationsUserid['error'],
        PostV1TeamMaoNotificationsUserid['request']
      >({
        method: 'post',
        url: `/v1/team/mao-notifications/${userId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
