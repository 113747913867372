// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysOverviewQueryResponse,
  GetV1SurveysOverviewHeaderParams,
  GetV1SurveysOverview401,
  GetV1SurveysOverview403,
} from '../models/GetV1SurveysOverview'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysOverviewClient = typeof client<
  GetV1SurveysOverviewQueryResponse,
  GetV1SurveysOverview401 | GetV1SurveysOverview403,
  never
>
type GetV1SurveysOverview = {
  data: GetV1SurveysOverviewQueryResponse
  error: GetV1SurveysOverview401 | GetV1SurveysOverview403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1SurveysOverviewHeaderParams
  response: Awaited<ReturnType<GetV1SurveysOverviewClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysOverviewClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysOverviewClient>>
  }
}
export const getV1SurveysOverviewQueryKey = () =>
  [{ url: '/v1/surveys/overview' }] as const
export type GetV1SurveysOverviewQueryKey = ReturnType<
  typeof getV1SurveysOverviewQueryKey
>
export function getV1SurveysOverviewQueryOptions(
  headers?: GetV1SurveysOverview['headerParams'],
  options: GetV1SurveysOverview['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysOverviewQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysOverview['data'],
        GetV1SurveysOverview['error']
      >({
        method: 'get',
        url: `/v1/surveys/overview`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/overview
 */
export function useGetV1SurveysOverview<
  TData = GetV1SurveysOverview['response'],
  TQueryData = GetV1SurveysOverview['response'],
  TQueryKey extends QueryKey = GetV1SurveysOverviewQueryKey,
>(
  headers?: GetV1SurveysOverview['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysOverview['response'],
        GetV1SurveysOverview['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysOverview['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysOverview['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1SurveysOverviewQueryKey()
  const query = useQuery({
    ...(getV1SurveysOverviewQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysOverview['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysOverviewSuspenseQueryKey = () =>
  [{ url: '/v1/surveys/overview' }] as const
export type GetV1SurveysOverviewSuspenseQueryKey = ReturnType<
  typeof getV1SurveysOverviewSuspenseQueryKey
>
export function getV1SurveysOverviewSuspenseQueryOptions(
  headers?: GetV1SurveysOverview['headerParams'],
  options: GetV1SurveysOverview['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysOverviewSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysOverview['data'],
        GetV1SurveysOverview['error']
      >({
        method: 'get',
        url: `/v1/surveys/overview`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/overview
 */
export function useGetV1SurveysOverviewSuspense<
  TData = GetV1SurveysOverview['response'],
  TQueryKey extends QueryKey = GetV1SurveysOverviewSuspenseQueryKey,
>(
  headers?: GetV1SurveysOverview['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysOverview['response'],
        GetV1SurveysOverview['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysOverview['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysOverview['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SurveysOverviewSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1SurveysOverviewSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1SurveysOverview['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
