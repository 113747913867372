// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1BenchmarksUpdateMutationRequest,
  PutV1BenchmarksUpdateMutationResponse,
  PutV1BenchmarksUpdateHeaderParams,
  PutV1BenchmarksUpdate401,
  PutV1BenchmarksUpdate403,
} from '../models/PutV1BenchmarksUpdate'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1BenchmarksUpdateClient = typeof client<
  PutV1BenchmarksUpdateMutationResponse,
  PutV1BenchmarksUpdate401 | PutV1BenchmarksUpdate403,
  PutV1BenchmarksUpdateMutationRequest
>
type PutV1BenchmarksUpdate = {
  data: PutV1BenchmarksUpdateMutationResponse
  error: PutV1BenchmarksUpdate401 | PutV1BenchmarksUpdate403
  request: PutV1BenchmarksUpdateMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PutV1BenchmarksUpdateHeaderParams
  response: Awaited<ReturnType<PutV1BenchmarksUpdateClient>>
  client: {
    parameters: Partial<Parameters<PutV1BenchmarksUpdateClient>[0]>
    return: Awaited<ReturnType<PutV1BenchmarksUpdateClient>>
  }
}
/**
 * @link /v1/benchmarks/update
 */
export function usePutV1BenchmarksUpdate(
  options: {
    mutation?: UseMutationOptions<
      PutV1BenchmarksUpdate['response'],
      PutV1BenchmarksUpdate['error'],
      {
        headers?: PutV1BenchmarksUpdate['headerParams']
        data: PutV1BenchmarksUpdate['request']
      }
    >
    client?: PutV1BenchmarksUpdate['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PutV1BenchmarksUpdate['data'],
        PutV1BenchmarksUpdate['error'],
        PutV1BenchmarksUpdate['request']
      >({
        method: 'put',
        url: `/v1/benchmarks/update`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
