// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type { GetV1SignUpEnterprisesQueryResponse } from '../models/GetV1SignUpEnterprises'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SignUpEnterprisesClient = typeof client<
  GetV1SignUpEnterprisesQueryResponse,
  Error,
  never
>
type GetV1SignUpEnterprises = {
  data: GetV1SignUpEnterprisesQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1SignUpEnterprisesClient>>
  client: {
    parameters: Partial<Parameters<GetV1SignUpEnterprisesClient>[0]>
    return: Awaited<ReturnType<GetV1SignUpEnterprisesClient>>
  }
}
export const getV1SignUpEnterprisesQueryKey = () =>
  [{ url: '/v1/sign-up/enterprises' }] as const
export type GetV1SignUpEnterprisesQueryKey = ReturnType<
  typeof getV1SignUpEnterprisesQueryKey
>
export function getV1SignUpEnterprisesQueryOptions(
  options: GetV1SignUpEnterprises['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpEnterprisesQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpEnterprises['data'],
        GetV1SignUpEnterprises['error']
      >({
        method: 'get',
        url: `/v1/sign-up/enterprises`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/enterprises
 */
export function useGetV1SignUpEnterprises<
  TData = GetV1SignUpEnterprises['response'],
  TQueryData = GetV1SignUpEnterprises['response'],
  TQueryKey extends QueryKey = GetV1SignUpEnterprisesQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SignUpEnterprises['response'],
        GetV1SignUpEnterprises['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SignUpEnterprises['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SignUpEnterprises['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1SignUpEnterprisesQueryKey()
  const query = useQuery({
    ...(getV1SignUpEnterprisesQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SignUpEnterprises['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SignUpEnterprisesSuspenseQueryKey = () =>
  [{ url: '/v1/sign-up/enterprises' }] as const
export type GetV1SignUpEnterprisesSuspenseQueryKey = ReturnType<
  typeof getV1SignUpEnterprisesSuspenseQueryKey
>
export function getV1SignUpEnterprisesSuspenseQueryOptions(
  options: GetV1SignUpEnterprises['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpEnterprisesSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpEnterprises['data'],
        GetV1SignUpEnterprises['error']
      >({
        method: 'get',
        url: `/v1/sign-up/enterprises`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/enterprises
 */
export function useGetV1SignUpEnterprisesSuspense<
  TData = GetV1SignUpEnterprises['response'],
  TQueryKey extends QueryKey = GetV1SignUpEnterprisesSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SignUpEnterprises['response'],
        GetV1SignUpEnterprises['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SignUpEnterprises['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SignUpEnterprises['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SignUpEnterprisesSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1SignUpEnterprisesSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1SignUpEnterprises['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
