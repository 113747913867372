// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsCommentsBenchmarkMutationRequest,
  PostV1ResultsCommentsBenchmarkMutationResponse,
  PostV1ResultsCommentsBenchmarkHeaderParams,
  PostV1ResultsCommentsBenchmark401,
  PostV1ResultsCommentsBenchmark403,
} from '../models/PostV1ResultsCommentsBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsCommentsBenchmarkClient = typeof client<
  PostV1ResultsCommentsBenchmarkMutationResponse,
  PostV1ResultsCommentsBenchmark401 | PostV1ResultsCommentsBenchmark403,
  PostV1ResultsCommentsBenchmarkMutationRequest
>
type PostV1ResultsCommentsBenchmark = {
  data: PostV1ResultsCommentsBenchmarkMutationResponse
  error: PostV1ResultsCommentsBenchmark401 | PostV1ResultsCommentsBenchmark403
  request: PostV1ResultsCommentsBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsCommentsBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsCommentsBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsCommentsBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsCommentsBenchmarkClient>>
  }
}
export const postV1ResultsCommentsBenchmarkQueryKey = (
  data: PostV1ResultsCommentsBenchmark['request'],
) =>
  [{ url: '/v1/results/comments/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsCommentsBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsCommentsBenchmarkQueryKey
>
export function postV1ResultsCommentsBenchmarkQueryOptions(
  data: PostV1ResultsCommentsBenchmark['request'],
  headers?: PostV1ResultsCommentsBenchmark['headerParams'],
  options: PostV1ResultsCommentsBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsCommentsBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsCommentsBenchmark['data'],
        PostV1ResultsCommentsBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/comments/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/comments/benchmark
 */
export function usePostV1ResultsCommentsBenchmark<
  TData = PostV1ResultsCommentsBenchmark['response'],
  TQueryData = PostV1ResultsCommentsBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsCommentsBenchmarkQueryKey,
>(
  data: PostV1ResultsCommentsBenchmark['request'],
  headers?: PostV1ResultsCommentsBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsCommentsBenchmark['response'],
        PostV1ResultsCommentsBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsCommentsBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsCommentsBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsCommentsBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsCommentsBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsCommentsBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsCommentsBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsCommentsBenchmark['request'],
) =>
  [{ url: '/v1/results/comments/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsCommentsBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsCommentsBenchmarkSuspenseQueryKey
>
export function postV1ResultsCommentsBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsCommentsBenchmark['request'],
  headers?: PostV1ResultsCommentsBenchmark['headerParams'],
  options: PostV1ResultsCommentsBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsCommentsBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsCommentsBenchmark['data'],
        PostV1ResultsCommentsBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/comments/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/comments/benchmark
 */
export function usePostV1ResultsCommentsBenchmarkSuspense<
  TData = PostV1ResultsCommentsBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsCommentsBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsCommentsBenchmark['request'],
  headers?: PostV1ResultsCommentsBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsCommentsBenchmark['response'],
        PostV1ResultsCommentsBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsCommentsBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsCommentsBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsCommentsBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsCommentsBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsCommentsBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
