// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TournamentsEventsFiltersMutationRequest,
  PostV1TournamentsEventsFiltersMutationResponse,
  PostV1TournamentsEventsFiltersHeaderParams,
  PostV1TournamentsEventsFilters401,
  PostV1TournamentsEventsFilters403,
} from '../models/PostV1TournamentsEventsFilters'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TournamentsEventsFiltersClient = typeof client<
  PostV1TournamentsEventsFiltersMutationResponse,
  PostV1TournamentsEventsFilters401 | PostV1TournamentsEventsFilters403,
  PostV1TournamentsEventsFiltersMutationRequest
>
type PostV1TournamentsEventsFilters = {
  data: PostV1TournamentsEventsFiltersMutationResponse
  error: PostV1TournamentsEventsFilters401 | PostV1TournamentsEventsFilters403
  request: PostV1TournamentsEventsFiltersMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1TournamentsEventsFiltersHeaderParams
  response: Awaited<ReturnType<PostV1TournamentsEventsFiltersClient>>
  client: {
    parameters: Partial<Parameters<PostV1TournamentsEventsFiltersClient>[0]>
    return: Awaited<ReturnType<PostV1TournamentsEventsFiltersClient>>
  }
}
/**
 * @link /v1/tournaments/events/filters
 */
export function usePostV1TournamentsEventsFilters(
  options: {
    mutation?: UseMutationOptions<
      PostV1TournamentsEventsFilters['response'],
      PostV1TournamentsEventsFilters['error'],
      {
        headers?: PostV1TournamentsEventsFilters['headerParams']
        data: PostV1TournamentsEventsFilters['request']
      }
    >
    client?: PostV1TournamentsEventsFilters['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1TournamentsEventsFilters['data'],
        PostV1TournamentsEventsFilters['error'],
        PostV1TournamentsEventsFilters['request']
      >({
        method: 'post',
        url: `/v1/tournaments/events/filters`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
