// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionSingleMutationRequest,
  PutV1SurveysSurveyidQuestionSingleMutationResponse,
  PutV1SurveysSurveyidQuestionSinglePathParams,
  PutV1SurveysSurveyidQuestionSingleHeaderParams,
  PutV1SurveysSurveyidQuestionSingle401,
  PutV1SurveysSurveyidQuestionSingle403,
} from '../models/PutV1SurveysSurveyidQuestionSingle'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionSingleClient = typeof client<
  PutV1SurveysSurveyidQuestionSingleMutationResponse,
  PutV1SurveysSurveyidQuestionSingle401 | PutV1SurveysSurveyidQuestionSingle403,
  PutV1SurveysSurveyidQuestionSingleMutationRequest
>
type PutV1SurveysSurveyidQuestionSingle = {
  data: PutV1SurveysSurveyidQuestionSingleMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionSingle401
    | PutV1SurveysSurveyidQuestionSingle403
  request: PutV1SurveysSurveyidQuestionSingleMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionSinglePathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionSingleHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionSingleClient>>
  client: {
    parameters: Partial<Parameters<PutV1SurveysSurveyidQuestionSingleClient>[0]>
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionSingleClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/single
 */
export function usePutV1SurveysSurveyidQuestionSingle(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionSingle['response'],
      PutV1SurveysSurveyidQuestionSingle['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionSinglePathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionSingle['headerParams']
        data: PutV1SurveysSurveyidQuestionSingle['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionSingle['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionSingle['data'],
        PutV1SurveysSurveyidQuestionSingle['error'],
        PutV1SurveysSurveyidQuestionSingle['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/single`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
