// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type { GetV1CountriesQueryResponse } from '../models/GetV1Countries'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1CountriesClient = typeof client<
  GetV1CountriesQueryResponse,
  Error,
  never
>
type GetV1Countries = {
  data: GetV1CountriesQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1CountriesClient>>
  client: {
    parameters: Partial<Parameters<GetV1CountriesClient>[0]>
    return: Awaited<ReturnType<GetV1CountriesClient>>
  }
}
export const getV1CountriesQueryKey = () => [{ url: '/v1/countries' }] as const
export type GetV1CountriesQueryKey = ReturnType<typeof getV1CountriesQueryKey>
export function getV1CountriesQueryOptions(
  options: GetV1Countries['client']['parameters'] = {},
) {
  const queryKey = getV1CountriesQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Countries['data'], GetV1Countries['error']>(
        {
          method: 'get',
          url: `/v1/countries`,
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/countries
 */
export function useGetV1Countries<
  TData = GetV1Countries['response'],
  TQueryData = GetV1Countries['response'],
  TQueryKey extends QueryKey = GetV1CountriesQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1Countries['response'],
        GetV1Countries['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1Countries['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1Countries['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1CountriesQueryKey()
  const query = useQuery({
    ...(getV1CountriesQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1Countries['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1CountriesSuspenseQueryKey = () =>
  [{ url: '/v1/countries' }] as const
export type GetV1CountriesSuspenseQueryKey = ReturnType<
  typeof getV1CountriesSuspenseQueryKey
>
export function getV1CountriesSuspenseQueryOptions(
  options: GetV1Countries['client']['parameters'] = {},
) {
  const queryKey = getV1CountriesSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Countries['data'], GetV1Countries['error']>(
        {
          method: 'get',
          url: `/v1/countries`,
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/countries
 */
export function useGetV1CountriesSuspense<
  TData = GetV1Countries['response'],
  TQueryKey extends QueryKey = GetV1CountriesSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1Countries['response'],
        GetV1Countries['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1Countries['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1Countries['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1CountriesSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1CountriesSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1Countries['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
