// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidInvitationLetterQueryResponse,
  GetV1SurveysSurveyidInvitationLetterPathParams,
  GetV1SurveysSurveyidInvitationLetterHeaderParams,
  GetV1SurveysSurveyidInvitationLetter401,
  GetV1SurveysSurveyidInvitationLetter403,
} from '../models/GetV1SurveysSurveyidInvitationLetter'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidInvitationLetterClient = typeof client<
  GetV1SurveysSurveyidInvitationLetterQueryResponse,
  | GetV1SurveysSurveyidInvitationLetter401
  | GetV1SurveysSurveyidInvitationLetter403,
  never
>
type GetV1SurveysSurveyidInvitationLetter = {
  data: GetV1SurveysSurveyidInvitationLetterQueryResponse
  error:
    | GetV1SurveysSurveyidInvitationLetter401
    | GetV1SurveysSurveyidInvitationLetter403
  request: never
  pathParams: GetV1SurveysSurveyidInvitationLetterPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidInvitationLetterHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidInvitationLetterClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1SurveysSurveyidInvitationLetterClient>[0]
    >
    return: Awaited<ReturnType<GetV1SurveysSurveyidInvitationLetterClient>>
  }
}
export const getV1SurveysSurveyidInvitationLetterQueryKey = (
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/invitation-letter',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidInvitationLetterQueryKey = ReturnType<
  typeof getV1SurveysSurveyidInvitationLetterQueryKey
>
export function getV1SurveysSurveyidInvitationLetterQueryOptions(
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidInvitationLetter['headerParams'],
  options: GetV1SurveysSurveyidInvitationLetter['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidInvitationLetterQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidInvitationLetter['data'],
        GetV1SurveysSurveyidInvitationLetter['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/invitation-letter`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/invitation-letter
 */
export function useGetV1SurveysSurveyidInvitationLetter<
  TData = GetV1SurveysSurveyidInvitationLetter['response'],
  TQueryData = GetV1SurveysSurveyidInvitationLetter['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidInvitationLetterQueryKey,
>(
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidInvitationLetter['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidInvitationLetter['response'],
        GetV1SurveysSurveyidInvitationLetter['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidInvitationLetter['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidInvitationLetter['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidInvitationLetterQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidInvitationLetterQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyidInvitationLetter['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidInvitationLetterSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/invitation-letter',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidInvitationLetterSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidInvitationLetterSuspenseQueryKey
>
export function getV1SurveysSurveyidInvitationLetterSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidInvitationLetter['headerParams'],
  options: GetV1SurveysSurveyidInvitationLetter['client']['parameters'] = {},
) {
  const queryKey =
    getV1SurveysSurveyidInvitationLetterSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidInvitationLetter['data'],
        GetV1SurveysSurveyidInvitationLetter['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/invitation-letter`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/invitation-letter
 */
export function useGetV1SurveysSurveyidInvitationLetterSuspense<
  TData = GetV1SurveysSurveyidInvitationLetter['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidInvitationLetterSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidInvitationLetterPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidInvitationLetter['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidInvitationLetter['response'],
        GetV1SurveysSurveyidInvitationLetter['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidInvitationLetter['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1SurveysSurveyidInvitationLetter['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidInvitationLetterSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidInvitationLetterSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidInvitationLetter['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
