// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type { GetV1TestPingQueryResponse } from '../models/GetV1TestPing'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TestPingClient = typeof client<
  GetV1TestPingQueryResponse,
  Error,
  never
>
type GetV1TestPing = {
  data: GetV1TestPingQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1TestPingClient>>
  client: {
    parameters: Partial<Parameters<GetV1TestPingClient>[0]>
    return: Awaited<ReturnType<GetV1TestPingClient>>
  }
}
export const getV1TestPingQueryKey = () => [{ url: '/v1/test/ping' }] as const
export type GetV1TestPingQueryKey = ReturnType<typeof getV1TestPingQueryKey>
export function getV1TestPingQueryOptions(
  options: GetV1TestPing['client']['parameters'] = {},
) {
  const queryKey = getV1TestPingQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1TestPing['data'], GetV1TestPing['error']>({
        method: 'get',
        url: `/v1/test/ping`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/ping
 */
export function useGetV1TestPing<
  TData = GetV1TestPing['response'],
  TQueryData = GetV1TestPing['response'],
  TQueryKey extends QueryKey = GetV1TestPingQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TestPing['response'],
        GetV1TestPing['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TestPing['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TestPing['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TestPingQueryKey()
  const query = useQuery({
    ...(getV1TestPingQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1TestPing['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TestPingSuspenseQueryKey = () =>
  [{ url: '/v1/test/ping' }] as const
export type GetV1TestPingSuspenseQueryKey = ReturnType<
  typeof getV1TestPingSuspenseQueryKey
>
export function getV1TestPingSuspenseQueryOptions(
  options: GetV1TestPing['client']['parameters'] = {},
) {
  const queryKey = getV1TestPingSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1TestPing['data'], GetV1TestPing['error']>({
        method: 'get',
        url: `/v1/test/ping`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/ping
 */
export function useGetV1TestPingSuspense<
  TData = GetV1TestPing['response'],
  TQueryKey extends QueryKey = GetV1TestPingSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TestPing['response'],
        GetV1TestPing['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TestPing['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1TestPing['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TestPingSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TestPingSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1TestPing['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
