// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsRankingBenchmarkMutationRequest,
  PostV1ResultsRankingBenchmarkMutationResponse,
  PostV1ResultsRankingBenchmarkHeaderParams,
  PostV1ResultsRankingBenchmark401,
  PostV1ResultsRankingBenchmark403,
} from '../models/PostV1ResultsRankingBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsRankingBenchmarkClient = typeof client<
  PostV1ResultsRankingBenchmarkMutationResponse,
  PostV1ResultsRankingBenchmark401 | PostV1ResultsRankingBenchmark403,
  PostV1ResultsRankingBenchmarkMutationRequest
>
type PostV1ResultsRankingBenchmark = {
  data: PostV1ResultsRankingBenchmarkMutationResponse
  error: PostV1ResultsRankingBenchmark401 | PostV1ResultsRankingBenchmark403
  request: PostV1ResultsRankingBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsRankingBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsRankingBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsRankingBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsRankingBenchmarkClient>>
  }
}
export const postV1ResultsRankingBenchmarkQueryKey = (
  data: PostV1ResultsRankingBenchmark['request'],
) =>
  [{ url: '/v1/results/ranking/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsRankingBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsRankingBenchmarkQueryKey
>
export function postV1ResultsRankingBenchmarkQueryOptions(
  data: PostV1ResultsRankingBenchmark['request'],
  headers?: PostV1ResultsRankingBenchmark['headerParams'],
  options: PostV1ResultsRankingBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsRankingBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsRankingBenchmark['data'],
        PostV1ResultsRankingBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/ranking/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/ranking/benchmark
 */
export function usePostV1ResultsRankingBenchmark<
  TData = PostV1ResultsRankingBenchmark['response'],
  TQueryData = PostV1ResultsRankingBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsRankingBenchmarkQueryKey,
>(
  data: PostV1ResultsRankingBenchmark['request'],
  headers?: PostV1ResultsRankingBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsRankingBenchmark['response'],
        PostV1ResultsRankingBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsRankingBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsRankingBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsRankingBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsRankingBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsRankingBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsRankingBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsRankingBenchmark['request'],
) =>
  [{ url: '/v1/results/ranking/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsRankingBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsRankingBenchmarkSuspenseQueryKey
>
export function postV1ResultsRankingBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsRankingBenchmark['request'],
  headers?: PostV1ResultsRankingBenchmark['headerParams'],
  options: PostV1ResultsRankingBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsRankingBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsRankingBenchmark['data'],
        PostV1ResultsRankingBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/ranking/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/ranking/benchmark
 */
export function usePostV1ResultsRankingBenchmarkSuspense<
  TData = PostV1ResultsRankingBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsRankingBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsRankingBenchmark['request'],
  headers?: PostV1ResultsRankingBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsRankingBenchmark['response'],
        PostV1ResultsRankingBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsRankingBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsRankingBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsRankingBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsRankingBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsRankingBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
