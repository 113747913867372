// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsPriorityMapMutationRequest,
  PostV1ResultsPriorityMapMutationResponse,
  PostV1ResultsPriorityMapHeaderParams,
  PostV1ResultsPriorityMap401,
  PostV1ResultsPriorityMap403,
} from '../models/PostV1ResultsPriorityMap'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsPriorityMapClient = typeof client<
  PostV1ResultsPriorityMapMutationResponse,
  PostV1ResultsPriorityMap401 | PostV1ResultsPriorityMap403,
  PostV1ResultsPriorityMapMutationRequest
>
type PostV1ResultsPriorityMap = {
  data: PostV1ResultsPriorityMapMutationResponse
  error: PostV1ResultsPriorityMap401 | PostV1ResultsPriorityMap403
  request: PostV1ResultsPriorityMapMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsPriorityMapHeaderParams
  response: Awaited<ReturnType<PostV1ResultsPriorityMapClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsPriorityMapClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsPriorityMapClient>>
  }
}
export const postV1ResultsPriorityMapQueryKey = (
  data: PostV1ResultsPriorityMap['request'],
) => [{ url: '/v1/results/priority-map' }, ...(data ? [data] : [])] as const
export type PostV1ResultsPriorityMapQueryKey = ReturnType<
  typeof postV1ResultsPriorityMapQueryKey
>
export function postV1ResultsPriorityMapQueryOptions(
  data: PostV1ResultsPriorityMap['request'],
  headers?: PostV1ResultsPriorityMap['headerParams'],
  options: PostV1ResultsPriorityMap['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsPriorityMapQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsPriorityMap['data'],
        PostV1ResultsPriorityMap['error']
      >({
        method: 'post',
        url: `/v1/results/priority-map`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/priority-map
 */
export function usePostV1ResultsPriorityMap<
  TData = PostV1ResultsPriorityMap['response'],
  TQueryData = PostV1ResultsPriorityMap['response'],
  TQueryKey extends QueryKey = PostV1ResultsPriorityMapQueryKey,
>(
  data: PostV1ResultsPriorityMap['request'],
  headers?: PostV1ResultsPriorityMap['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsPriorityMap['response'],
        PostV1ResultsPriorityMap['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsPriorityMap['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsPriorityMap['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsPriorityMapQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsPriorityMapQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsPriorityMap['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsPriorityMapSuspenseQueryKey = (
  data: PostV1ResultsPriorityMap['request'],
) => [{ url: '/v1/results/priority-map' }, ...(data ? [data] : [])] as const
export type PostV1ResultsPriorityMapSuspenseQueryKey = ReturnType<
  typeof postV1ResultsPriorityMapSuspenseQueryKey
>
export function postV1ResultsPriorityMapSuspenseQueryOptions(
  data: PostV1ResultsPriorityMap['request'],
  headers?: PostV1ResultsPriorityMap['headerParams'],
  options: PostV1ResultsPriorityMap['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsPriorityMapSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsPriorityMap['data'],
        PostV1ResultsPriorityMap['error']
      >({
        method: 'post',
        url: `/v1/results/priority-map`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/priority-map
 */
export function usePostV1ResultsPriorityMapSuspense<
  TData = PostV1ResultsPriorityMap['response'],
  TQueryKey extends QueryKey = PostV1ResultsPriorityMapSuspenseQueryKey,
>(
  data: PostV1ResultsPriorityMap['request'],
  headers?: PostV1ResultsPriorityMap['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsPriorityMap['response'],
        PostV1ResultsPriorityMap['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsPriorityMap['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsPriorityMap['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsPriorityMapSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsPriorityMapSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsPriorityMap['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
