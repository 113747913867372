// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1SurveysSurveyidNotificationsUseridMutationResponse,
  DeleteV1SurveysSurveyidNotificationsUseridPathParams,
  DeleteV1SurveysSurveyidNotificationsUseridHeaderParams,
  DeleteV1SurveysSurveyidNotificationsUserid401,
  DeleteV1SurveysSurveyidNotificationsUserid403,
} from '../models/DeleteV1SurveysSurveyidNotificationsUserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1SurveysSurveyidNotificationsUseridClient = typeof client<
  DeleteV1SurveysSurveyidNotificationsUseridMutationResponse,
  | DeleteV1SurveysSurveyidNotificationsUserid401
  | DeleteV1SurveysSurveyidNotificationsUserid403,
  never
>
type DeleteV1SurveysSurveyidNotificationsUserid = {
  data: DeleteV1SurveysSurveyidNotificationsUseridMutationResponse
  error:
    | DeleteV1SurveysSurveyidNotificationsUserid401
    | DeleteV1SurveysSurveyidNotificationsUserid403
  request: never
  pathParams: DeleteV1SurveysSurveyidNotificationsUseridPathParams
  queryParams: never
  headerParams: DeleteV1SurveysSurveyidNotificationsUseridHeaderParams
  response: Awaited<
    ReturnType<DeleteV1SurveysSurveyidNotificationsUseridClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1SurveysSurveyidNotificationsUseridClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1SurveysSurveyidNotificationsUseridClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/notifications/:userId
 */
export function useDeleteV1SurveysSurveyidNotificationsUserid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1SurveysSurveyidNotificationsUserid['response'],
      DeleteV1SurveysSurveyidNotificationsUserid['error'],
      {
        surveyId: DeleteV1SurveysSurveyidNotificationsUseridPathParams['surveyId']
        userId: DeleteV1SurveysSurveyidNotificationsUseridPathParams['userId']
        headers?: DeleteV1SurveysSurveyidNotificationsUserid['headerParams']
      }
    >
    client?: DeleteV1SurveysSurveyidNotificationsUserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, userId, headers }) => {
      const res = await client<
        DeleteV1SurveysSurveyidNotificationsUserid['data'],
        DeleteV1SurveysSurveyidNotificationsUserid['error'],
        DeleteV1SurveysSurveyidNotificationsUserid['request']
      >({
        method: 'delete',
        url: `/v1/surveys/${surveyId}/notifications/${userId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
