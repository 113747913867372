// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidShareableLinkQueryResponse,
  GetV1SurveysSurveyidShareableLinkPathParams,
  GetV1SurveysSurveyidShareableLinkHeaderParams,
  GetV1SurveysSurveyidShareableLink401,
  GetV1SurveysSurveyidShareableLink403,
} from '../models/GetV1SurveysSurveyidShareableLink'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidShareableLinkClient = typeof client<
  GetV1SurveysSurveyidShareableLinkQueryResponse,
  GetV1SurveysSurveyidShareableLink401 | GetV1SurveysSurveyidShareableLink403,
  never
>
type GetV1SurveysSurveyidShareableLink = {
  data: GetV1SurveysSurveyidShareableLinkQueryResponse
  error:
    | GetV1SurveysSurveyidShareableLink401
    | GetV1SurveysSurveyidShareableLink403
  request: never
  pathParams: GetV1SurveysSurveyidShareableLinkPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidShareableLinkHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidShareableLinkClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysSurveyidShareableLinkClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysSurveyidShareableLinkClient>>
  }
}
export const getV1SurveysSurveyidShareableLinkQueryKey = (
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/shareable-link',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidShareableLinkQueryKey = ReturnType<
  typeof getV1SurveysSurveyidShareableLinkQueryKey
>
export function getV1SurveysSurveyidShareableLinkQueryOptions(
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidShareableLink['headerParams'],
  options: GetV1SurveysSurveyidShareableLink['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidShareableLinkQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidShareableLink['data'],
        GetV1SurveysSurveyidShareableLink['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/shareable-link`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/shareable-link
 */
export function useGetV1SurveysSurveyidShareableLink<
  TData = GetV1SurveysSurveyidShareableLink['response'],
  TQueryData = GetV1SurveysSurveyidShareableLink['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidShareableLinkQueryKey,
>(
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidShareableLink['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidShareableLink['response'],
        GetV1SurveysSurveyidShareableLink['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidShareableLink['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidShareableLink['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidShareableLinkQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidShareableLinkQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyidShareableLink['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidShareableLinkSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/shareable-link',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidShareableLinkSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidShareableLinkSuspenseQueryKey
>
export function getV1SurveysSurveyidShareableLinkSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidShareableLink['headerParams'],
  options: GetV1SurveysSurveyidShareableLink['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidShareableLinkSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidShareableLink['data'],
        GetV1SurveysSurveyidShareableLink['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/shareable-link`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/shareable-link
 */
export function useGetV1SurveysSurveyidShareableLinkSuspense<
  TData = GetV1SurveysSurveyidShareableLink['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidShareableLinkSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidShareableLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidShareableLink['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidShareableLink['response'],
        GetV1SurveysSurveyidShareableLink['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidShareableLink['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysSurveyidShareableLink['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidShareableLinkSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidShareableLinkSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidShareableLink['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
