// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1IntegrationsProvideridNotificationUsersQueryResponse,
  GetV1IntegrationsProvideridNotificationUsersPathParams,
  GetV1IntegrationsProvideridNotificationUsersHeaderParams,
  GetV1IntegrationsProvideridNotificationUsers401,
  GetV1IntegrationsProvideridNotificationUsers403,
} from '../models/GetV1IntegrationsProvideridNotificationUsers'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1IntegrationsProvideridNotificationUsersClient = typeof client<
  GetV1IntegrationsProvideridNotificationUsersQueryResponse,
  | GetV1IntegrationsProvideridNotificationUsers401
  | GetV1IntegrationsProvideridNotificationUsers403,
  never
>
type GetV1IntegrationsProvideridNotificationUsers = {
  data: GetV1IntegrationsProvideridNotificationUsersQueryResponse
  error:
    | GetV1IntegrationsProvideridNotificationUsers401
    | GetV1IntegrationsProvideridNotificationUsers403
  request: never
  pathParams: GetV1IntegrationsProvideridNotificationUsersPathParams
  queryParams: never
  headerParams: GetV1IntegrationsProvideridNotificationUsersHeaderParams
  response: Awaited<
    ReturnType<GetV1IntegrationsProvideridNotificationUsersClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1IntegrationsProvideridNotificationUsersClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1IntegrationsProvideridNotificationUsersClient>
    >
  }
}
export const getV1IntegrationsProvideridNotificationUsersQueryKey = (
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
) =>
  [
    {
      url: '/v1/integrations/:providerId/notification-users',
      params: { providerId: providerId },
    },
  ] as const
export type GetV1IntegrationsProvideridNotificationUsersQueryKey = ReturnType<
  typeof getV1IntegrationsProvideridNotificationUsersQueryKey
>
export function getV1IntegrationsProvideridNotificationUsersQueryOptions(
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridNotificationUsers['headerParams'],
  options: GetV1IntegrationsProvideridNotificationUsers['client']['parameters'] = {},
) {
  const queryKey =
    getV1IntegrationsProvideridNotificationUsersQueryKey(providerId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1IntegrationsProvideridNotificationUsers['data'],
        GetV1IntegrationsProvideridNotificationUsers['error']
      >({
        method: 'get',
        url: `/v1/integrations/${providerId}/notification-users`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations/:providerId/notification-users
 */
export function useGetV1IntegrationsProvideridNotificationUsers<
  TData = GetV1IntegrationsProvideridNotificationUsers['response'],
  TQueryData = GetV1IntegrationsProvideridNotificationUsers['response'],
  TQueryKey extends
    QueryKey = GetV1IntegrationsProvideridNotificationUsersQueryKey,
>(
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridNotificationUsers['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1IntegrationsProvideridNotificationUsers['response'],
        GetV1IntegrationsProvideridNotificationUsers['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1IntegrationsProvideridNotificationUsers['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1IntegrationsProvideridNotificationUsers['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1IntegrationsProvideridNotificationUsersQueryKey(providerId)
  const query = useQuery({
    ...(getV1IntegrationsProvideridNotificationUsersQueryOptions(
      providerId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1IntegrationsProvideridNotificationUsers['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1IntegrationsProvideridNotificationUsersSuspenseQueryKey = (
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
) =>
  [
    {
      url: '/v1/integrations/:providerId/notification-users',
      params: { providerId: providerId },
    },
  ] as const
export type GetV1IntegrationsProvideridNotificationUsersSuspenseQueryKey =
  ReturnType<
    typeof getV1IntegrationsProvideridNotificationUsersSuspenseQueryKey
  >
export function getV1IntegrationsProvideridNotificationUsersSuspenseQueryOptions(
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridNotificationUsers['headerParams'],
  options: GetV1IntegrationsProvideridNotificationUsers['client']['parameters'] = {},
) {
  const queryKey =
    getV1IntegrationsProvideridNotificationUsersSuspenseQueryKey(providerId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1IntegrationsProvideridNotificationUsers['data'],
        GetV1IntegrationsProvideridNotificationUsers['error']
      >({
        method: 'get',
        url: `/v1/integrations/${providerId}/notification-users`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations/:providerId/notification-users
 */
export function useGetV1IntegrationsProvideridNotificationUsersSuspense<
  TData = GetV1IntegrationsProvideridNotificationUsers['response'],
  TQueryKey extends
    QueryKey = GetV1IntegrationsProvideridNotificationUsersSuspenseQueryKey,
>(
  providerId: GetV1IntegrationsProvideridNotificationUsersPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridNotificationUsers['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1IntegrationsProvideridNotificationUsers['response'],
        GetV1IntegrationsProvideridNotificationUsers['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1IntegrationsProvideridNotificationUsers['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1IntegrationsProvideridNotificationUsers['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1IntegrationsProvideridNotificationUsersSuspenseQueryKey(providerId)
  const query = useSuspenseQuery({
    ...(getV1IntegrationsProvideridNotificationUsersSuspenseQueryOptions(
      providerId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1IntegrationsProvideridNotificationUsers['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
