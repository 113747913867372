// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationRequest,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationResponse,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsPathParams,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsHeaderParams,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptions401,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptions403,
} from '../models/PutV1SurveysSurveyidQuestionMultipleOnlyOptions'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionMultipleOnlyOptionsClient = typeof client<
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationResponse,
  | PutV1SurveysSurveyidQuestionMultipleOnlyOptions401
  | PutV1SurveysSurveyidQuestionMultipleOnlyOptions403,
  PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationRequest
>
type PutV1SurveysSurveyidQuestionMultipleOnlyOptions = {
  data: PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionMultipleOnlyOptions401
    | PutV1SurveysSurveyidQuestionMultipleOnlyOptions403
  request: PutV1SurveysSurveyidQuestionMultipleOnlyOptionsMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionMultipleOnlyOptionsPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionMultipleOnlyOptionsHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidQuestionMultipleOnlyOptionsClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionMultipleOnlyOptionsClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidQuestionMultipleOnlyOptionsClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/question/multiple-only-options
 */
export function usePutV1SurveysSurveyidQuestionMultipleOnlyOptions(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionMultipleOnlyOptions['response'],
      PutV1SurveysSurveyidQuestionMultipleOnlyOptions['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionMultipleOnlyOptionsPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionMultipleOnlyOptions['headerParams']
        data: PutV1SurveysSurveyidQuestionMultipleOnlyOptions['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionMultipleOnlyOptions['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionMultipleOnlyOptions['data'],
        PutV1SurveysSurveyidQuestionMultipleOnlyOptions['error'],
        PutV1SurveysSurveyidQuestionMultipleOnlyOptions['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/multiple-only-options`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
