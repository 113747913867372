// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsDistributionMutationRequest,
  PostV1ResultsDistributionMutationResponse,
  PostV1ResultsDistributionHeaderParams,
  PostV1ResultsDistribution401,
  PostV1ResultsDistribution403,
} from '../models/PostV1ResultsDistribution'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsDistributionClient = typeof client<
  PostV1ResultsDistributionMutationResponse,
  PostV1ResultsDistribution401 | PostV1ResultsDistribution403,
  PostV1ResultsDistributionMutationRequest
>
type PostV1ResultsDistribution = {
  data: PostV1ResultsDistributionMutationResponse
  error: PostV1ResultsDistribution401 | PostV1ResultsDistribution403
  request: PostV1ResultsDistributionMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsDistributionHeaderParams
  response: Awaited<ReturnType<PostV1ResultsDistributionClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsDistributionClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsDistributionClient>>
  }
}
export const postV1ResultsDistributionQueryKey = (
  data: PostV1ResultsDistribution['request'],
) => [{ url: '/v1/results/distribution' }, ...(data ? [data] : [])] as const
export type PostV1ResultsDistributionQueryKey = ReturnType<
  typeof postV1ResultsDistributionQueryKey
>
export function postV1ResultsDistributionQueryOptions(
  data: PostV1ResultsDistribution['request'],
  headers?: PostV1ResultsDistribution['headerParams'],
  options: PostV1ResultsDistribution['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDistributionQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDistribution['data'],
        PostV1ResultsDistribution['error']
      >({
        method: 'post',
        url: `/v1/results/distribution`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/distribution
 */
export function usePostV1ResultsDistribution<
  TData = PostV1ResultsDistribution['response'],
  TQueryData = PostV1ResultsDistribution['response'],
  TQueryKey extends QueryKey = PostV1ResultsDistributionQueryKey,
>(
  data: PostV1ResultsDistribution['request'],
  headers?: PostV1ResultsDistribution['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsDistribution['response'],
        PostV1ResultsDistribution['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDistribution['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsDistribution['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDistributionQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsDistributionQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsDistribution['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsDistributionSuspenseQueryKey = (
  data: PostV1ResultsDistribution['request'],
) => [{ url: '/v1/results/distribution' }, ...(data ? [data] : [])] as const
export type PostV1ResultsDistributionSuspenseQueryKey = ReturnType<
  typeof postV1ResultsDistributionSuspenseQueryKey
>
export function postV1ResultsDistributionSuspenseQueryOptions(
  data: PostV1ResultsDistribution['request'],
  headers?: PostV1ResultsDistribution['headerParams'],
  options: PostV1ResultsDistribution['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDistributionSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDistribution['data'],
        PostV1ResultsDistribution['error']
      >({
        method: 'post',
        url: `/v1/results/distribution`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/distribution
 */
export function usePostV1ResultsDistributionSuspense<
  TData = PostV1ResultsDistribution['response'],
  TQueryKey extends QueryKey = PostV1ResultsDistributionSuspenseQueryKey,
>(
  data: PostV1ResultsDistribution['request'],
  headers?: PostV1ResultsDistribution['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsDistribution['response'],
        PostV1ResultsDistribution['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDistribution['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsDistribution['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDistributionSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsDistributionSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsDistribution['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
