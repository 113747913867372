// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1InvitesInviteidUserMutationRequest,
  PostV1InvitesInviteidUserMutationResponse,
  PostV1InvitesInviteidUserPathParams,
} from '../models/PostV1InvitesInviteidUser'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1InvitesInviteidUserClient = typeof client<
  PostV1InvitesInviteidUserMutationResponse,
  Error,
  PostV1InvitesInviteidUserMutationRequest
>
type PostV1InvitesInviteidUser = {
  data: PostV1InvitesInviteidUserMutationResponse
  error: Error
  request: PostV1InvitesInviteidUserMutationRequest
  pathParams: PostV1InvitesInviteidUserPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1InvitesInviteidUserClient>>
  client: {
    parameters: Partial<Parameters<PostV1InvitesInviteidUserClient>[0]>
    return: Awaited<ReturnType<PostV1InvitesInviteidUserClient>>
  }
}
/**
 * @link /v1/invites/:inviteId/user
 */
export function usePostV1InvitesInviteidUser(
  options: {
    mutation?: UseMutationOptions<
      PostV1InvitesInviteidUser['response'],
      PostV1InvitesInviteidUser['error'],
      {
        inviteId: PostV1InvitesInviteidUserPathParams['inviteId']
        data: PostV1InvitesInviteidUser['request']
      }
    >
    client?: PostV1InvitesInviteidUser['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ inviteId, data }) => {
      const res = await client<
        PostV1InvitesInviteidUser['data'],
        PostV1InvitesInviteidUser['error'],
        PostV1InvitesInviteidUser['request']
      >({
        method: 'post',
        url: `/v1/invites/${inviteId}/user`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
