// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsDataentriesImportMutationRequest,
  PostV1GenericAccountmetricsDataentriesImportMutationResponse,
  PostV1GenericAccountmetricsDataentriesImportHeaderParams,
  PostV1GenericAccountmetricsDataentriesImport401,
  PostV1GenericAccountmetricsDataentriesImport403,
} from '../models/PostV1GenericAccountmetricsDataentriesImport'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1GenericAccountmetricsDataentriesImportClient = typeof client<
  PostV1GenericAccountmetricsDataentriesImportMutationResponse,
  | PostV1GenericAccountmetricsDataentriesImport401
  | PostV1GenericAccountmetricsDataentriesImport403,
  PostV1GenericAccountmetricsDataentriesImportMutationRequest
>
type PostV1GenericAccountmetricsDataentriesImport = {
  data: PostV1GenericAccountmetricsDataentriesImportMutationResponse
  error:
    | PostV1GenericAccountmetricsDataentriesImport401
    | PostV1GenericAccountmetricsDataentriesImport403
  request: PostV1GenericAccountmetricsDataentriesImportMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsDataentriesImportHeaderParams
  response: Awaited<
    ReturnType<PostV1GenericAccountmetricsDataentriesImportClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsDataentriesImportClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1GenericAccountmetricsDataentriesImportClient>
    >
  }
}
/**
 * @link /v1/generic/accountmetrics/dataentries/import
 */
export function usePostV1GenericAccountmetricsDataentriesImport(
  options: {
    mutation?: UseMutationOptions<
      PostV1GenericAccountmetricsDataentriesImport['response'],
      PostV1GenericAccountmetricsDataentriesImport['error'],
      {
        headers?: PostV1GenericAccountmetricsDataentriesImport['headerParams']
        data: PostV1GenericAccountmetricsDataentriesImport['request']
      }
    >
    client?: PostV1GenericAccountmetricsDataentriesImport['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1GenericAccountmetricsDataentriesImport['data'],
        PostV1GenericAccountmetricsDataentriesImport['error'],
        PostV1GenericAccountmetricsDataentriesImport['request']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/dataentries/import`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
