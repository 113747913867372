// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidServiceAreasServiceareaidMutationRequest,
  PutV1SurveysSurveyidServiceAreasServiceareaidMutationResponse,
  PutV1SurveysSurveyidServiceAreasServiceareaidPathParams,
  PutV1SurveysSurveyidServiceAreasServiceareaidHeaderParams,
  PutV1SurveysSurveyidServiceAreasServiceareaid401,
  PutV1SurveysSurveyidServiceAreasServiceareaid403,
} from '../models/PutV1SurveysSurveyidServiceAreasServiceareaid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidServiceAreasServiceareaidClient = typeof client<
  PutV1SurveysSurveyidServiceAreasServiceareaidMutationResponse,
  | PutV1SurveysSurveyidServiceAreasServiceareaid401
  | PutV1SurveysSurveyidServiceAreasServiceareaid403,
  PutV1SurveysSurveyidServiceAreasServiceareaidMutationRequest
>
type PutV1SurveysSurveyidServiceAreasServiceareaid = {
  data: PutV1SurveysSurveyidServiceAreasServiceareaidMutationResponse
  error:
    | PutV1SurveysSurveyidServiceAreasServiceareaid401
    | PutV1SurveysSurveyidServiceAreasServiceareaid403
  request: PutV1SurveysSurveyidServiceAreasServiceareaidMutationRequest
  pathParams: PutV1SurveysSurveyidServiceAreasServiceareaidPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidServiceAreasServiceareaidHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidServiceAreasServiceareaidClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidServiceAreasServiceareaidClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidServiceAreasServiceareaidClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/service-areas/:serviceAreaId
 */
export function usePutV1SurveysSurveyidServiceAreasServiceareaid(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidServiceAreasServiceareaid['response'],
      PutV1SurveysSurveyidServiceAreasServiceareaid['error'],
      {
        surveyId: PutV1SurveysSurveyidServiceAreasServiceareaidPathParams['surveyId']
        serviceAreaId: PutV1SurveysSurveyidServiceAreasServiceareaidPathParams['serviceAreaId']
        headers?: PutV1SurveysSurveyidServiceAreasServiceareaid['headerParams']
        data: PutV1SurveysSurveyidServiceAreasServiceareaid['request']
      }
    >
    client?: PutV1SurveysSurveyidServiceAreasServiceareaid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, serviceAreaId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidServiceAreasServiceareaid['data'],
        PutV1SurveysSurveyidServiceAreasServiceareaid['error'],
        PutV1SurveysSurveyidServiceAreasServiceareaid['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/service-areas/${serviceAreaId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
