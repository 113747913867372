// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ProductProductidSubscribeMutationResponse,
  PostV1ProductProductidSubscribePathParams,
  PostV1ProductProductidSubscribeHeaderParams,
  PostV1ProductProductidSubscribe401,
  PostV1ProductProductidSubscribe403,
} from '../models/PostV1ProductProductidSubscribe'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ProductProductidSubscribeClient = typeof client<
  PostV1ProductProductidSubscribeMutationResponse,
  PostV1ProductProductidSubscribe401 | PostV1ProductProductidSubscribe403,
  never
>
type PostV1ProductProductidSubscribe = {
  data: PostV1ProductProductidSubscribeMutationResponse
  error: PostV1ProductProductidSubscribe401 | PostV1ProductProductidSubscribe403
  request: never
  pathParams: PostV1ProductProductidSubscribePathParams
  queryParams: never
  headerParams: PostV1ProductProductidSubscribeHeaderParams
  response: Awaited<ReturnType<PostV1ProductProductidSubscribeClient>>
  client: {
    parameters: Partial<Parameters<PostV1ProductProductidSubscribeClient>[0]>
    return: Awaited<ReturnType<PostV1ProductProductidSubscribeClient>>
  }
}
/**
 * @link /v1/product/:productId/subscribe
 */
export function usePostV1ProductProductidSubscribe(
  options: {
    mutation?: UseMutationOptions<
      PostV1ProductProductidSubscribe['response'],
      PostV1ProductProductidSubscribe['error'],
      {
        productId: PostV1ProductProductidSubscribePathParams['productId']
        headers?: PostV1ProductProductidSubscribe['headerParams']
      }
    >
    client?: PostV1ProductProductidSubscribe['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ productId, headers }) => {
      const res = await client<
        PostV1ProductProductidSubscribe['data'],
        PostV1ProductProductidSubscribe['error'],
        PostV1ProductProductidSubscribe['request']
      >({
        method: 'post',
        url: `/v1/product/${productId}/subscribe`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
