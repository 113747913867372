// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1TeamUsersUseridMutationRequest,
  PutV1TeamUsersUseridMutationResponse,
  PutV1TeamUsersUseridPathParams,
  PutV1TeamUsersUseridHeaderParams,
  PutV1TeamUsersUserid401,
  PutV1TeamUsersUserid403,
} from '../models/PutV1TeamUsersUserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1TeamUsersUseridClient = typeof client<
  PutV1TeamUsersUseridMutationResponse,
  PutV1TeamUsersUserid401 | PutV1TeamUsersUserid403,
  PutV1TeamUsersUseridMutationRequest
>
type PutV1TeamUsersUserid = {
  data: PutV1TeamUsersUseridMutationResponse
  error: PutV1TeamUsersUserid401 | PutV1TeamUsersUserid403
  request: PutV1TeamUsersUseridMutationRequest
  pathParams: PutV1TeamUsersUseridPathParams
  queryParams: never
  headerParams: PutV1TeamUsersUseridHeaderParams
  response: Awaited<ReturnType<PutV1TeamUsersUseridClient>>
  client: {
    parameters: Partial<Parameters<PutV1TeamUsersUseridClient>[0]>
    return: Awaited<ReturnType<PutV1TeamUsersUseridClient>>
  }
}
/**
 * @link /v1/team/users/:userId
 */
export function usePutV1TeamUsersUserid(
  options: {
    mutation?: UseMutationOptions<
      PutV1TeamUsersUserid['response'],
      PutV1TeamUsersUserid['error'],
      {
        userId: PutV1TeamUsersUseridPathParams['userId']
        headers?: PutV1TeamUsersUserid['headerParams']
        data?: PutV1TeamUsersUserid['request']
      }
    >
    client?: PutV1TeamUsersUserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ userId, headers, data }) => {
      const res = await client<
        PutV1TeamUsersUserid['data'],
        PutV1TeamUsersUserid['error'],
        PutV1TeamUsersUserid['request']
      >({
        method: 'put',
        url: `/v1/team/users/${userId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
