// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1BiBenchmarksMutationRequest,
  PostV1BiBenchmarksMutationResponse,
  PostV1BiBenchmarksHeaderParams,
  PostV1BiBenchmarks401,
  PostV1BiBenchmarks403,
} from '../models/PostV1BiBenchmarks'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1BiBenchmarksClient = typeof client<
  PostV1BiBenchmarksMutationResponse,
  PostV1BiBenchmarks401 | PostV1BiBenchmarks403,
  PostV1BiBenchmarksMutationRequest
>
type PostV1BiBenchmarks = {
  data: PostV1BiBenchmarksMutationResponse
  error: PostV1BiBenchmarks401 | PostV1BiBenchmarks403
  request: PostV1BiBenchmarksMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1BiBenchmarksHeaderParams
  response: Awaited<ReturnType<PostV1BiBenchmarksClient>>
  client: {
    parameters: Partial<Parameters<PostV1BiBenchmarksClient>[0]>
    return: Awaited<ReturnType<PostV1BiBenchmarksClient>>
  }
}
export const postV1BiBenchmarksQueryKey = (
  data?: PostV1BiBenchmarks['request'],
) => [{ url: '/v1/bi/benchmarks' }, ...(data ? [data] : [])] as const
export type PostV1BiBenchmarksQueryKey = ReturnType<
  typeof postV1BiBenchmarksQueryKey
>
export function postV1BiBenchmarksQueryOptions(
  headers?: PostV1BiBenchmarks['headerParams'],
  data?: PostV1BiBenchmarks['request'],
  options: PostV1BiBenchmarks['client']['parameters'] = {},
) {
  const queryKey = postV1BiBenchmarksQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiBenchmarks['data'],
        PostV1BiBenchmarks['error']
      >({
        method: 'post',
        url: `/v1/bi/benchmarks`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/benchmarks
 */
export function usePostV1BiBenchmarks<
  TData = PostV1BiBenchmarks['response'],
  TQueryData = PostV1BiBenchmarks['response'],
  TQueryKey extends QueryKey = PostV1BiBenchmarksQueryKey,
>(
  headers?: PostV1BiBenchmarks['headerParams'],
  data?: PostV1BiBenchmarks['request'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1BiBenchmarks['response'],
        PostV1BiBenchmarks['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1BiBenchmarks['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1BiBenchmarks['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? postV1BiBenchmarksQueryKey(data)
  const query = useQuery({
    ...(postV1BiBenchmarksQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1BiBenchmarks['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1BiBenchmarksSuspenseQueryKey = (
  data?: PostV1BiBenchmarks['request'],
) => [{ url: '/v1/bi/benchmarks' }, ...(data ? [data] : [])] as const
export type PostV1BiBenchmarksSuspenseQueryKey = ReturnType<
  typeof postV1BiBenchmarksSuspenseQueryKey
>
export function postV1BiBenchmarksSuspenseQueryOptions(
  headers?: PostV1BiBenchmarks['headerParams'],
  data?: PostV1BiBenchmarks['request'],
  options: PostV1BiBenchmarks['client']['parameters'] = {},
) {
  const queryKey = postV1BiBenchmarksSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiBenchmarks['data'],
        PostV1BiBenchmarks['error']
      >({
        method: 'post',
        url: `/v1/bi/benchmarks`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/benchmarks
 */
export function usePostV1BiBenchmarksSuspense<
  TData = PostV1BiBenchmarks['response'],
  TQueryKey extends QueryKey = PostV1BiBenchmarksSuspenseQueryKey,
>(
  headers?: PostV1BiBenchmarks['headerParams'],
  data?: PostV1BiBenchmarks['request'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1BiBenchmarks['response'],
        PostV1BiBenchmarks['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1BiBenchmarks['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1BiBenchmarks['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1BiBenchmarksSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1BiBenchmarksSuspenseQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1BiBenchmarks['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
