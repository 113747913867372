// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationRequest,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationResponse,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdatePathParams,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdateHeaderParams,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdate401,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdate403,
} from '../models/PutV1SurveysSurveyidAutomatedDataCollectionUpdate'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidAutomatedDataCollectionUpdateClient = typeof client<
  PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationResponse,
  | PutV1SurveysSurveyidAutomatedDataCollectionUpdate401
  | PutV1SurveysSurveyidAutomatedDataCollectionUpdate403,
  PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationRequest
>
type PutV1SurveysSurveyidAutomatedDataCollectionUpdate = {
  data: PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationResponse
  error:
    | PutV1SurveysSurveyidAutomatedDataCollectionUpdate401
    | PutV1SurveysSurveyidAutomatedDataCollectionUpdate403
  request: PutV1SurveysSurveyidAutomatedDataCollectionUpdateMutationRequest
  pathParams: PutV1SurveysSurveyidAutomatedDataCollectionUpdatePathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidAutomatedDataCollectionUpdateHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidAutomatedDataCollectionUpdateClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidAutomatedDataCollectionUpdateClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidAutomatedDataCollectionUpdateClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/automated-data-collection-update
 */
export function usePutV1SurveysSurveyidAutomatedDataCollectionUpdate(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidAutomatedDataCollectionUpdate['response'],
      PutV1SurveysSurveyidAutomatedDataCollectionUpdate['error'],
      {
        surveyId: PutV1SurveysSurveyidAutomatedDataCollectionUpdatePathParams['surveyId']
        headers?: PutV1SurveysSurveyidAutomatedDataCollectionUpdate['headerParams']
        data: PutV1SurveysSurveyidAutomatedDataCollectionUpdate['request']
      }
    >
    client?: PutV1SurveysSurveyidAutomatedDataCollectionUpdate['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidAutomatedDataCollectionUpdate['data'],
        PutV1SurveysSurveyidAutomatedDataCollectionUpdate['error'],
        PutV1SurveysSurveyidAutomatedDataCollectionUpdate['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/automated-data-collection-update`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
