// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsListviewBenchmarkMutationRequest,
  PostV1ResultsListviewBenchmarkMutationResponse,
  PostV1ResultsListviewBenchmarkHeaderParams,
  PostV1ResultsListviewBenchmark401,
  PostV1ResultsListviewBenchmark403,
} from '../models/PostV1ResultsListviewBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsListviewBenchmarkClient = typeof client<
  PostV1ResultsListviewBenchmarkMutationResponse,
  PostV1ResultsListviewBenchmark401 | PostV1ResultsListviewBenchmark403,
  PostV1ResultsListviewBenchmarkMutationRequest
>
type PostV1ResultsListviewBenchmark = {
  data: PostV1ResultsListviewBenchmarkMutationResponse
  error: PostV1ResultsListviewBenchmark401 | PostV1ResultsListviewBenchmark403
  request: PostV1ResultsListviewBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsListviewBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsListviewBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsListviewBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsListviewBenchmarkClient>>
  }
}
export const postV1ResultsListviewBenchmarkQueryKey = (
  data: PostV1ResultsListviewBenchmark['request'],
) =>
  [{ url: '/v1/results/listview/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsListviewBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsListviewBenchmarkQueryKey
>
export function postV1ResultsListviewBenchmarkQueryOptions(
  data: PostV1ResultsListviewBenchmark['request'],
  headers?: PostV1ResultsListviewBenchmark['headerParams'],
  options: PostV1ResultsListviewBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsListviewBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsListviewBenchmark['data'],
        PostV1ResultsListviewBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/listview/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/listview/benchmark
 */
export function usePostV1ResultsListviewBenchmark<
  TData = PostV1ResultsListviewBenchmark['response'],
  TQueryData = PostV1ResultsListviewBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsListviewBenchmarkQueryKey,
>(
  data: PostV1ResultsListviewBenchmark['request'],
  headers?: PostV1ResultsListviewBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsListviewBenchmark['response'],
        PostV1ResultsListviewBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsListviewBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsListviewBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsListviewBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsListviewBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsListviewBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsListviewBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsListviewBenchmark['request'],
) =>
  [{ url: '/v1/results/listview/benchmark' }, ...(data ? [data] : [])] as const
export type PostV1ResultsListviewBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsListviewBenchmarkSuspenseQueryKey
>
export function postV1ResultsListviewBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsListviewBenchmark['request'],
  headers?: PostV1ResultsListviewBenchmark['headerParams'],
  options: PostV1ResultsListviewBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsListviewBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsListviewBenchmark['data'],
        PostV1ResultsListviewBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/listview/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/listview/benchmark
 */
export function usePostV1ResultsListviewBenchmarkSuspense<
  TData = PostV1ResultsListviewBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsListviewBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsListviewBenchmark['request'],
  headers?: PostV1ResultsListviewBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsListviewBenchmark['response'],
        PostV1ResultsListviewBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsListviewBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsListviewBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsListviewBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsListviewBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsListviewBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
