// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV2SurveysSurveyidIntegrationProvidersQueryResponse,
  GetV2SurveysSurveyidIntegrationProvidersPathParams,
  GetV2SurveysSurveyidIntegrationProvidersHeaderParams,
  GetV2SurveysSurveyidIntegrationProviders401,
  GetV2SurveysSurveyidIntegrationProviders403,
} from '../models/GetV2SurveysSurveyidIntegrationProviders'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV2SurveysSurveyidIntegrationProvidersClient = typeof client<
  GetV2SurveysSurveyidIntegrationProvidersQueryResponse,
  | GetV2SurveysSurveyidIntegrationProviders401
  | GetV2SurveysSurveyidIntegrationProviders403,
  never
>
type GetV2SurveysSurveyidIntegrationProviders = {
  data: GetV2SurveysSurveyidIntegrationProvidersQueryResponse
  error:
    | GetV2SurveysSurveyidIntegrationProviders401
    | GetV2SurveysSurveyidIntegrationProviders403
  request: never
  pathParams: GetV2SurveysSurveyidIntegrationProvidersPathParams
  queryParams: never
  headerParams: GetV2SurveysSurveyidIntegrationProvidersHeaderParams
  response: Awaited<ReturnType<GetV2SurveysSurveyidIntegrationProvidersClient>>
  client: {
    parameters: Partial<
      Parameters<GetV2SurveysSurveyidIntegrationProvidersClient>[0]
    >
    return: Awaited<ReturnType<GetV2SurveysSurveyidIntegrationProvidersClient>>
  }
}
export const getV2SurveysSurveyidIntegrationProvidersQueryKey = (
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
) =>
  [
    {
      url: '/v2/surveys/:surveyId/integration-providers',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV2SurveysSurveyidIntegrationProvidersQueryKey = ReturnType<
  typeof getV2SurveysSurveyidIntegrationProvidersQueryKey
>
export function getV2SurveysSurveyidIntegrationProvidersQueryOptions(
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
  headers?: GetV2SurveysSurveyidIntegrationProviders['headerParams'],
  options: GetV2SurveysSurveyidIntegrationProviders['client']['parameters'] = {},
) {
  const queryKey = getV2SurveysSurveyidIntegrationProvidersQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV2SurveysSurveyidIntegrationProviders['data'],
        GetV2SurveysSurveyidIntegrationProviders['error']
      >({
        method: 'get',
        url: `/v2/surveys/${surveyId}/integration-providers`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/integration-providers
 */
export function useGetV2SurveysSurveyidIntegrationProviders<
  TData = GetV2SurveysSurveyidIntegrationProviders['response'],
  TQueryData = GetV2SurveysSurveyidIntegrationProviders['response'],
  TQueryKey extends QueryKey = GetV2SurveysSurveyidIntegrationProvidersQueryKey,
>(
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
  headers?: GetV2SurveysSurveyidIntegrationProviders['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV2SurveysSurveyidIntegrationProviders['response'],
        GetV2SurveysSurveyidIntegrationProviders['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV2SurveysSurveyidIntegrationProviders['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV2SurveysSurveyidIntegrationProviders['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV2SurveysSurveyidIntegrationProvidersQueryKey(surveyId)
  const query = useQuery({
    ...(getV2SurveysSurveyidIntegrationProvidersQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV2SurveysSurveyidIntegrationProviders['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey = (
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
) =>
  [
    {
      url: '/v2/surveys/:surveyId/integration-providers',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey =
  ReturnType<typeof getV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey>
export function getV2SurveysSurveyidIntegrationProvidersSuspenseQueryOptions(
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
  headers?: GetV2SurveysSurveyidIntegrationProviders['headerParams'],
  options: GetV2SurveysSurveyidIntegrationProviders['client']['parameters'] = {},
) {
  const queryKey =
    getV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV2SurveysSurveyidIntegrationProviders['data'],
        GetV2SurveysSurveyidIntegrationProviders['error']
      >({
        method: 'get',
        url: `/v2/surveys/${surveyId}/integration-providers`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/integration-providers
 */
export function useGetV2SurveysSurveyidIntegrationProvidersSuspense<
  TData = GetV2SurveysSurveyidIntegrationProviders['response'],
  TQueryKey extends
    QueryKey = GetV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey,
>(
  surveyId: GetV2SurveysSurveyidIntegrationProvidersPathParams['surveyId'],
  headers?: GetV2SurveysSurveyidIntegrationProviders['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV2SurveysSurveyidIntegrationProviders['response'],
        GetV2SurveysSurveyidIntegrationProviders['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV2SurveysSurveyidIntegrationProviders['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV2SurveysSurveyidIntegrationProviders['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV2SurveysSurveyidIntegrationProvidersSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV2SurveysSurveyidIntegrationProvidersSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV2SurveysSurveyidIntegrationProviders['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
