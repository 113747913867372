// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidInvitationLetterPreviewMutationRequest,
  PostV1SurveysSurveyidInvitationLetterPreviewMutationResponse,
  PostV1SurveysSurveyidInvitationLetterPreviewPathParams,
  PostV1SurveysSurveyidInvitationLetterPreviewHeaderParams,
  PostV1SurveysSurveyidInvitationLetterPreview401,
  PostV1SurveysSurveyidInvitationLetterPreview403,
} from '../models/PostV1SurveysSurveyidInvitationLetterPreview'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidInvitationLetterPreviewClient = typeof client<
  PostV1SurveysSurveyidInvitationLetterPreviewMutationResponse,
  | PostV1SurveysSurveyidInvitationLetterPreview401
  | PostV1SurveysSurveyidInvitationLetterPreview403,
  PostV1SurveysSurveyidInvitationLetterPreviewMutationRequest
>
type PostV1SurveysSurveyidInvitationLetterPreview = {
  data: PostV1SurveysSurveyidInvitationLetterPreviewMutationResponse
  error:
    | PostV1SurveysSurveyidInvitationLetterPreview401
    | PostV1SurveysSurveyidInvitationLetterPreview403
  request: PostV1SurveysSurveyidInvitationLetterPreviewMutationRequest
  pathParams: PostV1SurveysSurveyidInvitationLetterPreviewPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidInvitationLetterPreviewHeaderParams
  response: Awaited<
    ReturnType<PostV1SurveysSurveyidInvitationLetterPreviewClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidInvitationLetterPreviewClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1SurveysSurveyidInvitationLetterPreviewClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/invitation-letter/preview
 */
export function usePostV1SurveysSurveyidInvitationLetterPreview(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidInvitationLetterPreview['response'],
      PostV1SurveysSurveyidInvitationLetterPreview['error'],
      {
        surveyId: PostV1SurveysSurveyidInvitationLetterPreviewPathParams['surveyId']
        headers?: PostV1SurveysSurveyidInvitationLetterPreview['headerParams']
        data: PostV1SurveysSurveyidInvitationLetterPreview['request']
      }
    >
    client?: PostV1SurveysSurveyidInvitationLetterPreview['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidInvitationLetterPreview['data'],
        PostV1SurveysSurveyidInvitationLetterPreview['error'],
        PostV1SurveysSurveyidInvitationLetterPreview['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/invitation-letter/preview`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
