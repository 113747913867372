// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1TeamInvitesInviteidMutationResponse,
  DeleteV1TeamInvitesInviteidPathParams,
  DeleteV1TeamInvitesInviteid401,
  DeleteV1TeamInvitesInviteid403,
} from '../models/DeleteV1TeamInvitesInviteid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1TeamInvitesInviteidClient = typeof client<
  DeleteV1TeamInvitesInviteidMutationResponse,
  DeleteV1TeamInvitesInviteid401 | DeleteV1TeamInvitesInviteid403,
  never
>
type DeleteV1TeamInvitesInviteid = {
  data: DeleteV1TeamInvitesInviteidMutationResponse
  error: DeleteV1TeamInvitesInviteid401 | DeleteV1TeamInvitesInviteid403
  request: never
  pathParams: DeleteV1TeamInvitesInviteidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<DeleteV1TeamInvitesInviteidClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1TeamInvitesInviteidClient>[0]>
    return: Awaited<ReturnType<DeleteV1TeamInvitesInviteidClient>>
  }
}
/**
 * @link /v1/team/invites/:inviteId
 */
export function useDeleteV1TeamInvitesInviteid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1TeamInvitesInviteid['response'],
      DeleteV1TeamInvitesInviteid['error'],
      {
        inviteId: DeleteV1TeamInvitesInviteidPathParams['inviteId']
      }
    >
    client?: DeleteV1TeamInvitesInviteid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ inviteId }) => {
      const res = await client<
        DeleteV1TeamInvitesInviteid['data'],
        DeleteV1TeamInvitesInviteid['error'],
        DeleteV1TeamInvitesInviteid['request']
      >({
        method: 'delete',
        url: `/v1/team/invites/${inviteId}`,
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
