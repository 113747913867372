// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsListviewMutationRequest,
  PostV1ResultsListviewMutationResponse,
  PostV1ResultsListviewHeaderParams,
  PostV1ResultsListview401,
  PostV1ResultsListview403,
} from '../models/PostV1ResultsListview'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsListviewClient = typeof client<
  PostV1ResultsListviewMutationResponse,
  PostV1ResultsListview401 | PostV1ResultsListview403,
  PostV1ResultsListviewMutationRequest
>
type PostV1ResultsListview = {
  data: PostV1ResultsListviewMutationResponse
  error: PostV1ResultsListview401 | PostV1ResultsListview403
  request: PostV1ResultsListviewMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsListviewHeaderParams
  response: Awaited<ReturnType<PostV1ResultsListviewClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsListviewClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsListviewClient>>
  }
}
export const postV1ResultsListviewQueryKey = (
  data: PostV1ResultsListview['request'],
) => [{ url: '/v1/results/listview' }, ...(data ? [data] : [])] as const
export type PostV1ResultsListviewQueryKey = ReturnType<
  typeof postV1ResultsListviewQueryKey
>
export function postV1ResultsListviewQueryOptions(
  data: PostV1ResultsListview['request'],
  headers?: PostV1ResultsListview['headerParams'],
  options: PostV1ResultsListview['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsListviewQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsListview['data'],
        PostV1ResultsListview['error']
      >({
        method: 'post',
        url: `/v1/results/listview`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/listview
 */
export function usePostV1ResultsListview<
  TData = PostV1ResultsListview['response'],
  TQueryData = PostV1ResultsListview['response'],
  TQueryKey extends QueryKey = PostV1ResultsListviewQueryKey,
>(
  data: PostV1ResultsListview['request'],
  headers?: PostV1ResultsListview['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsListview['response'],
        PostV1ResultsListview['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsListview['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsListview['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? postV1ResultsListviewQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsListviewQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsListview['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsListviewSuspenseQueryKey = (
  data: PostV1ResultsListview['request'],
) => [{ url: '/v1/results/listview' }, ...(data ? [data] : [])] as const
export type PostV1ResultsListviewSuspenseQueryKey = ReturnType<
  typeof postV1ResultsListviewSuspenseQueryKey
>
export function postV1ResultsListviewSuspenseQueryOptions(
  data: PostV1ResultsListview['request'],
  headers?: PostV1ResultsListview['headerParams'],
  options: PostV1ResultsListview['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsListviewSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsListview['data'],
        PostV1ResultsListview['error']
      >({
        method: 'post',
        url: `/v1/results/listview`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/listview
 */
export function usePostV1ResultsListviewSuspense<
  TData = PostV1ResultsListview['response'],
  TQueryKey extends QueryKey = PostV1ResultsListviewSuspenseQueryKey,
>(
  data: PostV1ResultsListview['request'],
  headers?: PostV1ResultsListview['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsListview['response'],
        PostV1ResultsListview['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsListview['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsListview['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsListviewSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsListviewSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsListview['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
