// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsOtherOptionCommentsMutationRequest,
  PostV1ResultsOtherOptionCommentsMutationResponse,
  PostV1ResultsOtherOptionCommentsHeaderParams,
  PostV1ResultsOtherOptionComments401,
  PostV1ResultsOtherOptionComments403,
} from '../models/PostV1ResultsOtherOptionComments'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsOtherOptionCommentsClient = typeof client<
  PostV1ResultsOtherOptionCommentsMutationResponse,
  PostV1ResultsOtherOptionComments401 | PostV1ResultsOtherOptionComments403,
  PostV1ResultsOtherOptionCommentsMutationRequest
>
type PostV1ResultsOtherOptionComments = {
  data: PostV1ResultsOtherOptionCommentsMutationResponse
  error:
    | PostV1ResultsOtherOptionComments401
    | PostV1ResultsOtherOptionComments403
  request: PostV1ResultsOtherOptionCommentsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsOtherOptionCommentsHeaderParams
  response: Awaited<ReturnType<PostV1ResultsOtherOptionCommentsClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsOtherOptionCommentsClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsOtherOptionCommentsClient>>
  }
}
export const postV1ResultsOtherOptionCommentsQueryKey = (
  data: PostV1ResultsOtherOptionComments['request'],
) =>
  [
    { url: '/v1/results/other-option-comments' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsOtherOptionCommentsQueryKey = ReturnType<
  typeof postV1ResultsOtherOptionCommentsQueryKey
>
export function postV1ResultsOtherOptionCommentsQueryOptions(
  data: PostV1ResultsOtherOptionComments['request'],
  headers?: PostV1ResultsOtherOptionComments['headerParams'],
  options: PostV1ResultsOtherOptionComments['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsOtherOptionCommentsQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsOtherOptionComments['data'],
        PostV1ResultsOtherOptionComments['error']
      >({
        method: 'post',
        url: `/v1/results/other-option-comments`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/other-option-comments
 */
export function usePostV1ResultsOtherOptionComments<
  TData = PostV1ResultsOtherOptionComments['response'],
  TQueryData = PostV1ResultsOtherOptionComments['response'],
  TQueryKey extends QueryKey = PostV1ResultsOtherOptionCommentsQueryKey,
>(
  data: PostV1ResultsOtherOptionComments['request'],
  headers?: PostV1ResultsOtherOptionComments['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsOtherOptionComments['response'],
        PostV1ResultsOtherOptionComments['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsOtherOptionComments['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsOtherOptionComments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsOtherOptionCommentsQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsOtherOptionCommentsQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsOtherOptionComments['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsOtherOptionCommentsSuspenseQueryKey = (
  data: PostV1ResultsOtherOptionComments['request'],
) =>
  [
    { url: '/v1/results/other-option-comments' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsOtherOptionCommentsSuspenseQueryKey = ReturnType<
  typeof postV1ResultsOtherOptionCommentsSuspenseQueryKey
>
export function postV1ResultsOtherOptionCommentsSuspenseQueryOptions(
  data: PostV1ResultsOtherOptionComments['request'],
  headers?: PostV1ResultsOtherOptionComments['headerParams'],
  options: PostV1ResultsOtherOptionComments['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsOtherOptionCommentsSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsOtherOptionComments['data'],
        PostV1ResultsOtherOptionComments['error']
      >({
        method: 'post',
        url: `/v1/results/other-option-comments`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/other-option-comments
 */
export function usePostV1ResultsOtherOptionCommentsSuspense<
  TData = PostV1ResultsOtherOptionComments['response'],
  TQueryKey extends QueryKey = PostV1ResultsOtherOptionCommentsSuspenseQueryKey,
>(
  data: PostV1ResultsOtherOptionComments['request'],
  headers?: PostV1ResultsOtherOptionComments['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsOtherOptionComments['response'],
        PostV1ResultsOtherOptionComments['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsOtherOptionComments['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsOtherOptionComments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsOtherOptionCommentsSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsOtherOptionCommentsSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsOtherOptionComments['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
