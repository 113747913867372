// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1SurveysSurveyidSurveyLogoMutationResponse,
  DeleteV1SurveysSurveyidSurveyLogoPathParams,
  DeleteV1SurveysSurveyidSurveyLogoHeaderParams,
  DeleteV1SurveysSurveyidSurveyLogo401,
  DeleteV1SurveysSurveyidSurveyLogo403,
} from '../models/DeleteV1SurveysSurveyidSurveyLogo'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1SurveysSurveyidSurveyLogoClient = typeof client<
  DeleteV1SurveysSurveyidSurveyLogoMutationResponse,
  DeleteV1SurveysSurveyidSurveyLogo401 | DeleteV1SurveysSurveyidSurveyLogo403,
  never
>
type DeleteV1SurveysSurveyidSurveyLogo = {
  data: DeleteV1SurveysSurveyidSurveyLogoMutationResponse
  error:
    | DeleteV1SurveysSurveyidSurveyLogo401
    | DeleteV1SurveysSurveyidSurveyLogo403
  request: never
  pathParams: DeleteV1SurveysSurveyidSurveyLogoPathParams
  queryParams: never
  headerParams: DeleteV1SurveysSurveyidSurveyLogoHeaderParams
  response: Awaited<ReturnType<DeleteV1SurveysSurveyidSurveyLogoClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1SurveysSurveyidSurveyLogoClient>[0]>
    return: Awaited<ReturnType<DeleteV1SurveysSurveyidSurveyLogoClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/survey-logo
 */
export function useDeleteV1SurveysSurveyidSurveyLogo(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1SurveysSurveyidSurveyLogo['response'],
      DeleteV1SurveysSurveyidSurveyLogo['error'],
      {
        surveyId: DeleteV1SurveysSurveyidSurveyLogoPathParams['surveyId']
        headers?: DeleteV1SurveysSurveyidSurveyLogo['headerParams']
      }
    >
    client?: DeleteV1SurveysSurveyidSurveyLogo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers }) => {
      const res = await client<
        DeleteV1SurveysSurveyidSurveyLogo['data'],
        DeleteV1SurveysSurveyidSurveyLogo['error'],
        DeleteV1SurveysSurveyidSurveyLogo['request']
      >({
        method: 'delete',
        url: `/v1/surveys/${surveyId}/survey-logo`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
