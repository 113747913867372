// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationRequest,
  PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse,
  PutV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams,
  PutV1IntegrationsProvideridNotificationUsersNotificationuseridHeaderParams,
  PutV1IntegrationsProvideridNotificationUsersNotificationuserid401,
  PutV1IntegrationsProvideridNotificationUsersNotificationuserid403,
} from '../models/PutV1IntegrationsProvideridNotificationUsersNotificationuserid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1IntegrationsProvideridNotificationUsersNotificationuseridClient =
  typeof client<
    PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse,
    | PutV1IntegrationsProvideridNotificationUsersNotificationuserid401
    | PutV1IntegrationsProvideridNotificationUsersNotificationuserid403,
    PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationRequest
  >
type PutV1IntegrationsProvideridNotificationUsersNotificationuserid = {
  data: PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationResponse
  error:
    | PutV1IntegrationsProvideridNotificationUsersNotificationuserid401
    | PutV1IntegrationsProvideridNotificationUsersNotificationuserid403
  request: PutV1IntegrationsProvideridNotificationUsersNotificationuseridMutationRequest
  pathParams: PutV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams
  queryParams: never
  headerParams: PutV1IntegrationsProvideridNotificationUsersNotificationuseridHeaderParams
  response: Awaited<
    ReturnType<PutV1IntegrationsProvideridNotificationUsersNotificationuseridClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1IntegrationsProvideridNotificationUsersNotificationuseridClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1IntegrationsProvideridNotificationUsersNotificationuseridClient>
    >
  }
}
/**
 * @link /v1/integrations/:providerId/notification-users/:notificationUserId
 */
export function usePutV1IntegrationsProvideridNotificationUsersNotificationuserid(
  options: {
    mutation?: UseMutationOptions<
      PutV1IntegrationsProvideridNotificationUsersNotificationuserid['response'],
      PutV1IntegrationsProvideridNotificationUsersNotificationuserid['error'],
      {
        providerId: PutV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams['providerId']
        notificationUserId: PutV1IntegrationsProvideridNotificationUsersNotificationuseridPathParams['notificationUserId']
        headers?: PutV1IntegrationsProvideridNotificationUsersNotificationuserid['headerParams']
        data: PutV1IntegrationsProvideridNotificationUsersNotificationuserid['request']
      }
    >
    client?: PutV1IntegrationsProvideridNotificationUsersNotificationuserid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ providerId, notificationUserId, data, headers }) => {
      const res = await client<
        PutV1IntegrationsProvideridNotificationUsersNotificationuserid['data'],
        PutV1IntegrationsProvideridNotificationUsersNotificationuserid['error'],
        PutV1IntegrationsProvideridNotificationUsersNotificationuserid['request']
      >({
        method: 'put',
        url: `/v1/integrations/${providerId}/notification-users/${notificationUserId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
