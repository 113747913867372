// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1CommentsMutationRequest,
  PutV1CommentsMutationResponse,
  PutV1CommentsHeaderParams,
  PutV1Comments401,
  PutV1Comments403,
} from '../models/PutV1Comments'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1CommentsClient = typeof client<
  PutV1CommentsMutationResponse,
  PutV1Comments401 | PutV1Comments403,
  PutV1CommentsMutationRequest
>
type PutV1Comments = {
  data: PutV1CommentsMutationResponse
  error: PutV1Comments401 | PutV1Comments403
  request: PutV1CommentsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PutV1CommentsHeaderParams
  response: Awaited<ReturnType<PutV1CommentsClient>>
  client: {
    parameters: Partial<Parameters<PutV1CommentsClient>[0]>
    return: Awaited<ReturnType<PutV1CommentsClient>>
  }
}
/**
 * @link /v1/comments
 */
export function usePutV1Comments(
  options: {
    mutation?: UseMutationOptions<
      PutV1Comments['response'],
      PutV1Comments['error'],
      {
        headers?: PutV1Comments['headerParams']
        data: PutV1Comments['request']
      }
    >
    client?: PutV1Comments['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PutV1Comments['data'],
        PutV1Comments['error'],
        PutV1Comments['request']
      >({
        method: 'put',
        url: `/v1/comments`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
