// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1EconomicsAccountsMutationResponse,
  PostV1EconomicsAccountsHeaderParams,
  PostV1EconomicsAccounts401,
  PostV1EconomicsAccounts403,
} from '../models/PostV1EconomicsAccounts'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1EconomicsAccountsClient = typeof client<
  PostV1EconomicsAccountsMutationResponse,
  PostV1EconomicsAccounts401 | PostV1EconomicsAccounts403,
  never
>
type PostV1EconomicsAccounts = {
  data: PostV1EconomicsAccountsMutationResponse
  error: PostV1EconomicsAccounts401 | PostV1EconomicsAccounts403
  request: never
  pathParams: never
  queryParams: never
  headerParams: PostV1EconomicsAccountsHeaderParams
  response: Awaited<ReturnType<PostV1EconomicsAccountsClient>>
  client: {
    parameters: Partial<Parameters<PostV1EconomicsAccountsClient>[0]>
    return: Awaited<ReturnType<PostV1EconomicsAccountsClient>>
  }
}
/**
 * @link /v1/economics/accounts
 */
export function usePostV1EconomicsAccounts(
  options: {
    mutation?: UseMutationOptions<
      PostV1EconomicsAccounts['response'],
      PostV1EconomicsAccounts['error'],
      {
        headers?: PostV1EconomicsAccounts['headerParams']
      }
    >
    client?: PostV1EconomicsAccounts['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ headers }) => {
      const res = await client<
        PostV1EconomicsAccounts['data'],
        PostV1EconomicsAccounts['error'],
        PostV1EconomicsAccounts['request']
      >({
        method: 'post',
        url: `/v1/economics/accounts`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
