// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidShareableLinkMutationResponse,
  PostV1SurveysSurveyidShareableLinkPathParams,
  PostV1SurveysSurveyidShareableLinkHeaderParams,
  PostV1SurveysSurveyidShareableLink401,
  PostV1SurveysSurveyidShareableLink403,
} from '../models/PostV1SurveysSurveyidShareableLink'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidShareableLinkClient = typeof client<
  PostV1SurveysSurveyidShareableLinkMutationResponse,
  PostV1SurveysSurveyidShareableLink401 | PostV1SurveysSurveyidShareableLink403,
  never
>
type PostV1SurveysSurveyidShareableLink = {
  data: PostV1SurveysSurveyidShareableLinkMutationResponse
  error:
    | PostV1SurveysSurveyidShareableLink401
    | PostV1SurveysSurveyidShareableLink403
  request: never
  pathParams: PostV1SurveysSurveyidShareableLinkPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidShareableLinkHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidShareableLinkClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveysSurveyidShareableLinkClient>[0]>
    return: Awaited<ReturnType<PostV1SurveysSurveyidShareableLinkClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/shareable-link
 */
export function usePostV1SurveysSurveyidShareableLink(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidShareableLink['response'],
      PostV1SurveysSurveyidShareableLink['error'],
      {
        surveyId: PostV1SurveysSurveyidShareableLinkPathParams['surveyId']
        headers?: PostV1SurveysSurveyidShareableLink['headerParams']
      }
    >
    client?: PostV1SurveysSurveyidShareableLink['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidShareableLink['data'],
        PostV1SurveysSurveyidShareableLink['error'],
        PostV1SurveysSurveyidShareableLink['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/shareable-link`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
