// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidSurveyLogoMutationResponse,
  PostV1SurveysSurveyidSurveyLogoPathParams,
  PostV1SurveysSurveyidSurveyLogoHeaderParams,
  PostV1SurveysSurveyidSurveyLogo401,
  PostV1SurveysSurveyidSurveyLogo403,
} from '../models/PostV1SurveysSurveyidSurveyLogo'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidSurveyLogoClient = typeof client<
  PostV1SurveysSurveyidSurveyLogoMutationResponse,
  PostV1SurveysSurveyidSurveyLogo401 | PostV1SurveysSurveyidSurveyLogo403,
  FormData
>
type PostV1SurveysSurveyidSurveyLogo = {
  data: PostV1SurveysSurveyidSurveyLogoMutationResponse
  error: PostV1SurveysSurveyidSurveyLogo401 | PostV1SurveysSurveyidSurveyLogo403
  request: FormData
  pathParams: PostV1SurveysSurveyidSurveyLogoPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidSurveyLogoHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidSurveyLogoClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveysSurveyidSurveyLogoClient>[0]>
    return: Awaited<ReturnType<PostV1SurveysSurveyidSurveyLogoClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/survey-logo
 */
export function usePostV1SurveysSurveyidSurveyLogo(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidSurveyLogo['response'],
      PostV1SurveysSurveyidSurveyLogo['error'],
      {
        surveyId: PostV1SurveysSurveyidSurveyLogoPathParams['surveyId']
        headers?: PostV1SurveysSurveyidSurveyLogo['headerParams']
        data?: PostV1SurveysSurveyidSurveyLogo['request']
      }
    >
    client?: PostV1SurveysSurveyidSurveyLogo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers, data }) => {
      const formData = new FormData()
      if (data) {
        Object.keys(data).forEach((key) => {
          const value = data[key]
          if (
            typeof key === 'string' &&
            (typeof value === 'string' || value instanceof Blob)
          ) {
            formData.append(key, value)
          }
        })
      }
      const res = await client<
        PostV1SurveysSurveyidSurveyLogo['data'],
        PostV1SurveysSurveyidSurveyLogo['error'],
        PostV1SurveysSurveyidSurveyLogo['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/survey-logo`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
