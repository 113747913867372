// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1TeamQueryResponse,
  GetV1TeamHeaderParams,
  GetV1Team401,
  GetV1Team403,
} from '../models/GetV1Team'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TeamClient = typeof client<
  GetV1TeamQueryResponse,
  GetV1Team401 | GetV1Team403,
  never
>
type GetV1Team = {
  data: GetV1TeamQueryResponse
  error: GetV1Team401 | GetV1Team403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1TeamHeaderParams
  response: Awaited<ReturnType<GetV1TeamClient>>
  client: {
    parameters: Partial<Parameters<GetV1TeamClient>[0]>
    return: Awaited<ReturnType<GetV1TeamClient>>
  }
}
export const getV1TeamQueryKey = () => [{ url: '/v1/team' }] as const
export type GetV1TeamQueryKey = ReturnType<typeof getV1TeamQueryKey>
export function getV1TeamQueryOptions(
  headers?: GetV1Team['headerParams'],
  options: GetV1Team['client']['parameters'] = {},
) {
  const queryKey = getV1TeamQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Team['data'], GetV1Team['error']>({
        method: 'get',
        url: `/v1/team`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/team
 */
export function useGetV1Team<
  TData = GetV1Team['response'],
  TQueryData = GetV1Team['response'],
  TQueryKey extends QueryKey = GetV1TeamQueryKey,
>(
  headers?: GetV1Team['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1Team['response'],
        GetV1Team['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1Team['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1Team['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TeamQueryKey()
  const query = useQuery({
    ...(getV1TeamQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1Team['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TeamSuspenseQueryKey = () => [{ url: '/v1/team' }] as const
export type GetV1TeamSuspenseQueryKey = ReturnType<
  typeof getV1TeamSuspenseQueryKey
>
export function getV1TeamSuspenseQueryOptions(
  headers?: GetV1Team['headerParams'],
  options: GetV1Team['client']['parameters'] = {},
) {
  const queryKey = getV1TeamSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Team['data'], GetV1Team['error']>({
        method: 'get',
        url: `/v1/team`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/team
 */
export function useGetV1TeamSuspense<
  TData = GetV1Team['response'],
  TQueryKey extends QueryKey = GetV1TeamSuspenseQueryKey,
>(
  headers?: GetV1Team['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1Team['response'],
        GetV1Team['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1Team['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1Team['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TeamSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TeamSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1Team['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
