// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TestSentryMutationRequest,
  PostV1TestSentryMutationResponse,
  PostV1TestSentryHeaderParams,
  PostV1TestSentry401,
  PostV1TestSentry403,
} from '../models/PostV1TestSentry'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TestSentryClient = typeof client<
  PostV1TestSentryMutationResponse,
  PostV1TestSentry401 | PostV1TestSentry403,
  PostV1TestSentryMutationRequest
>
type PostV1TestSentry = {
  data: PostV1TestSentryMutationResponse
  error: PostV1TestSentry401 | PostV1TestSentry403
  request: PostV1TestSentryMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1TestSentryHeaderParams
  response: Awaited<ReturnType<PostV1TestSentryClient>>
  client: {
    parameters: Partial<Parameters<PostV1TestSentryClient>[0]>
    return: Awaited<ReturnType<PostV1TestSentryClient>>
  }
}
/**
 * @link /v1/test/sentry
 */
export function usePostV1TestSentry(
  options: {
    mutation?: UseMutationOptions<
      PostV1TestSentry['response'],
      PostV1TestSentry['error'],
      {
        headers?: PostV1TestSentry['headerParams']
        data: PostV1TestSentry['request']
      }
    >
    client?: PostV1TestSentry['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1TestSentry['data'],
        PostV1TestSentry['error'],
        PostV1TestSentry['request']
      >({
        method: 'post',
        url: `/v1/test/sentry`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
