// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidUpdateQuestionActiveMutationRequest,
  PostV1SurveysSurveyidUpdateQuestionActiveMutationResponse,
  PostV1SurveysSurveyidUpdateQuestionActivePathParams,
  PostV1SurveysSurveyidUpdateQuestionActiveHeaderParams,
  PostV1SurveysSurveyidUpdateQuestionActive401,
  PostV1SurveysSurveyidUpdateQuestionActive403,
} from '../models/PostV1SurveysSurveyidUpdateQuestionActive'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidUpdateQuestionActiveClient = typeof client<
  PostV1SurveysSurveyidUpdateQuestionActiveMutationResponse,
  | PostV1SurveysSurveyidUpdateQuestionActive401
  | PostV1SurveysSurveyidUpdateQuestionActive403,
  PostV1SurveysSurveyidUpdateQuestionActiveMutationRequest
>
type PostV1SurveysSurveyidUpdateQuestionActive = {
  data: PostV1SurveysSurveyidUpdateQuestionActiveMutationResponse
  error:
    | PostV1SurveysSurveyidUpdateQuestionActive401
    | PostV1SurveysSurveyidUpdateQuestionActive403
  request: PostV1SurveysSurveyidUpdateQuestionActiveMutationRequest
  pathParams: PostV1SurveysSurveyidUpdateQuestionActivePathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidUpdateQuestionActiveHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidUpdateQuestionActiveClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidUpdateQuestionActiveClient>[0]
    >
    return: Awaited<ReturnType<PostV1SurveysSurveyidUpdateQuestionActiveClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/update-question-active
 */
export function usePostV1SurveysSurveyidUpdateQuestionActive(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidUpdateQuestionActive['response'],
      PostV1SurveysSurveyidUpdateQuestionActive['error'],
      {
        surveyId: PostV1SurveysSurveyidUpdateQuestionActivePathParams['surveyId']
        headers?: PostV1SurveysSurveyidUpdateQuestionActive['headerParams']
        data: PostV1SurveysSurveyidUpdateQuestionActive['request']
      }
    >
    client?: PostV1SurveysSurveyidUpdateQuestionActive['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidUpdateQuestionActive['data'],
        PostV1SurveysSurveyidUpdateQuestionActive['error'],
        PostV1SurveysSurveyidUpdateQuestionActive['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/update-question-active`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
