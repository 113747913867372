// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ResultsExportResultDataMutationRequest,
  PostV1ResultsExportResultDataMutationResponse,
  PostV1ResultsExportResultDataHeaderParams,
  PostV1ResultsExportResultData401,
  PostV1ResultsExportResultData403,
} from '../models/PostV1ResultsExportResultData'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ResultsExportResultDataClient = typeof client<
  PostV1ResultsExportResultDataMutationResponse,
  PostV1ResultsExportResultData401 | PostV1ResultsExportResultData403,
  PostV1ResultsExportResultDataMutationRequest
>
type PostV1ResultsExportResultData = {
  data: PostV1ResultsExportResultDataMutationResponse
  error: PostV1ResultsExportResultData401 | PostV1ResultsExportResultData403
  request: PostV1ResultsExportResultDataMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsExportResultDataHeaderParams
  response: Awaited<ReturnType<PostV1ResultsExportResultDataClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsExportResultDataClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsExportResultDataClient>>
  }
}
/**
 * @link /v1/results/export-result-data
 */
export function usePostV1ResultsExportResultData(
  options: {
    mutation?: UseMutationOptions<
      PostV1ResultsExportResultData['response'],
      PostV1ResultsExportResultData['error'],
      {
        headers?: PostV1ResultsExportResultData['headerParams']
        data: PostV1ResultsExportResultData['request']
      }
    >
    client?: PostV1ResultsExportResultData['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1ResultsExportResultData['data'],
        PostV1ResultsExportResultData['error'],
        PostV1ResultsExportResultData['request']
      >({
        method: 'post',
        url: `/v1/results/export-result-data`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
