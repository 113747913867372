// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ResultsAccountsurveyidFiltersQueryResponse,
  GetV1ResultsAccountsurveyidFiltersPathParams,
  GetV1ResultsAccountsurveyidFiltersHeaderParams,
  GetV1ResultsAccountsurveyidFilters401,
  GetV1ResultsAccountsurveyidFilters403,
} from '../models/GetV1ResultsAccountsurveyidFilters'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ResultsAccountsurveyidFiltersClient = typeof client<
  GetV1ResultsAccountsurveyidFiltersQueryResponse,
  GetV1ResultsAccountsurveyidFilters401 | GetV1ResultsAccountsurveyidFilters403,
  never
>
type GetV1ResultsAccountsurveyidFilters = {
  data: GetV1ResultsAccountsurveyidFiltersQueryResponse
  error:
    | GetV1ResultsAccountsurveyidFilters401
    | GetV1ResultsAccountsurveyidFilters403
  request: never
  pathParams: GetV1ResultsAccountsurveyidFiltersPathParams
  queryParams: never
  headerParams: GetV1ResultsAccountsurveyidFiltersHeaderParams
  response: Awaited<ReturnType<GetV1ResultsAccountsurveyidFiltersClient>>
  client: {
    parameters: Partial<Parameters<GetV1ResultsAccountsurveyidFiltersClient>[0]>
    return: Awaited<ReturnType<GetV1ResultsAccountsurveyidFiltersClient>>
  }
}
export const getV1ResultsAccountsurveyidFiltersQueryKey = (
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
) =>
  [
    {
      url: '/v1/results/:AccountSurveyId/filters',
      params: { accountSurveyId: accountSurveyId },
    },
  ] as const
export type GetV1ResultsAccountsurveyidFiltersQueryKey = ReturnType<
  typeof getV1ResultsAccountsurveyidFiltersQueryKey
>
export function getV1ResultsAccountsurveyidFiltersQueryOptions(
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
  headers?: GetV1ResultsAccountsurveyidFilters['headerParams'],
  options: GetV1ResultsAccountsurveyidFilters['client']['parameters'] = {},
) {
  const queryKey = getV1ResultsAccountsurveyidFiltersQueryKey(accountSurveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ResultsAccountsurveyidFilters['data'],
        GetV1ResultsAccountsurveyidFilters['error']
      >({
        method: 'get',
        url: `/v1/results/${accountSurveyId}/filters`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/:AccountSurveyId/filters
 */
export function useGetV1ResultsAccountsurveyidFilters<
  TData = GetV1ResultsAccountsurveyidFilters['response'],
  TQueryData = GetV1ResultsAccountsurveyidFilters['response'],
  TQueryKey extends QueryKey = GetV1ResultsAccountsurveyidFiltersQueryKey,
>(
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
  headers?: GetV1ResultsAccountsurveyidFilters['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ResultsAccountsurveyidFilters['response'],
        GetV1ResultsAccountsurveyidFilters['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ResultsAccountsurveyidFilters['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1ResultsAccountsurveyidFilters['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ResultsAccountsurveyidFiltersQueryKey(accountSurveyId)
  const query = useQuery({
    ...(getV1ResultsAccountsurveyidFiltersQueryOptions(
      accountSurveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1ResultsAccountsurveyidFilters['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ResultsAccountsurveyidFiltersSuspenseQueryKey = (
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
) =>
  [
    {
      url: '/v1/results/:AccountSurveyId/filters',
      params: { accountSurveyId: accountSurveyId },
    },
  ] as const
export type GetV1ResultsAccountsurveyidFiltersSuspenseQueryKey = ReturnType<
  typeof getV1ResultsAccountsurveyidFiltersSuspenseQueryKey
>
export function getV1ResultsAccountsurveyidFiltersSuspenseQueryOptions(
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
  headers?: GetV1ResultsAccountsurveyidFilters['headerParams'],
  options: GetV1ResultsAccountsurveyidFilters['client']['parameters'] = {},
) {
  const queryKey =
    getV1ResultsAccountsurveyidFiltersSuspenseQueryKey(accountSurveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ResultsAccountsurveyidFilters['data'],
        GetV1ResultsAccountsurveyidFilters['error']
      >({
        method: 'get',
        url: `/v1/results/${accountSurveyId}/filters`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/:AccountSurveyId/filters
 */
export function useGetV1ResultsAccountsurveyidFiltersSuspense<
  TData = GetV1ResultsAccountsurveyidFilters['response'],
  TQueryKey extends
    QueryKey = GetV1ResultsAccountsurveyidFiltersSuspenseQueryKey,
>(
  accountSurveyId: GetV1ResultsAccountsurveyidFiltersPathParams['AccountSurveyId'],
  headers?: GetV1ResultsAccountsurveyidFilters['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ResultsAccountsurveyidFilters['response'],
        GetV1ResultsAccountsurveyidFilters['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ResultsAccountsurveyidFilters['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1ResultsAccountsurveyidFilters['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ResultsAccountsurveyidFiltersSuspenseQueryKey(accountSurveyId)
  const query = useSuspenseQuery({
    ...(getV1ResultsAccountsurveyidFiltersSuspenseQueryOptions(
      accountSurveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1ResultsAccountsurveyidFilters['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
