// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionMultipleMutationRequest,
  PostV1SurveysSurveyidQuestionMultipleMutationResponse,
  PostV1SurveysSurveyidQuestionMultiplePathParams,
  PostV1SurveysSurveyidQuestionMultipleHeaderParams,
  PostV1SurveysSurveyidQuestionMultiple401,
  PostV1SurveysSurveyidQuestionMultiple403,
} from '../models/PostV1SurveysSurveyidQuestionMultiple'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionMultipleClient = typeof client<
  PostV1SurveysSurveyidQuestionMultipleMutationResponse,
  | PostV1SurveysSurveyidQuestionMultiple401
  | PostV1SurveysSurveyidQuestionMultiple403,
  PostV1SurveysSurveyidQuestionMultipleMutationRequest
>
type PostV1SurveysSurveyidQuestionMultiple = {
  data: PostV1SurveysSurveyidQuestionMultipleMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionMultiple401
    | PostV1SurveysSurveyidQuestionMultiple403
  request: PostV1SurveysSurveyidQuestionMultipleMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionMultiplePathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionMultipleHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidQuestionMultipleClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidQuestionMultipleClient>[0]
    >
    return: Awaited<ReturnType<PostV1SurveysSurveyidQuestionMultipleClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/multiple
 */
export function usePostV1SurveysSurveyidQuestionMultiple(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidQuestionMultiple['response'],
      PostV1SurveysSurveyidQuestionMultiple['error'],
      {
        surveyId: PostV1SurveysSurveyidQuestionMultiplePathParams['surveyId']
        headers?: PostV1SurveysSurveyidQuestionMultiple['headerParams']
        data: PostV1SurveysSurveyidQuestionMultiple['request']
      }
    >
    client?: PostV1SurveysSurveyidQuestionMultiple['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidQuestionMultiple['data'],
        PostV1SurveysSurveyidQuestionMultiple['error'],
        PostV1SurveysSurveyidQuestionMultiple['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/multiple`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
