// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SignUpUserCheckEmailMutationRequest,
  PostV1SignUpUserCheckEmailMutationResponse,
} from '../models/PostV1SignUpUserCheckEmail'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SignUpUserCheckEmailClient = typeof client<
  PostV1SignUpUserCheckEmailMutationResponse,
  Error,
  PostV1SignUpUserCheckEmailMutationRequest
>
type PostV1SignUpUserCheckEmail = {
  data: PostV1SignUpUserCheckEmailMutationResponse
  error: Error
  request: PostV1SignUpUserCheckEmailMutationRequest
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1SignUpUserCheckEmailClient>>
  client: {
    parameters: Partial<Parameters<PostV1SignUpUserCheckEmailClient>[0]>
    return: Awaited<ReturnType<PostV1SignUpUserCheckEmailClient>>
  }
}
/**
 * @link /v1/sign-up/user/check-email
 */
export function usePostV1SignUpUserCheckEmail(
  options: {
    mutation?: UseMutationOptions<
      PostV1SignUpUserCheckEmail['response'],
      PostV1SignUpUserCheckEmail['error'],
      {
        data?: PostV1SignUpUserCheckEmail['request']
      }
    >
    client?: PostV1SignUpUserCheckEmail['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data }) => {
      const res = await client<
        PostV1SignUpUserCheckEmail['data'],
        PostV1SignUpUserCheckEmail['error'],
        PostV1SignUpUserCheckEmail['request']
      >({
        method: 'post',
        url: `/v1/sign-up/user/check-email`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
