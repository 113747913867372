// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidGolfGeniusIntegrationQueryResponse,
  GetV1SurveysSurveyidGolfGeniusIntegrationPathParams,
  GetV1SurveysSurveyidGolfGeniusIntegrationHeaderParams,
  GetV1SurveysSurveyidGolfGeniusIntegration401,
  GetV1SurveysSurveyidGolfGeniusIntegration403,
} from '../models/GetV1SurveysSurveyidGolfGeniusIntegration'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidGolfGeniusIntegrationClient = typeof client<
  GetV1SurveysSurveyidGolfGeniusIntegrationQueryResponse,
  | GetV1SurveysSurveyidGolfGeniusIntegration401
  | GetV1SurveysSurveyidGolfGeniusIntegration403,
  never
>
type GetV1SurveysSurveyidGolfGeniusIntegration = {
  data: GetV1SurveysSurveyidGolfGeniusIntegrationQueryResponse
  error:
    | GetV1SurveysSurveyidGolfGeniusIntegration401
    | GetV1SurveysSurveyidGolfGeniusIntegration403
  request: never
  pathParams: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidGolfGeniusIntegrationHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidGolfGeniusIntegrationClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1SurveysSurveyidGolfGeniusIntegrationClient>[0]
    >
    return: Awaited<ReturnType<GetV1SurveysSurveyidGolfGeniusIntegrationClient>>
  }
}
export const getV1SurveysSurveyidGolfGeniusIntegrationQueryKey = (
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/golf-genius/integration',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidGolfGeniusIntegrationQueryKey = ReturnType<
  typeof getV1SurveysSurveyidGolfGeniusIntegrationQueryKey
>
export function getV1SurveysSurveyidGolfGeniusIntegrationQueryOptions(
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidGolfGeniusIntegration['headerParams'],
  options: GetV1SurveysSurveyidGolfGeniusIntegration['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidGolfGeniusIntegrationQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidGolfGeniusIntegration['data'],
        GetV1SurveysSurveyidGolfGeniusIntegration['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/golf-genius/integration`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/golf-genius/integration
 */
export function useGetV1SurveysSurveyidGolfGeniusIntegration<
  TData = GetV1SurveysSurveyidGolfGeniusIntegration['response'],
  TQueryData = GetV1SurveysSurveyidGolfGeniusIntegration['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidGolfGeniusIntegrationQueryKey,
>(
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidGolfGeniusIntegration['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidGolfGeniusIntegration['response'],
        GetV1SurveysSurveyidGolfGeniusIntegration['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidGolfGeniusIntegration['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidGolfGeniusIntegration['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidGolfGeniusIntegrationQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidGolfGeniusIntegrationQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1SurveysSurveyidGolfGeniusIntegration['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/golf-genius/integration',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey =
  ReturnType<typeof getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey>
export function getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidGolfGeniusIntegration['headerParams'],
  options: GetV1SurveysSurveyidGolfGeniusIntegration['client']['parameters'] = {},
) {
  const queryKey =
    getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidGolfGeniusIntegration['data'],
        GetV1SurveysSurveyidGolfGeniusIntegration['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/golf-genius/integration`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/golf-genius/integration
 */
export function useGetV1SurveysSurveyidGolfGeniusIntegrationSuspense<
  TData = GetV1SurveysSurveyidGolfGeniusIntegration['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidGolfGeniusIntegrationPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidGolfGeniusIntegration['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidGolfGeniusIntegration['response'],
        GetV1SurveysSurveyidGolfGeniusIntegration['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidGolfGeniusIntegration['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1SurveysSurveyidGolfGeniusIntegration['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidGolfGeniusIntegrationSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidGolfGeniusIntegration['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
