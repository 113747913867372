// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1BillingsAccountidBillingInfoMutationRequest,
  PutV1BillingsAccountidBillingInfoMutationResponse,
  PutV1BillingsAccountidBillingInfoPathParams,
  PutV1BillingsAccountidBillingInfo401,
  PutV1BillingsAccountidBillingInfo403,
} from '../models/PutV1BillingsAccountidBillingInfo'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1BillingsAccountidBillingInfoClient = typeof client<
  PutV1BillingsAccountidBillingInfoMutationResponse,
  PutV1BillingsAccountidBillingInfo401 | PutV1BillingsAccountidBillingInfo403,
  PutV1BillingsAccountidBillingInfoMutationRequest
>
type PutV1BillingsAccountidBillingInfo = {
  data: PutV1BillingsAccountidBillingInfoMutationResponse
  error:
    | PutV1BillingsAccountidBillingInfo401
    | PutV1BillingsAccountidBillingInfo403
  request: PutV1BillingsAccountidBillingInfoMutationRequest
  pathParams: PutV1BillingsAccountidBillingInfoPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PutV1BillingsAccountidBillingInfoClient>>
  client: {
    parameters: Partial<Parameters<PutV1BillingsAccountidBillingInfoClient>[0]>
    return: Awaited<ReturnType<PutV1BillingsAccountidBillingInfoClient>>
  }
}
/**
 * @link /v1/billings/:accountId/billing-info
 */
export function usePutV1BillingsAccountidBillingInfo(
  options: {
    mutation?: UseMutationOptions<
      PutV1BillingsAccountidBillingInfo['response'],
      PutV1BillingsAccountidBillingInfo['error'],
      {
        accountId: PutV1BillingsAccountidBillingInfoPathParams['accountId']
        data: PutV1BillingsAccountidBillingInfo['request']
      }
    >
    client?: PutV1BillingsAccountidBillingInfo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PutV1BillingsAccountidBillingInfo['data'],
        PutV1BillingsAccountidBillingInfo['error'],
        PutV1BillingsAccountidBillingInfo['request']
      >({
        method: 'put',
        url: `/v1/billings/${accountId}/billing-info`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
