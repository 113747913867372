// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TournamentsEventsScheduleMutationRequest,
  PostV1TournamentsEventsScheduleMutationResponse,
  PostV1TournamentsEventsScheduleQueryParams,
  PostV1TournamentsEventsScheduleHeaderParams,
  PostV1TournamentsEventsSchedule401,
  PostV1TournamentsEventsSchedule403,
} from '../models/PostV1TournamentsEventsSchedule'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TournamentsEventsScheduleClient = typeof client<
  PostV1TournamentsEventsScheduleMutationResponse,
  PostV1TournamentsEventsSchedule401 | PostV1TournamentsEventsSchedule403,
  PostV1TournamentsEventsScheduleMutationRequest
>
type PostV1TournamentsEventsSchedule = {
  data: PostV1TournamentsEventsScheduleMutationResponse
  error: PostV1TournamentsEventsSchedule401 | PostV1TournamentsEventsSchedule403
  request: PostV1TournamentsEventsScheduleMutationRequest
  pathParams: never
  queryParams: PostV1TournamentsEventsScheduleQueryParams
  headerParams: PostV1TournamentsEventsScheduleHeaderParams
  response: Awaited<ReturnType<PostV1TournamentsEventsScheduleClient>>
  client: {
    parameters: Partial<Parameters<PostV1TournamentsEventsScheduleClient>[0]>
    return: Awaited<ReturnType<PostV1TournamentsEventsScheduleClient>>
  }
}
/**
 * @link /v1/tournaments/events/schedule
 */
export function usePostV1TournamentsEventsSchedule(
  options: {
    mutation?: UseMutationOptions<
      PostV1TournamentsEventsSchedule['response'],
      PostV1TournamentsEventsSchedule['error'],
      {
        params?: PostV1TournamentsEventsSchedule['queryParams']
        headers?: PostV1TournamentsEventsSchedule['headerParams']
        data?: PostV1TournamentsEventsSchedule['request']
      }
    >
    client?: PostV1TournamentsEventsSchedule['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ params, headers, data }) => {
      const res = await client<
        PostV1TournamentsEventsSchedule['data'],
        PostV1TournamentsEventsSchedule['error'],
        PostV1TournamentsEventsSchedule['request']
      >({
        method: 'post',
        url: `/v1/tournaments/events/schedule`,
        params,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
