// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV2SurveysSurveyidIntegrationsMemberMutationRequest,
  PostV2SurveysSurveyidIntegrationsMemberMutationResponse,
  PostV2SurveysSurveyidIntegrationsMemberPathParams,
  PostV2SurveysSurveyidIntegrationsMemberHeaderParams,
  PostV2SurveysSurveyidIntegrationsMember401,
  PostV2SurveysSurveyidIntegrationsMember403,
} from '../models/PostV2SurveysSurveyidIntegrationsMember'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV2SurveysSurveyidIntegrationsMemberClient = typeof client<
  PostV2SurveysSurveyidIntegrationsMemberMutationResponse,
  | PostV2SurveysSurveyidIntegrationsMember401
  | PostV2SurveysSurveyidIntegrationsMember403,
  PostV2SurveysSurveyidIntegrationsMemberMutationRequest
>
type PostV2SurveysSurveyidIntegrationsMember = {
  data: PostV2SurveysSurveyidIntegrationsMemberMutationResponse
  error:
    | PostV2SurveysSurveyidIntegrationsMember401
    | PostV2SurveysSurveyidIntegrationsMember403
  request: PostV2SurveysSurveyidIntegrationsMemberMutationRequest
  pathParams: PostV2SurveysSurveyidIntegrationsMemberPathParams
  queryParams: never
  headerParams: PostV2SurveysSurveyidIntegrationsMemberHeaderParams
  response: Awaited<ReturnType<PostV2SurveysSurveyidIntegrationsMemberClient>>
  client: {
    parameters: Partial<
      Parameters<PostV2SurveysSurveyidIntegrationsMemberClient>[0]
    >
    return: Awaited<ReturnType<PostV2SurveysSurveyidIntegrationsMemberClient>>
  }
}
export const postV2SurveysSurveyidIntegrationsMemberQueryKey = (
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
) =>
  [
    {
      url: '/v2/surveys/:surveyId/integrations/member',
      params: { surveyId: surveyId },
    },
    ...(data ? [data] : []),
  ] as const
export type PostV2SurveysSurveyidIntegrationsMemberQueryKey = ReturnType<
  typeof postV2SurveysSurveyidIntegrationsMemberQueryKey
>
export function postV2SurveysSurveyidIntegrationsMemberQueryOptions(
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
  headers?: PostV2SurveysSurveyidIntegrationsMember['headerParams'],
  options: PostV2SurveysSurveyidIntegrationsMember['client']['parameters'] = {},
) {
  const queryKey = postV2SurveysSurveyidIntegrationsMemberQueryKey(
    surveyId,
    data,
  )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV2SurveysSurveyidIntegrationsMember['data'],
        PostV2SurveysSurveyidIntegrationsMember['error']
      >({
        method: 'post',
        url: `/v2/surveys/${surveyId}/integrations/member`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/integrations/member
 */
export function usePostV2SurveysSurveyidIntegrationsMember<
  TData = PostV2SurveysSurveyidIntegrationsMember['response'],
  TQueryData = PostV2SurveysSurveyidIntegrationsMember['response'],
  TQueryKey extends QueryKey = PostV2SurveysSurveyidIntegrationsMemberQueryKey,
>(
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
  headers?: PostV2SurveysSurveyidIntegrationsMember['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV2SurveysSurveyidIntegrationsMember['response'],
        PostV2SurveysSurveyidIntegrationsMember['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV2SurveysSurveyidIntegrationsMember['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV2SurveysSurveyidIntegrationsMember['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV2SurveysSurveyidIntegrationsMemberQueryKey(surveyId, data)
  const query = useQuery({
    ...(postV2SurveysSurveyidIntegrationsMemberQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV2SurveysSurveyidIntegrationsMember['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey = (
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
) =>
  [
    {
      url: '/v2/surveys/:surveyId/integrations/member',
      params: { surveyId: surveyId },
    },
    ...(data ? [data] : []),
  ] as const
export type PostV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey =
  ReturnType<typeof postV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey>
export function postV2SurveysSurveyidIntegrationsMemberSuspenseQueryOptions(
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
  headers?: PostV2SurveysSurveyidIntegrationsMember['headerParams'],
  options: PostV2SurveysSurveyidIntegrationsMember['client']['parameters'] = {},
) {
  const queryKey = postV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey(
    surveyId,
    data,
  )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV2SurveysSurveyidIntegrationsMember['data'],
        PostV2SurveysSurveyidIntegrationsMember['error']
      >({
        method: 'post',
        url: `/v2/surveys/${surveyId}/integrations/member`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v2/surveys/:surveyId/integrations/member
 */
export function usePostV2SurveysSurveyidIntegrationsMemberSuspense<
  TData = PostV2SurveysSurveyidIntegrationsMember['response'],
  TQueryKey extends
    QueryKey = PostV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey,
>(
  surveyId: PostV2SurveysSurveyidIntegrationsMemberPathParams['surveyId'],
  data: PostV2SurveysSurveyidIntegrationsMember['request'],
  headers?: PostV2SurveysSurveyidIntegrationsMember['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV2SurveysSurveyidIntegrationsMember['response'],
        PostV2SurveysSurveyidIntegrationsMember['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV2SurveysSurveyidIntegrationsMember['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV2SurveysSurveyidIntegrationsMember['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV2SurveysSurveyidIntegrationsMemberSuspenseQueryKey(surveyId, data)
  const query = useSuspenseQuery({
    ...(postV2SurveysSurveyidIntegrationsMemberSuspenseQueryOptions(
      surveyId,
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV2SurveysSurveyidIntegrationsMember['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
