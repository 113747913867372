// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidAutomatedDataCollectionQueryResponse,
  GetV1SurveysSurveyidAutomatedDataCollectionPathParams,
  GetV1SurveysSurveyidAutomatedDataCollectionHeaderParams,
  GetV1SurveysSurveyidAutomatedDataCollection401,
  GetV1SurveysSurveyidAutomatedDataCollection403,
} from '../models/GetV1SurveysSurveyidAutomatedDataCollection'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidAutomatedDataCollectionClient = typeof client<
  GetV1SurveysSurveyidAutomatedDataCollectionQueryResponse,
  | GetV1SurveysSurveyidAutomatedDataCollection401
  | GetV1SurveysSurveyidAutomatedDataCollection403,
  never
>
type GetV1SurveysSurveyidAutomatedDataCollection = {
  data: GetV1SurveysSurveyidAutomatedDataCollectionQueryResponse
  error:
    | GetV1SurveysSurveyidAutomatedDataCollection401
    | GetV1SurveysSurveyidAutomatedDataCollection403
  request: never
  pathParams: GetV1SurveysSurveyidAutomatedDataCollectionPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidAutomatedDataCollectionHeaderParams
  response: Awaited<
    ReturnType<GetV1SurveysSurveyidAutomatedDataCollectionClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1SurveysSurveyidAutomatedDataCollectionClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1SurveysSurveyidAutomatedDataCollectionClient>
    >
  }
}
export const getV1SurveysSurveyidAutomatedDataCollectionQueryKey = (
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/automated-data-collection',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidAutomatedDataCollectionQueryKey = ReturnType<
  typeof getV1SurveysSurveyidAutomatedDataCollectionQueryKey
>
export function getV1SurveysSurveyidAutomatedDataCollectionQueryOptions(
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidAutomatedDataCollection['headerParams'],
  options: GetV1SurveysSurveyidAutomatedDataCollection['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidAutomatedDataCollectionQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidAutomatedDataCollection['data'],
        GetV1SurveysSurveyidAutomatedDataCollection['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/automated-data-collection`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/automated-data-collection
 */
export function useGetV1SurveysSurveyidAutomatedDataCollection<
  TData = GetV1SurveysSurveyidAutomatedDataCollection['response'],
  TQueryData = GetV1SurveysSurveyidAutomatedDataCollection['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidAutomatedDataCollectionQueryKey,
>(
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidAutomatedDataCollection['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidAutomatedDataCollection['response'],
        GetV1SurveysSurveyidAutomatedDataCollection['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidAutomatedDataCollection['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1SurveysSurveyidAutomatedDataCollection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidAutomatedDataCollectionQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidAutomatedDataCollectionQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1SurveysSurveyidAutomatedDataCollection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/automated-data-collection',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey =
  ReturnType<typeof getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey>
export function getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidAutomatedDataCollection['headerParams'],
  options: GetV1SurveysSurveyidAutomatedDataCollection['client']['parameters'] = {},
) {
  const queryKey =
    getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidAutomatedDataCollection['data'],
        GetV1SurveysSurveyidAutomatedDataCollection['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/automated-data-collection`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/automated-data-collection
 */
export function useGetV1SurveysSurveyidAutomatedDataCollectionSuspense<
  TData = GetV1SurveysSurveyidAutomatedDataCollection['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidAutomatedDataCollectionPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidAutomatedDataCollection['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidAutomatedDataCollection['response'],
        GetV1SurveysSurveyidAutomatedDataCollection['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidAutomatedDataCollection['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1SurveysSurveyidAutomatedDataCollection['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidAutomatedDataCollectionSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidAutomatedDataCollection['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
