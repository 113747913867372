// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsDevelopmentBenchmarkMutationRequest,
  PostV1ResultsDevelopmentBenchmarkMutationResponse,
  PostV1ResultsDevelopmentBenchmarkHeaderParams,
  PostV1ResultsDevelopmentBenchmark401,
  PostV1ResultsDevelopmentBenchmark403,
} from '../models/PostV1ResultsDevelopmentBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsDevelopmentBenchmarkClient = typeof client<
  PostV1ResultsDevelopmentBenchmarkMutationResponse,
  PostV1ResultsDevelopmentBenchmark401 | PostV1ResultsDevelopmentBenchmark403,
  PostV1ResultsDevelopmentBenchmarkMutationRequest
>
type PostV1ResultsDevelopmentBenchmark = {
  data: PostV1ResultsDevelopmentBenchmarkMutationResponse
  error:
    | PostV1ResultsDevelopmentBenchmark401
    | PostV1ResultsDevelopmentBenchmark403
  request: PostV1ResultsDevelopmentBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsDevelopmentBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsDevelopmentBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsDevelopmentBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsDevelopmentBenchmarkClient>>
  }
}
export const postV1ResultsDevelopmentBenchmarkQueryKey = (
  data: PostV1ResultsDevelopmentBenchmark['request'],
) =>
  [
    { url: '/v1/results/development/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsDevelopmentBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsDevelopmentBenchmarkQueryKey
>
export function postV1ResultsDevelopmentBenchmarkQueryOptions(
  data: PostV1ResultsDevelopmentBenchmark['request'],
  headers?: PostV1ResultsDevelopmentBenchmark['headerParams'],
  options: PostV1ResultsDevelopmentBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDevelopmentBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDevelopmentBenchmark['data'],
        PostV1ResultsDevelopmentBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/development/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/development/benchmark
 */
export function usePostV1ResultsDevelopmentBenchmark<
  TData = PostV1ResultsDevelopmentBenchmark['response'],
  TQueryData = PostV1ResultsDevelopmentBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsDevelopmentBenchmarkQueryKey,
>(
  data: PostV1ResultsDevelopmentBenchmark['request'],
  headers?: PostV1ResultsDevelopmentBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsDevelopmentBenchmark['response'],
        PostV1ResultsDevelopmentBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDevelopmentBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsDevelopmentBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDevelopmentBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsDevelopmentBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsDevelopmentBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsDevelopmentBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsDevelopmentBenchmark['request'],
) =>
  [
    { url: '/v1/results/development/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsDevelopmentBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsDevelopmentBenchmarkSuspenseQueryKey
>
export function postV1ResultsDevelopmentBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsDevelopmentBenchmark['request'],
  headers?: PostV1ResultsDevelopmentBenchmark['headerParams'],
  options: PostV1ResultsDevelopmentBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDevelopmentBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDevelopmentBenchmark['data'],
        PostV1ResultsDevelopmentBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/development/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/development/benchmark
 */
export function usePostV1ResultsDevelopmentBenchmarkSuspense<
  TData = PostV1ResultsDevelopmentBenchmark['response'],
  TQueryKey extends
    QueryKey = PostV1ResultsDevelopmentBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsDevelopmentBenchmark['request'],
  headers?: PostV1ResultsDevelopmentBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsDevelopmentBenchmark['response'],
        PostV1ResultsDevelopmentBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDevelopmentBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsDevelopmentBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsDevelopmentBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsDevelopmentBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsDevelopmentBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
