// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TournamentsDataCollectionsTrialsMutationRequest,
  PostV1TournamentsDataCollectionsTrialsMutationResponse,
  PostV1TournamentsDataCollectionsTrialsHeaderParams,
  PostV1TournamentsDataCollectionsTrials401,
  PostV1TournamentsDataCollectionsTrials403,
} from '../models/PostV1TournamentsDataCollectionsTrials'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TournamentsDataCollectionsTrialsClient = typeof client<
  PostV1TournamentsDataCollectionsTrialsMutationResponse,
  | PostV1TournamentsDataCollectionsTrials401
  | PostV1TournamentsDataCollectionsTrials403,
  PostV1TournamentsDataCollectionsTrialsMutationRequest
>
type PostV1TournamentsDataCollectionsTrials = {
  data: PostV1TournamentsDataCollectionsTrialsMutationResponse
  error:
    | PostV1TournamentsDataCollectionsTrials401
    | PostV1TournamentsDataCollectionsTrials403
  request: PostV1TournamentsDataCollectionsTrialsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1TournamentsDataCollectionsTrialsHeaderParams
  response: Awaited<ReturnType<PostV1TournamentsDataCollectionsTrialsClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1TournamentsDataCollectionsTrialsClient>[0]
    >
    return: Awaited<ReturnType<PostV1TournamentsDataCollectionsTrialsClient>>
  }
}
/**
 * @link /v1/tournaments/data-collections/trials
 */
export function usePostV1TournamentsDataCollectionsTrials(
  options: {
    mutation?: UseMutationOptions<
      PostV1TournamentsDataCollectionsTrials['response'],
      PostV1TournamentsDataCollectionsTrials['error'],
      {
        headers?: PostV1TournamentsDataCollectionsTrials['headerParams']
        data: PostV1TournamentsDataCollectionsTrials['request']
      }
    >
    client?: PostV1TournamentsDataCollectionsTrials['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1TournamentsDataCollectionsTrials['data'],
        PostV1TournamentsDataCollectionsTrials['error'],
        PostV1TournamentsDataCollectionsTrials['request']
      >({
        method: 'post',
        url: `/v1/tournaments/data-collections/trials`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
