// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsCommentsMutationRequest,
  PostV1ResultsCommentsMutationResponse,
  PostV1ResultsCommentsHeaderParams,
  PostV1ResultsComments401,
  PostV1ResultsComments403,
} from '../models/PostV1ResultsComments'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsCommentsClient = typeof client<
  PostV1ResultsCommentsMutationResponse,
  PostV1ResultsComments401 | PostV1ResultsComments403,
  PostV1ResultsCommentsMutationRequest
>
type PostV1ResultsComments = {
  data: PostV1ResultsCommentsMutationResponse
  error: PostV1ResultsComments401 | PostV1ResultsComments403
  request: PostV1ResultsCommentsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsCommentsHeaderParams
  response: Awaited<ReturnType<PostV1ResultsCommentsClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsCommentsClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsCommentsClient>>
  }
}
export const postV1ResultsCommentsQueryKey = (
  data: PostV1ResultsComments['request'],
) => [{ url: '/v1/results/comments' }, ...(data ? [data] : [])] as const
export type PostV1ResultsCommentsQueryKey = ReturnType<
  typeof postV1ResultsCommentsQueryKey
>
export function postV1ResultsCommentsQueryOptions(
  data: PostV1ResultsComments['request'],
  headers?: PostV1ResultsComments['headerParams'],
  options: PostV1ResultsComments['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsCommentsQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsComments['data'],
        PostV1ResultsComments['error']
      >({
        method: 'post',
        url: `/v1/results/comments`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/comments
 */
export function usePostV1ResultsComments<
  TData = PostV1ResultsComments['response'],
  TQueryData = PostV1ResultsComments['response'],
  TQueryKey extends QueryKey = PostV1ResultsCommentsQueryKey,
>(
  data: PostV1ResultsComments['request'],
  headers?: PostV1ResultsComments['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsComments['response'],
        PostV1ResultsComments['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsComments['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsComments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? postV1ResultsCommentsQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsCommentsQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsComments['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsCommentsSuspenseQueryKey = (
  data: PostV1ResultsComments['request'],
) => [{ url: '/v1/results/comments' }, ...(data ? [data] : [])] as const
export type PostV1ResultsCommentsSuspenseQueryKey = ReturnType<
  typeof postV1ResultsCommentsSuspenseQueryKey
>
export function postV1ResultsCommentsSuspenseQueryOptions(
  data: PostV1ResultsComments['request'],
  headers?: PostV1ResultsComments['headerParams'],
  options: PostV1ResultsComments['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsCommentsSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsComments['data'],
        PostV1ResultsComments['error']
      >({
        method: 'post',
        url: `/v1/results/comments`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/comments
 */
export function usePostV1ResultsCommentsSuspense<
  TData = PostV1ResultsComments['response'],
  TQueryKey extends QueryKey = PostV1ResultsCommentsSuspenseQueryKey,
>(
  data: PostV1ResultsComments['request'],
  headers?: PostV1ResultsComments['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsComments['response'],
        PostV1ResultsComments['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsComments['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsComments['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsCommentsSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsCommentsSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsComments['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
