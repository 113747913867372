// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV2SurveysSurveyidAutomatedDataCollectionsMutationRequest,
  PostV2SurveysSurveyidAutomatedDataCollectionsMutationResponse,
  PostV2SurveysSurveyidAutomatedDataCollectionsPathParams,
  PostV2SurveysSurveyidAutomatedDataCollectionsHeaderParams,
  PostV2SurveysSurveyidAutomatedDataCollections401,
  PostV2SurveysSurveyidAutomatedDataCollections403,
} from '../models/PostV2SurveysSurveyidAutomatedDataCollections'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV2SurveysSurveyidAutomatedDataCollectionsClient = typeof client<
  PostV2SurveysSurveyidAutomatedDataCollectionsMutationResponse,
  | PostV2SurveysSurveyidAutomatedDataCollections401
  | PostV2SurveysSurveyidAutomatedDataCollections403,
  PostV2SurveysSurveyidAutomatedDataCollectionsMutationRequest
>
type PostV2SurveysSurveyidAutomatedDataCollections = {
  data: PostV2SurveysSurveyidAutomatedDataCollectionsMutationResponse
  error:
    | PostV2SurveysSurveyidAutomatedDataCollections401
    | PostV2SurveysSurveyidAutomatedDataCollections403
  request: PostV2SurveysSurveyidAutomatedDataCollectionsMutationRequest
  pathParams: PostV2SurveysSurveyidAutomatedDataCollectionsPathParams
  queryParams: never
  headerParams: PostV2SurveysSurveyidAutomatedDataCollectionsHeaderParams
  response: Awaited<
    ReturnType<PostV2SurveysSurveyidAutomatedDataCollectionsClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV2SurveysSurveyidAutomatedDataCollectionsClient>[0]
    >
    return: Awaited<
      ReturnType<PostV2SurveysSurveyidAutomatedDataCollectionsClient>
    >
  }
}
/**
 * @link /v2/surveys/:surveyId/automated-data-collections
 */
export function usePostV2SurveysSurveyidAutomatedDataCollections(
  options: {
    mutation?: UseMutationOptions<
      PostV2SurveysSurveyidAutomatedDataCollections['response'],
      PostV2SurveysSurveyidAutomatedDataCollections['error'],
      {
        surveyId: PostV2SurveysSurveyidAutomatedDataCollectionsPathParams['surveyId']
        headers?: PostV2SurveysSurveyidAutomatedDataCollections['headerParams']
        data?: PostV2SurveysSurveyidAutomatedDataCollections['request']
      }
    >
    client?: PostV2SurveysSurveyidAutomatedDataCollections['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers, data }) => {
      const res = await client<
        PostV2SurveysSurveyidAutomatedDataCollections['data'],
        PostV2SurveysSurveyidAutomatedDataCollections['error'],
        PostV2SurveysSurveyidAutomatedDataCollections['request']
      >({
        method: 'post',
        url: `/v2/surveys/${surveyId}/automated-data-collections`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
