import { vercel } from '@t3-oss/env-core/presets'
import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  extends: [vercel()],
  client: {
    NEXT_PUBLIC_API_ENDPOINT_URL: z.string().min(1),
    NEXT_PUBLIC_AUTH_IDENTITY_SERVER4_ISSUER: z.string().min(1),
    NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME: z.string().min(1),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  },
  runtimeEnv: {
    NEXT_PUBLIC_API_ENDPOINT_URL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
    NEXT_PUBLIC_AUTH_IDENTITY_SERVER4_ISSUER:
      process.env.NEXT_PUBLIC_AUTH_IDENTITY_SERVER4_ISSUER,
    NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME:
      process.env.NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME,
    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
})
