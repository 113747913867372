// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1AccountadminAccountidTournamentMutationRequest,
  PostV1AccountadminAccountidTournamentMutationResponse,
  PostV1AccountadminAccountidTournamentPathParams,
  PostV1AccountadminAccountidTournament401,
  PostV1AccountadminAccountidTournament403,
} from '../models/PostV1AccountadminAccountidTournament'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1AccountadminAccountidTournamentClient = typeof client<
  PostV1AccountadminAccountidTournamentMutationResponse,
  | PostV1AccountadminAccountidTournament401
  | PostV1AccountadminAccountidTournament403,
  PostV1AccountadminAccountidTournamentMutationRequest
>
type PostV1AccountadminAccountidTournament = {
  data: PostV1AccountadminAccountidTournamentMutationResponse
  error:
    | PostV1AccountadminAccountidTournament401
    | PostV1AccountadminAccountidTournament403
  request: PostV1AccountadminAccountidTournamentMutationRequest
  pathParams: PostV1AccountadminAccountidTournamentPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1AccountadminAccountidTournamentClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1AccountadminAccountidTournamentClient>[0]
    >
    return: Awaited<ReturnType<PostV1AccountadminAccountidTournamentClient>>
  }
}
/**
 * @link /v1/accountadmin/:accountId/tournament
 */
export function usePostV1AccountadminAccountidTournament(
  options: {
    mutation?: UseMutationOptions<
      PostV1AccountadminAccountidTournament['response'],
      PostV1AccountadminAccountidTournament['error'],
      {
        accountId: PostV1AccountadminAccountidTournamentPathParams['accountId']
        data: PostV1AccountadminAccountidTournament['request']
      }
    >
    client?: PostV1AccountadminAccountidTournament['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PostV1AccountadminAccountidTournament['data'],
        PostV1AccountadminAccountidTournament['error'],
        PostV1AccountadminAccountidTournament['request']
      >({
        method: 'post',
        url: `/v1/accountadmin/${accountId}/tournament`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
