// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsDistributionBenchmarkMutationRequest,
  PostV1ResultsDistributionBenchmarkMutationResponse,
  PostV1ResultsDistributionBenchmarkHeaderParams,
  PostV1ResultsDistributionBenchmark401,
  PostV1ResultsDistributionBenchmark403,
} from '../models/PostV1ResultsDistributionBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsDistributionBenchmarkClient = typeof client<
  PostV1ResultsDistributionBenchmarkMutationResponse,
  PostV1ResultsDistributionBenchmark401 | PostV1ResultsDistributionBenchmark403,
  PostV1ResultsDistributionBenchmarkMutationRequest
>
type PostV1ResultsDistributionBenchmark = {
  data: PostV1ResultsDistributionBenchmarkMutationResponse
  error:
    | PostV1ResultsDistributionBenchmark401
    | PostV1ResultsDistributionBenchmark403
  request: PostV1ResultsDistributionBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsDistributionBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsDistributionBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsDistributionBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsDistributionBenchmarkClient>>
  }
}
export const postV1ResultsDistributionBenchmarkQueryKey = (
  data: PostV1ResultsDistributionBenchmark['request'],
) =>
  [
    { url: '/v1/results/distribution/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsDistributionBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsDistributionBenchmarkQueryKey
>
export function postV1ResultsDistributionBenchmarkQueryOptions(
  data: PostV1ResultsDistributionBenchmark['request'],
  headers?: PostV1ResultsDistributionBenchmark['headerParams'],
  options: PostV1ResultsDistributionBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDistributionBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDistributionBenchmark['data'],
        PostV1ResultsDistributionBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/distribution/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/distribution/benchmark
 */
export function usePostV1ResultsDistributionBenchmark<
  TData = PostV1ResultsDistributionBenchmark['response'],
  TQueryData = PostV1ResultsDistributionBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsDistributionBenchmarkQueryKey,
>(
  data: PostV1ResultsDistributionBenchmark['request'],
  headers?: PostV1ResultsDistributionBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsDistributionBenchmark['response'],
        PostV1ResultsDistributionBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDistributionBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsDistributionBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDistributionBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsDistributionBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsDistributionBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsDistributionBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsDistributionBenchmark['request'],
) =>
  [
    { url: '/v1/results/distribution/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsDistributionBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsDistributionBenchmarkSuspenseQueryKey
>
export function postV1ResultsDistributionBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsDistributionBenchmark['request'],
  headers?: PostV1ResultsDistributionBenchmark['headerParams'],
  options: PostV1ResultsDistributionBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDistributionBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDistributionBenchmark['data'],
        PostV1ResultsDistributionBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/distribution/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/distribution/benchmark
 */
export function usePostV1ResultsDistributionBenchmarkSuspense<
  TData = PostV1ResultsDistributionBenchmark['response'],
  TQueryKey extends
    QueryKey = PostV1ResultsDistributionBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsDistributionBenchmark['request'],
  headers?: PostV1ResultsDistributionBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsDistributionBenchmark['response'],
        PostV1ResultsDistributionBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDistributionBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1ResultsDistributionBenchmark['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsDistributionBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsDistributionBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsDistributionBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
