// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1GenericAccountmetricsAccountmetricidSettingsQueryResponse,
  GetV1GenericAccountmetricsAccountmetricidSettingsPathParams,
  GetV1GenericAccountmetricsAccountmetricidSettingsHeaderParams,
  GetV1GenericAccountmetricsAccountmetricidSettings401,
  GetV1GenericAccountmetricsAccountmetricidSettings403,
} from '../models/GetV1GenericAccountmetricsAccountmetricidSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1GenericAccountmetricsAccountmetricidSettingsClient = typeof client<
  GetV1GenericAccountmetricsAccountmetricidSettingsQueryResponse,
  | GetV1GenericAccountmetricsAccountmetricidSettings401
  | GetV1GenericAccountmetricsAccountmetricidSettings403,
  never
>
type GetV1GenericAccountmetricsAccountmetricidSettings = {
  data: GetV1GenericAccountmetricsAccountmetricidSettingsQueryResponse
  error:
    | GetV1GenericAccountmetricsAccountmetricidSettings401
    | GetV1GenericAccountmetricsAccountmetricidSettings403
  request: never
  pathParams: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams
  queryParams: never
  headerParams: GetV1GenericAccountmetricsAccountmetricidSettingsHeaderParams
  response: Awaited<
    ReturnType<GetV1GenericAccountmetricsAccountmetricidSettingsClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1GenericAccountmetricsAccountmetricidSettingsClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1GenericAccountmetricsAccountmetricidSettingsClient>
    >
  }
}
export const getV1GenericAccountmetricsAccountmetricidSettingsQueryKey = (
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
) =>
  [
    {
      url: '/v1/generic/accountmetrics/:accountMetricId/settings',
      params: { accountMetricId: accountMetricId },
    },
  ] as const
export type GetV1GenericAccountmetricsAccountmetricidSettingsQueryKey =
  ReturnType<typeof getV1GenericAccountmetricsAccountmetricidSettingsQueryKey>
export function getV1GenericAccountmetricsAccountmetricidSettingsQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidSettingsQueryKey(accountMetricId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidSettings<
  TData = GetV1GenericAccountmetricsAccountmetricidSettings['response'],
  TQueryData = GetV1GenericAccountmetricsAccountmetricidSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidSettingsQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1GenericAccountmetricsAccountmetricidSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidSettings['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidSettingsQueryKey(accountMetricId)
  const query = useQuery({
    ...(getV1GenericAccountmetricsAccountmetricidSettingsQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/settings',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidSettingsSuspense<
  TData = GetV1GenericAccountmetricsAccountmetricidSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1GenericAccountmetricsAccountmetricidSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryKey(
      accountMetricId,
    )
  const query = useSuspenseQuery({
    ...(getV1GenericAccountmetricsAccountmetricidSettingsSuspenseQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
