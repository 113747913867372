// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1TeamInvitesInviteidMutationRequest,
  PutV1TeamInvitesInviteidMutationResponse,
  PutV1TeamInvitesInviteidPathParams,
  PutV1TeamInvitesInviteid401,
  PutV1TeamInvitesInviteid403,
} from '../models/PutV1TeamInvitesInviteid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1TeamInvitesInviteidClient = typeof client<
  PutV1TeamInvitesInviteidMutationResponse,
  PutV1TeamInvitesInviteid401 | PutV1TeamInvitesInviteid403,
  PutV1TeamInvitesInviteidMutationRequest
>
type PutV1TeamInvitesInviteid = {
  data: PutV1TeamInvitesInviteidMutationResponse
  error: PutV1TeamInvitesInviteid401 | PutV1TeamInvitesInviteid403
  request: PutV1TeamInvitesInviteidMutationRequest
  pathParams: PutV1TeamInvitesInviteidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PutV1TeamInvitesInviteidClient>>
  client: {
    parameters: Partial<Parameters<PutV1TeamInvitesInviteidClient>[0]>
    return: Awaited<ReturnType<PutV1TeamInvitesInviteidClient>>
  }
}
/**
 * @link /v1/team/invites/:inviteId
 */
export function usePutV1TeamInvitesInviteid(
  options: {
    mutation?: UseMutationOptions<
      PutV1TeamInvitesInviteid['response'],
      PutV1TeamInvitesInviteid['error'],
      {
        inviteId: PutV1TeamInvitesInviteidPathParams['inviteId']
        data?: PutV1TeamInvitesInviteid['request']
      }
    >
    client?: PutV1TeamInvitesInviteid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ inviteId, data }) => {
      const res = await client<
        PutV1TeamInvitesInviteid['data'],
        PutV1TeamInvitesInviteid['error'],
        PutV1TeamInvitesInviteid['request']
      >({
        method: 'put',
        url: `/v1/team/invites/${inviteId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
