// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1GenericAccountmetricsEconomicsaccountsMutationRequest,
  PutV1GenericAccountmetricsEconomicsaccountsMutationResponse,
  PutV1GenericAccountmetricsEconomicsaccountsHeaderParams,
  PutV1GenericAccountmetricsEconomicsaccounts401,
  PutV1GenericAccountmetricsEconomicsaccounts403,
} from '../models/PutV1GenericAccountmetricsEconomicsaccounts'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1GenericAccountmetricsEconomicsaccountsClient = typeof client<
  PutV1GenericAccountmetricsEconomicsaccountsMutationResponse,
  | PutV1GenericAccountmetricsEconomicsaccounts401
  | PutV1GenericAccountmetricsEconomicsaccounts403,
  PutV1GenericAccountmetricsEconomicsaccountsMutationRequest
>
type PutV1GenericAccountmetricsEconomicsaccounts = {
  data: PutV1GenericAccountmetricsEconomicsaccountsMutationResponse
  error:
    | PutV1GenericAccountmetricsEconomicsaccounts401
    | PutV1GenericAccountmetricsEconomicsaccounts403
  request: PutV1GenericAccountmetricsEconomicsaccountsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PutV1GenericAccountmetricsEconomicsaccountsHeaderParams
  response: Awaited<
    ReturnType<PutV1GenericAccountmetricsEconomicsaccountsClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1GenericAccountmetricsEconomicsaccountsClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1GenericAccountmetricsEconomicsaccountsClient>
    >
  }
}
/**
 * @link /v1/generic/accountmetrics/economicsaccounts
 */
export function usePutV1GenericAccountmetricsEconomicsaccounts(
  options: {
    mutation?: UseMutationOptions<
      PutV1GenericAccountmetricsEconomicsaccounts['response'],
      PutV1GenericAccountmetricsEconomicsaccounts['error'],
      {
        headers?: PutV1GenericAccountmetricsEconomicsaccounts['headerParams']
        data: PutV1GenericAccountmetricsEconomicsaccounts['request']
      }
    >
    client?: PutV1GenericAccountmetricsEconomicsaccounts['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PutV1GenericAccountmetricsEconomicsaccounts['data'],
        PutV1GenericAccountmetricsEconomicsaccounts['error'],
        PutV1GenericAccountmetricsEconomicsaccounts['request']
      >({
        method: 'put',
        url: `/v1/generic/accountmetrics/economicsaccounts`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
