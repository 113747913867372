// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1BiSettingsTeetimeboundariesMutationRequest,
  PutV1BiSettingsTeetimeboundariesMutationResponse,
  PutV1BiSettingsTeetimeboundariesHeaderParams,
  PutV1BiSettingsTeetimeboundaries401,
  PutV1BiSettingsTeetimeboundaries403,
} from '../models/PutV1BiSettingsTeetimeboundaries'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1BiSettingsTeetimeboundariesClient = typeof client<
  PutV1BiSettingsTeetimeboundariesMutationResponse,
  PutV1BiSettingsTeetimeboundaries401 | PutV1BiSettingsTeetimeboundaries403,
  PutV1BiSettingsTeetimeboundariesMutationRequest
>
type PutV1BiSettingsTeetimeboundaries = {
  data: PutV1BiSettingsTeetimeboundariesMutationResponse
  error:
    | PutV1BiSettingsTeetimeboundaries401
    | PutV1BiSettingsTeetimeboundaries403
  request: PutV1BiSettingsTeetimeboundariesMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PutV1BiSettingsTeetimeboundariesHeaderParams
  response: Awaited<ReturnType<PutV1BiSettingsTeetimeboundariesClient>>
  client: {
    parameters: Partial<Parameters<PutV1BiSettingsTeetimeboundariesClient>[0]>
    return: Awaited<ReturnType<PutV1BiSettingsTeetimeboundariesClient>>
  }
}
/**
 * @link /v1/bi/settings/teetimeboundaries
 */
export function usePutV1BiSettingsTeetimeboundaries(
  options: {
    mutation?: UseMutationOptions<
      PutV1BiSettingsTeetimeboundaries['response'],
      PutV1BiSettingsTeetimeboundaries['error'],
      {
        headers?: PutV1BiSettingsTeetimeboundaries['headerParams']
        data: PutV1BiSettingsTeetimeboundaries['request']
      }
    >
    client?: PutV1BiSettingsTeetimeboundaries['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PutV1BiSettingsTeetimeboundaries['data'],
        PutV1BiSettingsTeetimeboundaries['error'],
        PutV1BiSettingsTeetimeboundaries['request']
      >({
        method: 'put',
        url: `/v1/bi/settings/teetimeboundaries`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
