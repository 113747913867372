// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsDataentriesPublishMutationRequest,
  PostV1GenericAccountmetricsDataentriesPublishMutationResponse,
  PostV1GenericAccountmetricsDataentriesPublishHeaderParams,
  PostV1GenericAccountmetricsDataentriesPublish401,
  PostV1GenericAccountmetricsDataentriesPublish403,
} from '../models/PostV1GenericAccountmetricsDataentriesPublish'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1GenericAccountmetricsDataentriesPublishClient = typeof client<
  PostV1GenericAccountmetricsDataentriesPublishMutationResponse,
  | PostV1GenericAccountmetricsDataentriesPublish401
  | PostV1GenericAccountmetricsDataentriesPublish403,
  PostV1GenericAccountmetricsDataentriesPublishMutationRequest
>
type PostV1GenericAccountmetricsDataentriesPublish = {
  data: PostV1GenericAccountmetricsDataentriesPublishMutationResponse
  error:
    | PostV1GenericAccountmetricsDataentriesPublish401
    | PostV1GenericAccountmetricsDataentriesPublish403
  request: PostV1GenericAccountmetricsDataentriesPublishMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsDataentriesPublishHeaderParams
  response: Awaited<
    ReturnType<PostV1GenericAccountmetricsDataentriesPublishClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsDataentriesPublishClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1GenericAccountmetricsDataentriesPublishClient>
    >
  }
}
/**
 * @link /v1/generic/accountmetrics/dataentries/publish
 */
export function usePostV1GenericAccountmetricsDataentriesPublish(
  options: {
    mutation?: UseMutationOptions<
      PostV1GenericAccountmetricsDataentriesPublish['response'],
      PostV1GenericAccountmetricsDataentriesPublish['error'],
      {
        headers?: PostV1GenericAccountmetricsDataentriesPublish['headerParams']
        data: PostV1GenericAccountmetricsDataentriesPublish['request']
      }
    >
    client?: PostV1GenericAccountmetricsDataentriesPublish['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1GenericAccountmetricsDataentriesPublish['data'],
        PostV1GenericAccountmetricsDataentriesPublish['error'],
        PostV1GenericAccountmetricsDataentriesPublish['request']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/dataentries/publish`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
