// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveyIncentivesDrawAWinnerMutationRequest,
  PostV1SurveyIncentivesDrawAWinnerMutationResponse,
  PostV1SurveyIncentivesDrawAWinnerHeaderParams,
  PostV1SurveyIncentivesDrawAWinner401,
  PostV1SurveyIncentivesDrawAWinner403,
} from '../models/PostV1SurveyIncentivesDrawAWinner'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveyIncentivesDrawAWinnerClient = typeof client<
  PostV1SurveyIncentivesDrawAWinnerMutationResponse,
  PostV1SurveyIncentivesDrawAWinner401 | PostV1SurveyIncentivesDrawAWinner403,
  PostV1SurveyIncentivesDrawAWinnerMutationRequest
>
type PostV1SurveyIncentivesDrawAWinner = {
  data: PostV1SurveyIncentivesDrawAWinnerMutationResponse
  error:
    | PostV1SurveyIncentivesDrawAWinner401
    | PostV1SurveyIncentivesDrawAWinner403
  request: PostV1SurveyIncentivesDrawAWinnerMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1SurveyIncentivesDrawAWinnerHeaderParams
  response: Awaited<ReturnType<PostV1SurveyIncentivesDrawAWinnerClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveyIncentivesDrawAWinnerClient>[0]>
    return: Awaited<ReturnType<PostV1SurveyIncentivesDrawAWinnerClient>>
  }
}
/**
 * @link /v1/survey-incentives/draw-a-winner
 */
export function usePostV1SurveyIncentivesDrawAWinner(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveyIncentivesDrawAWinner['response'],
      PostV1SurveyIncentivesDrawAWinner['error'],
      {
        headers?: PostV1SurveyIncentivesDrawAWinner['headerParams']
        data: PostV1SurveyIncentivesDrawAWinner['request']
      }
    >
    client?: PostV1SurveyIncentivesDrawAWinner['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1SurveyIncentivesDrawAWinner['data'],
        PostV1SurveyIncentivesDrawAWinner['error'],
        PostV1SurveyIncentivesDrawAWinner['request']
      >({
        method: 'post',
        url: `/v1/survey-incentives/draw-a-winner`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
