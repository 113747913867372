// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionSingleMutationRequest,
  PostV1SurveysSurveyidQuestionSingleMutationResponse,
  PostV1SurveysSurveyidQuestionSinglePathParams,
  PostV1SurveysSurveyidQuestionSingleHeaderParams,
  PostV1SurveysSurveyidQuestionSingle401,
  PostV1SurveysSurveyidQuestionSingle403,
} from '../models/PostV1SurveysSurveyidQuestionSingle'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionSingleClient = typeof client<
  PostV1SurveysSurveyidQuestionSingleMutationResponse,
  | PostV1SurveysSurveyidQuestionSingle401
  | PostV1SurveysSurveyidQuestionSingle403,
  PostV1SurveysSurveyidQuestionSingleMutationRequest
>
type PostV1SurveysSurveyidQuestionSingle = {
  data: PostV1SurveysSurveyidQuestionSingleMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionSingle401
    | PostV1SurveysSurveyidQuestionSingle403
  request: PostV1SurveysSurveyidQuestionSingleMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionSinglePathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionSingleHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidQuestionSingleClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidQuestionSingleClient>[0]
    >
    return: Awaited<ReturnType<PostV1SurveysSurveyidQuestionSingleClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/single
 */
export function usePostV1SurveysSurveyidQuestionSingle(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidQuestionSingle['response'],
      PostV1SurveysSurveyidQuestionSingle['error'],
      {
        surveyId: PostV1SurveysSurveyidQuestionSinglePathParams['surveyId']
        headers?: PostV1SurveysSurveyidQuestionSingle['headerParams']
        data: PostV1SurveysSurveyidQuestionSingle['request']
      }
    >
    client?: PostV1SurveysSurveyidQuestionSingle['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidQuestionSingle['data'],
        PostV1SurveysSurveyidQuestionSingle['error'],
        PostV1SurveysSurveyidQuestionSingle['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/single`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
