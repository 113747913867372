// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1AccountsAccountidMutationRequest,
  PutV1AccountsAccountidMutationResponse,
  PutV1AccountsAccountidPathParams,
  PutV1AccountsAccountid401,
  PutV1AccountsAccountid403,
} from '../models/PutV1AccountsAccountid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1AccountsAccountidClient = typeof client<
  PutV1AccountsAccountidMutationResponse,
  PutV1AccountsAccountid401 | PutV1AccountsAccountid403,
  PutV1AccountsAccountidMutationRequest
>
type PutV1AccountsAccountid = {
  data: PutV1AccountsAccountidMutationResponse
  error: PutV1AccountsAccountid401 | PutV1AccountsAccountid403
  request: PutV1AccountsAccountidMutationRequest
  pathParams: PutV1AccountsAccountidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PutV1AccountsAccountidClient>>
  client: {
    parameters: Partial<Parameters<PutV1AccountsAccountidClient>[0]>
    return: Awaited<ReturnType<PutV1AccountsAccountidClient>>
  }
}
/**
 * @link /v1/accounts/:accountId
 */
export function usePutV1AccountsAccountid(
  options: {
    mutation?: UseMutationOptions<
      PutV1AccountsAccountid['response'],
      PutV1AccountsAccountid['error'],
      {
        accountId: PutV1AccountsAccountidPathParams['accountId']
        data: PutV1AccountsAccountid['request']
      }
    >
    client?: PutV1AccountsAccountid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PutV1AccountsAccountid['data'],
        PutV1AccountsAccountid['error'],
        PutV1AccountsAccountid['request']
      >({
        method: 'put',
        url: `/v1/accounts/${accountId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
