// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1SurveysSurveyidServiceAreasServiceareaidMutationResponse,
  DeleteV1SurveysSurveyidServiceAreasServiceareaidPathParams,
  DeleteV1SurveysSurveyidServiceAreasServiceareaidHeaderParams,
  DeleteV1SurveysSurveyidServiceAreasServiceareaid401,
  DeleteV1SurveysSurveyidServiceAreasServiceareaid403,
} from '../models/DeleteV1SurveysSurveyidServiceAreasServiceareaid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1SurveysSurveyidServiceAreasServiceareaidClient = typeof client<
  DeleteV1SurveysSurveyidServiceAreasServiceareaidMutationResponse,
  | DeleteV1SurveysSurveyidServiceAreasServiceareaid401
  | DeleteV1SurveysSurveyidServiceAreasServiceareaid403,
  never
>
type DeleteV1SurveysSurveyidServiceAreasServiceareaid = {
  data: DeleteV1SurveysSurveyidServiceAreasServiceareaidMutationResponse
  error:
    | DeleteV1SurveysSurveyidServiceAreasServiceareaid401
    | DeleteV1SurveysSurveyidServiceAreasServiceareaid403
  request: never
  pathParams: DeleteV1SurveysSurveyidServiceAreasServiceareaidPathParams
  queryParams: never
  headerParams: DeleteV1SurveysSurveyidServiceAreasServiceareaidHeaderParams
  response: Awaited<
    ReturnType<DeleteV1SurveysSurveyidServiceAreasServiceareaidClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1SurveysSurveyidServiceAreasServiceareaidClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1SurveysSurveyidServiceAreasServiceareaidClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/service-areas/:serviceAreaId
 */
export function useDeleteV1SurveysSurveyidServiceAreasServiceareaid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1SurveysSurveyidServiceAreasServiceareaid['response'],
      DeleteV1SurveysSurveyidServiceAreasServiceareaid['error'],
      {
        surveyId: DeleteV1SurveysSurveyidServiceAreasServiceareaidPathParams['surveyId']
        serviceAreaId: DeleteV1SurveysSurveyidServiceAreasServiceareaidPathParams['serviceAreaId']
        headers?: DeleteV1SurveysSurveyidServiceAreasServiceareaid['headerParams']
      }
    >
    client?: DeleteV1SurveysSurveyidServiceAreasServiceareaid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, serviceAreaId, headers }) => {
      const res = await client<
        DeleteV1SurveysSurveyidServiceAreasServiceareaid['data'],
        DeleteV1SurveysSurveyidServiceAreasServiceareaid['error'],
        DeleteV1SurveysSurveyidServiceAreasServiceareaid['request']
      >({
        method: 'delete',
        url: `/v1/surveys/${surveyId}/service-areas/${serviceAreaId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
