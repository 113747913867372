import { deleteCookie, getCookie, setCookie } from 'cookies-next/client'

export function getAccountId() {
  return getCookie('accountId') ?? ''
}

export function getDirectAccessAccountId() {
  return getCookie('directAccessAccountId') ?? ''
}

export function setAccountId(accountId: string) {
  return setCookie('accountId', accountId)
}

export function setDirectAccessAccountId(directAccessAccountId: string) {
  return setCookie('directAccessAccountId', directAccessAccountId)
}

export function deleteAccountId() {
  return deleteCookie('accountId')
}

export function deleteDirectAccessAccountId() {
  return deleteCookie('directAccessAccountId')
}
