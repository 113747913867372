// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ResultsExportPptBasicMutationRequest,
  PostV1ResultsExportPptBasicMutationResponse,
  PostV1ResultsExportPptBasicHeaderParams,
  PostV1ResultsExportPptBasic401,
  PostV1ResultsExportPptBasic403,
} from '../models/PostV1ResultsExportPptBasic'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ResultsExportPptBasicClient = typeof client<
  PostV1ResultsExportPptBasicMutationResponse,
  PostV1ResultsExportPptBasic401 | PostV1ResultsExportPptBasic403,
  PostV1ResultsExportPptBasicMutationRequest
>
type PostV1ResultsExportPptBasic = {
  data: PostV1ResultsExportPptBasicMutationResponse
  error: PostV1ResultsExportPptBasic401 | PostV1ResultsExportPptBasic403
  request: PostV1ResultsExportPptBasicMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsExportPptBasicHeaderParams
  response: Awaited<ReturnType<PostV1ResultsExportPptBasicClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsExportPptBasicClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsExportPptBasicClient>>
  }
}
/**
 * @link /v1/results/export/PPT/basic
 */
export function usePostV1ResultsExportPptBasic(
  options: {
    mutation?: UseMutationOptions<
      PostV1ResultsExportPptBasic['response'],
      PostV1ResultsExportPptBasic['error'],
      {
        headers?: PostV1ResultsExportPptBasic['headerParams']
        data: PostV1ResultsExportPptBasic['request']
      }
    >
    client?: PostV1ResultsExportPptBasic['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1ResultsExportPptBasic['data'],
        PostV1ResultsExportPptBasic['error'],
        PostV1ResultsExportPptBasic['request']
      >({
        method: 'post',
        url: `/v1/results/export/PPT/basic`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
