// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1ProfileMutationResponse,
  DeleteV1ProfileHeaderParams,
  DeleteV1Profile401,
  DeleteV1Profile403,
} from '../models/DeleteV1Profile'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1ProfileClient = typeof client<
  DeleteV1ProfileMutationResponse,
  DeleteV1Profile401 | DeleteV1Profile403,
  never
>
type DeleteV1Profile = {
  data: DeleteV1ProfileMutationResponse
  error: DeleteV1Profile401 | DeleteV1Profile403
  request: never
  pathParams: never
  queryParams: never
  headerParams: DeleteV1ProfileHeaderParams
  response: Awaited<ReturnType<DeleteV1ProfileClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1ProfileClient>[0]>
    return: Awaited<ReturnType<DeleteV1ProfileClient>>
  }
}
/**
 * @link /v1/profile
 */
export function useDeleteV1Profile(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1Profile['response'],
      DeleteV1Profile['error'],
      {
        headers?: DeleteV1Profile['headerParams']
      }
    >
    client?: DeleteV1Profile['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ headers }) => {
      const res = await client<
        DeleteV1Profile['data'],
        DeleteV1Profile['error'],
        DeleteV1Profile['request']
      >({
        method: 'delete',
        url: `/v1/profile`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
