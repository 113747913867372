// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionTextMutationRequest,
  PostV1SurveysSurveyidQuestionTextMutationResponse,
  PostV1SurveysSurveyidQuestionTextPathParams,
  PostV1SurveysSurveyidQuestionTextHeaderParams,
  PostV1SurveysSurveyidQuestionText401,
  PostV1SurveysSurveyidQuestionText403,
} from '../models/PostV1SurveysSurveyidQuestionText'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionTextClient = typeof client<
  PostV1SurveysSurveyidQuestionTextMutationResponse,
  PostV1SurveysSurveyidQuestionText401 | PostV1SurveysSurveyidQuestionText403,
  PostV1SurveysSurveyidQuestionTextMutationRequest
>
type PostV1SurveysSurveyidQuestionText = {
  data: PostV1SurveysSurveyidQuestionTextMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionText401
    | PostV1SurveysSurveyidQuestionText403
  request: PostV1SurveysSurveyidQuestionTextMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionTextPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionTextHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidQuestionTextClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveysSurveyidQuestionTextClient>[0]>
    return: Awaited<ReturnType<PostV1SurveysSurveyidQuestionTextClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/text
 */
export function usePostV1SurveysSurveyidQuestionText(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidQuestionText['response'],
      PostV1SurveysSurveyidQuestionText['error'],
      {
        surveyId: PostV1SurveysSurveyidQuestionTextPathParams['surveyId']
        headers?: PostV1SurveysSurveyidQuestionText['headerParams']
        data?: PostV1SurveysSurveyidQuestionText['request']
      }
    >
    client?: PostV1SurveysSurveyidQuestionText['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, headers, data }) => {
      const res = await client<
        PostV1SurveysSurveyidQuestionText['data'],
        PostV1SurveysSurveyidQuestionText['error'],
        PostV1SurveysSurveyidQuestionText['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/text`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
