// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1BiSettingsResourceResourceidMutationRequest,
  PutV1BiSettingsResourceResourceidMutationResponse,
  PutV1BiSettingsResourceResourceidPathParams,
  PutV1BiSettingsResourceResourceidHeaderParams,
  PutV1BiSettingsResourceResourceid401,
  PutV1BiSettingsResourceResourceid403,
} from '../models/PutV1BiSettingsResourceResourceid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1BiSettingsResourceResourceidClient = typeof client<
  PutV1BiSettingsResourceResourceidMutationResponse,
  PutV1BiSettingsResourceResourceid401 | PutV1BiSettingsResourceResourceid403,
  PutV1BiSettingsResourceResourceidMutationRequest
>
type PutV1BiSettingsResourceResourceid = {
  data: PutV1BiSettingsResourceResourceidMutationResponse
  error:
    | PutV1BiSettingsResourceResourceid401
    | PutV1BiSettingsResourceResourceid403
  request: PutV1BiSettingsResourceResourceidMutationRequest
  pathParams: PutV1BiSettingsResourceResourceidPathParams
  queryParams: never
  headerParams: PutV1BiSettingsResourceResourceidHeaderParams
  response: Awaited<ReturnType<PutV1BiSettingsResourceResourceidClient>>
  client: {
    parameters: Partial<Parameters<PutV1BiSettingsResourceResourceidClient>[0]>
    return: Awaited<ReturnType<PutV1BiSettingsResourceResourceidClient>>
  }
}
/**
 * @link /v1/bi/settings/resource/:resourceId
 */
export function usePutV1BiSettingsResourceResourceid(
  options: {
    mutation?: UseMutationOptions<
      PutV1BiSettingsResourceResourceid['response'],
      PutV1BiSettingsResourceResourceid['error'],
      {
        resourceId: PutV1BiSettingsResourceResourceidPathParams['resourceId']
        headers?: PutV1BiSettingsResourceResourceid['headerParams']
        data?: PutV1BiSettingsResourceResourceid['request']
      }
    >
    client?: PutV1BiSettingsResourceResourceid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ resourceId, headers, data }) => {
      const res = await client<
        PutV1BiSettingsResourceResourceid['data'],
        PutV1BiSettingsResourceResourceid['error'],
        PutV1BiSettingsResourceResourceid['request']
      >({
        method: 'put',
        url: `/v1/bi/settings/resource/${resourceId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
