// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ContextsMeQueryResponse,
  GetV1ContextsMe401,
  GetV1ContextsMe403,
} from '../models/GetV1ContextsMe'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ContextsMeClient = typeof client<
  GetV1ContextsMeQueryResponse,
  GetV1ContextsMe401 | GetV1ContextsMe403,
  never
>
type GetV1ContextsMe = {
  data: GetV1ContextsMeQueryResponse
  error: GetV1ContextsMe401 | GetV1ContextsMe403
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1ContextsMeClient>>
  client: {
    parameters: Partial<Parameters<GetV1ContextsMeClient>[0]>
    return: Awaited<ReturnType<GetV1ContextsMeClient>>
  }
}
export const getV1ContextsMeQueryKey = () =>
  [{ url: '/v1/contexts/me' }] as const
export type GetV1ContextsMeQueryKey = ReturnType<typeof getV1ContextsMeQueryKey>
export function getV1ContextsMeQueryOptions(
  options: GetV1ContextsMe['client']['parameters'] = {},
) {
  const queryKey = getV1ContextsMeQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ContextsMe['data'],
        GetV1ContextsMe['error']
      >({
        method: 'get',
        url: `/v1/contexts/me`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/contexts/me
 */
export function useGetV1ContextsMe<
  TData = GetV1ContextsMe['response'],
  TQueryData = GetV1ContextsMe['response'],
  TQueryKey extends QueryKey = GetV1ContextsMeQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ContextsMe['response'],
        GetV1ContextsMe['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ContextsMe['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1ContextsMe['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1ContextsMeQueryKey()
  const query = useQuery({
    ...(getV1ContextsMeQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1ContextsMe['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ContextsMeSuspenseQueryKey = () =>
  [{ url: '/v1/contexts/me' }] as const
export type GetV1ContextsMeSuspenseQueryKey = ReturnType<
  typeof getV1ContextsMeSuspenseQueryKey
>
export function getV1ContextsMeSuspenseQueryOptions(
  options: GetV1ContextsMe['client']['parameters'] = {},
) {
  const queryKey = getV1ContextsMeSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ContextsMe['data'],
        GetV1ContextsMe['error']
      >({
        method: 'get',
        url: `/v1/contexts/me`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/contexts/me
 */
export function useGetV1ContextsMeSuspense<
  TData = GetV1ContextsMe['response'],
  TQueryKey extends QueryKey = GetV1ContextsMeSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ContextsMe['response'],
        GetV1ContextsMe['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ContextsMe['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1ContextsMe['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1ContextsMeSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1ContextsMeSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1ContextsMe['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
