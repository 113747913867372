// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1BenchmarksBenchmarkidSurveysSurveyidMutationResponse,
  DeleteV1BenchmarksBenchmarkidSurveysSurveyidPathParams,
  DeleteV1BenchmarksBenchmarkidSurveysSurveyidHeaderParams,
  DeleteV1BenchmarksBenchmarkidSurveysSurveyid401,
  DeleteV1BenchmarksBenchmarkidSurveysSurveyid403,
} from '../models/DeleteV1BenchmarksBenchmarkidSurveysSurveyid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1BenchmarksBenchmarkidSurveysSurveyidClient = typeof client<
  DeleteV1BenchmarksBenchmarkidSurveysSurveyidMutationResponse,
  | DeleteV1BenchmarksBenchmarkidSurveysSurveyid401
  | DeleteV1BenchmarksBenchmarkidSurveysSurveyid403,
  never
>
type DeleteV1BenchmarksBenchmarkidSurveysSurveyid = {
  data: DeleteV1BenchmarksBenchmarkidSurveysSurveyidMutationResponse
  error:
    | DeleteV1BenchmarksBenchmarkidSurveysSurveyid401
    | DeleteV1BenchmarksBenchmarkidSurveysSurveyid403
  request: never
  pathParams: DeleteV1BenchmarksBenchmarkidSurveysSurveyidPathParams
  queryParams: never
  headerParams: DeleteV1BenchmarksBenchmarkidSurveysSurveyidHeaderParams
  response: Awaited<
    ReturnType<DeleteV1BenchmarksBenchmarkidSurveysSurveyidClient>
  >
  client: {
    parameters: Partial<
      Parameters<DeleteV1BenchmarksBenchmarkidSurveysSurveyidClient>[0]
    >
    return: Awaited<
      ReturnType<DeleteV1BenchmarksBenchmarkidSurveysSurveyidClient>
    >
  }
}
/**
 * @link /v1/benchmarks/:benchmarkId/surveys/:surveyId
 */
export function useDeleteV1BenchmarksBenchmarkidSurveysSurveyid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1BenchmarksBenchmarkidSurveysSurveyid['response'],
      DeleteV1BenchmarksBenchmarkidSurveysSurveyid['error'],
      {
        benchmarkId: DeleteV1BenchmarksBenchmarkidSurveysSurveyidPathParams['benchmarkId']
        surveyId: DeleteV1BenchmarksBenchmarkidSurveysSurveyidPathParams['surveyId']
        headers?: DeleteV1BenchmarksBenchmarkidSurveysSurveyid['headerParams']
      }
    >
    client?: DeleteV1BenchmarksBenchmarkidSurveysSurveyid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ benchmarkId, surveyId, headers }) => {
      const res = await client<
        DeleteV1BenchmarksBenchmarkidSurveysSurveyid['data'],
        DeleteV1BenchmarksBenchmarkidSurveysSurveyid['error'],
        DeleteV1BenchmarksBenchmarkidSurveysSurveyid['request']
      >({
        method: 'delete',
        url: `/v1/benchmarks/${benchmarkId}/surveys/${surveyId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
