// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ResultListsExportPptCommentListMutationRequest,
  PostV1ResultListsExportPptCommentListMutationResponse,
  PostV1ResultListsExportPptCommentListHeaderParams,
  PostV1ResultListsExportPptCommentList401,
  PostV1ResultListsExportPptCommentList403,
} from '../models/PostV1ResultListsExportPptCommentList'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ResultListsExportPptCommentListClient = typeof client<
  PostV1ResultListsExportPptCommentListMutationResponse,
  | PostV1ResultListsExportPptCommentList401
  | PostV1ResultListsExportPptCommentList403,
  PostV1ResultListsExportPptCommentListMutationRequest
>
type PostV1ResultListsExportPptCommentList = {
  data: PostV1ResultListsExportPptCommentListMutationResponse
  error:
    | PostV1ResultListsExportPptCommentList401
    | PostV1ResultListsExportPptCommentList403
  request: PostV1ResultListsExportPptCommentListMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultListsExportPptCommentListHeaderParams
  response: Awaited<ReturnType<PostV1ResultListsExportPptCommentListClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1ResultListsExportPptCommentListClient>[0]
    >
    return: Awaited<ReturnType<PostV1ResultListsExportPptCommentListClient>>
  }
}
/**
 * @link /v1/result-lists/export/PPT/comment-list
 */
export function usePostV1ResultListsExportPptCommentList(
  options: {
    mutation?: UseMutationOptions<
      PostV1ResultListsExportPptCommentList['response'],
      PostV1ResultListsExportPptCommentList['error'],
      {
        headers?: PostV1ResultListsExportPptCommentList['headerParams']
        data: PostV1ResultListsExportPptCommentList['request']
      }
    >
    client?: PostV1ResultListsExportPptCommentList['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1ResultListsExportPptCommentList['data'],
        PostV1ResultListsExportPptCommentList['error'],
        PostV1ResultListsExportPptCommentList['request']
      >({
        method: 'post',
        url: `/v1/result-lists/export/PPT/comment-list`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
