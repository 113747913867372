// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ResultsNavigationTagsAccountidQueryResponse,
  GetV1ResultsNavigationTagsAccountidPathParams,
  GetV1ResultsNavigationTagsAccountid401,
  GetV1ResultsNavigationTagsAccountid403,
} from '../models/GetV1ResultsNavigationTagsAccountid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ResultsNavigationTagsAccountidClient = typeof client<
  GetV1ResultsNavigationTagsAccountidQueryResponse,
  | GetV1ResultsNavigationTagsAccountid401
  | GetV1ResultsNavigationTagsAccountid403,
  never
>
type GetV1ResultsNavigationTagsAccountid = {
  data: GetV1ResultsNavigationTagsAccountidQueryResponse
  error:
    | GetV1ResultsNavigationTagsAccountid401
    | GetV1ResultsNavigationTagsAccountid403
  request: never
  pathParams: GetV1ResultsNavigationTagsAccountidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1ResultsNavigationTagsAccountidClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1ResultsNavigationTagsAccountidClient>[0]
    >
    return: Awaited<ReturnType<GetV1ResultsNavigationTagsAccountidClient>>
  }
}
export const getV1ResultsNavigationTagsAccountidQueryKey = (
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
) =>
  [
    {
      url: '/v1/results/navigation-tags/:accountId',
      params: { accountId: accountId },
    },
  ] as const
export type GetV1ResultsNavigationTagsAccountidQueryKey = ReturnType<
  typeof getV1ResultsNavigationTagsAccountidQueryKey
>
export function getV1ResultsNavigationTagsAccountidQueryOptions(
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
  options: GetV1ResultsNavigationTagsAccountid['client']['parameters'] = {},
) {
  const queryKey = getV1ResultsNavigationTagsAccountidQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ResultsNavigationTagsAccountid['data'],
        GetV1ResultsNavigationTagsAccountid['error']
      >({
        method: 'get',
        url: `/v1/results/navigation-tags/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/navigation-tags/:accountId
 */
export function useGetV1ResultsNavigationTagsAccountid<
  TData = GetV1ResultsNavigationTagsAccountid['response'],
  TQueryData = GetV1ResultsNavigationTagsAccountid['response'],
  TQueryKey extends QueryKey = GetV1ResultsNavigationTagsAccountidQueryKey,
>(
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ResultsNavigationTagsAccountid['response'],
        GetV1ResultsNavigationTagsAccountid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ResultsNavigationTagsAccountid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1ResultsNavigationTagsAccountid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ResultsNavigationTagsAccountidQueryKey(accountId)
  const query = useQuery({
    ...(getV1ResultsNavigationTagsAccountidQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1ResultsNavigationTagsAccountid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ResultsNavigationTagsAccountidSuspenseQueryKey = (
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
) =>
  [
    {
      url: '/v1/results/navigation-tags/:accountId',
      params: { accountId: accountId },
    },
  ] as const
export type GetV1ResultsNavigationTagsAccountidSuspenseQueryKey = ReturnType<
  typeof getV1ResultsNavigationTagsAccountidSuspenseQueryKey
>
export function getV1ResultsNavigationTagsAccountidSuspenseQueryOptions(
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
  options: GetV1ResultsNavigationTagsAccountid['client']['parameters'] = {},
) {
  const queryKey =
    getV1ResultsNavigationTagsAccountidSuspenseQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ResultsNavigationTagsAccountid['data'],
        GetV1ResultsNavigationTagsAccountid['error']
      >({
        method: 'get',
        url: `/v1/results/navigation-tags/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/navigation-tags/:accountId
 */
export function useGetV1ResultsNavigationTagsAccountidSuspense<
  TData = GetV1ResultsNavigationTagsAccountid['response'],
  TQueryKey extends
    QueryKey = GetV1ResultsNavigationTagsAccountidSuspenseQueryKey,
>(
  accountId: GetV1ResultsNavigationTagsAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ResultsNavigationTagsAccountid['response'],
        GetV1ResultsNavigationTagsAccountid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ResultsNavigationTagsAccountid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1ResultsNavigationTagsAccountid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ResultsNavigationTagsAccountidSuspenseQueryKey(accountId)
  const query = useSuspenseQuery({
    ...(getV1ResultsNavigationTagsAccountidSuspenseQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1ResultsNavigationTagsAccountid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
