// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationRequest,
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationResponse,
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodePathParams,
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeHeaderParams,
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode401,
  PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode403,
} from '../models/PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeClient =
  typeof client<
    PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationResponse,
    | PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode401
    | PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode403,
    PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationRequest
  >
type PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode = {
  data: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationResponse
  error:
    | PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode401
    | PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode403
  request: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeMutationRequest
  pathParams: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodePathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodeClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/invitation-letter/translations/:languageCode
 */
export function usePutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['response'],
      PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['error'],
      {
        surveyId: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodePathParams['surveyId']
        languageCode: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecodePathParams['languageCode']
        headers?: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['headerParams']
        data: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['request']
      }
    >
    client?: PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, languageCode, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['data'],
        PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['error'],
        PutV1SurveysSurveyidInvitationLetterTranslationsLanguagecode['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/invitation-letter/translations/${languageCode}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
