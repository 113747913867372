// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1TeamInvitesInviteidResendMutationResponse,
  PostV1TeamInvitesInviteidResendPathParams,
  PostV1TeamInvitesInviteidResendHeaderParams,
  PostV1TeamInvitesInviteidResend401,
  PostV1TeamInvitesInviteidResend403,
} from '../models/PostV1TeamInvitesInviteidResend'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1TeamInvitesInviteidResendClient = typeof client<
  PostV1TeamInvitesInviteidResendMutationResponse,
  PostV1TeamInvitesInviteidResend401 | PostV1TeamInvitesInviteidResend403,
  never
>
type PostV1TeamInvitesInviteidResend = {
  data: PostV1TeamInvitesInviteidResendMutationResponse
  error: PostV1TeamInvitesInviteidResend401 | PostV1TeamInvitesInviteidResend403
  request: never
  pathParams: PostV1TeamInvitesInviteidResendPathParams
  queryParams: never
  headerParams: PostV1TeamInvitesInviteidResendHeaderParams
  response: Awaited<ReturnType<PostV1TeamInvitesInviteidResendClient>>
  client: {
    parameters: Partial<Parameters<PostV1TeamInvitesInviteidResendClient>[0]>
    return: Awaited<ReturnType<PostV1TeamInvitesInviteidResendClient>>
  }
}
/**
 * @link /v1/team/invites/:inviteId/resend
 */
export function usePostV1TeamInvitesInviteidResend(
  options: {
    mutation?: UseMutationOptions<
      PostV1TeamInvitesInviteidResend['response'],
      PostV1TeamInvitesInviteidResend['error'],
      {
        inviteId: PostV1TeamInvitesInviteidResendPathParams['inviteId']
        headers?: PostV1TeamInvitesInviteidResend['headerParams']
      }
    >
    client?: PostV1TeamInvitesInviteidResend['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ inviteId, headers }) => {
      const res = await client<
        PostV1TeamInvitesInviteidResend['data'],
        PostV1TeamInvitesInviteidResend['error'],
        PostV1TeamInvitesInviteidResend['request']
      >({
        method: 'post',
        url: `/v1/team/invites/${inviteId}/resend`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
