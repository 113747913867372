// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ManualDataCollectionsDatacollectionidQueryResponse,
  GetV1ManualDataCollectionsDatacollectionidPathParams,
  GetV1ManualDataCollectionsDatacollectionidHeaderParams,
  GetV1ManualDataCollectionsDatacollectionid401,
  GetV1ManualDataCollectionsDatacollectionid403,
} from '../models/GetV1ManualDataCollectionsDatacollectionid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ManualDataCollectionsDatacollectionidClient = typeof client<
  GetV1ManualDataCollectionsDatacollectionidQueryResponse,
  | GetV1ManualDataCollectionsDatacollectionid401
  | GetV1ManualDataCollectionsDatacollectionid403,
  never
>
type GetV1ManualDataCollectionsDatacollectionid = {
  data: GetV1ManualDataCollectionsDatacollectionidQueryResponse
  error:
    | GetV1ManualDataCollectionsDatacollectionid401
    | GetV1ManualDataCollectionsDatacollectionid403
  request: never
  pathParams: GetV1ManualDataCollectionsDatacollectionidPathParams
  queryParams: never
  headerParams: GetV1ManualDataCollectionsDatacollectionidHeaderParams
  response: Awaited<
    ReturnType<GetV1ManualDataCollectionsDatacollectionidClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1ManualDataCollectionsDatacollectionidClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1ManualDataCollectionsDatacollectionidClient>
    >
  }
}
export const getV1ManualDataCollectionsDatacollectionidQueryKey = (
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
) =>
  [
    {
      url: '/v1/manual-data-collections/:dataCollectionId',
      params: { dataCollectionId: dataCollectionId },
    },
  ] as const
export type GetV1ManualDataCollectionsDatacollectionidQueryKey = ReturnType<
  typeof getV1ManualDataCollectionsDatacollectionidQueryKey
>
export function getV1ManualDataCollectionsDatacollectionidQueryOptions(
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV1ManualDataCollectionsDatacollectionid['headerParams'],
  options: GetV1ManualDataCollectionsDatacollectionid['client']['parameters'] = {},
) {
  const queryKey =
    getV1ManualDataCollectionsDatacollectionidQueryKey(dataCollectionId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ManualDataCollectionsDatacollectionid['data'],
        GetV1ManualDataCollectionsDatacollectionid['error']
      >({
        method: 'get',
        url: `/v1/manual-data-collections/${dataCollectionId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/manual-data-collections/:dataCollectionId
 */
export function useGetV1ManualDataCollectionsDatacollectionid<
  TData = GetV1ManualDataCollectionsDatacollectionid['response'],
  TQueryData = GetV1ManualDataCollectionsDatacollectionid['response'],
  TQueryKey extends
    QueryKey = GetV1ManualDataCollectionsDatacollectionidQueryKey,
>(
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV1ManualDataCollectionsDatacollectionid['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ManualDataCollectionsDatacollectionid['response'],
        GetV1ManualDataCollectionsDatacollectionid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ManualDataCollectionsDatacollectionid['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1ManualDataCollectionsDatacollectionid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ManualDataCollectionsDatacollectionidQueryKey(dataCollectionId)
  const query = useQuery({
    ...(getV1ManualDataCollectionsDatacollectionidQueryOptions(
      dataCollectionId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1ManualDataCollectionsDatacollectionid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ManualDataCollectionsDatacollectionidSuspenseQueryKey = (
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
) =>
  [
    {
      url: '/v1/manual-data-collections/:dataCollectionId',
      params: { dataCollectionId: dataCollectionId },
    },
  ] as const
export type GetV1ManualDataCollectionsDatacollectionidSuspenseQueryKey =
  ReturnType<typeof getV1ManualDataCollectionsDatacollectionidSuspenseQueryKey>
export function getV1ManualDataCollectionsDatacollectionidSuspenseQueryOptions(
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV1ManualDataCollectionsDatacollectionid['headerParams'],
  options: GetV1ManualDataCollectionsDatacollectionid['client']['parameters'] = {},
) {
  const queryKey =
    getV1ManualDataCollectionsDatacollectionidSuspenseQueryKey(dataCollectionId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ManualDataCollectionsDatacollectionid['data'],
        GetV1ManualDataCollectionsDatacollectionid['error']
      >({
        method: 'get',
        url: `/v1/manual-data-collections/${dataCollectionId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/manual-data-collections/:dataCollectionId
 */
export function useGetV1ManualDataCollectionsDatacollectionidSuspense<
  TData = GetV1ManualDataCollectionsDatacollectionid['response'],
  TQueryKey extends
    QueryKey = GetV1ManualDataCollectionsDatacollectionidSuspenseQueryKey,
>(
  dataCollectionId: GetV1ManualDataCollectionsDatacollectionidPathParams['dataCollectionId'],
  headers?: GetV1ManualDataCollectionsDatacollectionid['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ManualDataCollectionsDatacollectionid['response'],
        GetV1ManualDataCollectionsDatacollectionid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ManualDataCollectionsDatacollectionid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1ManualDataCollectionsDatacollectionid['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1ManualDataCollectionsDatacollectionidSuspenseQueryKey(dataCollectionId)
  const query = useSuspenseQuery({
    ...(getV1ManualDataCollectionsDatacollectionidSuspenseQueryOptions(
      dataCollectionId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1ManualDataCollectionsDatacollectionid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
