// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1AccountsAccountidQueryResponse,
  GetV1AccountsAccountidPathParams,
  GetV1AccountsAccountid401,
  GetV1AccountsAccountid403,
} from '../models/GetV1AccountsAccountid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1AccountsAccountidClient = typeof client<
  GetV1AccountsAccountidQueryResponse,
  GetV1AccountsAccountid401 | GetV1AccountsAccountid403,
  never
>
type GetV1AccountsAccountid = {
  data: GetV1AccountsAccountidQueryResponse
  error: GetV1AccountsAccountid401 | GetV1AccountsAccountid403
  request: never
  pathParams: GetV1AccountsAccountidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1AccountsAccountidClient>>
  client: {
    parameters: Partial<Parameters<GetV1AccountsAccountidClient>[0]>
    return: Awaited<ReturnType<GetV1AccountsAccountidClient>>
  }
}
export const getV1AccountsAccountidQueryKey = (
  accountId: GetV1AccountsAccountidPathParams['accountId'],
) =>
  [
    { url: '/v1/accounts/:accountId', params: { accountId: accountId } },
  ] as const
export type GetV1AccountsAccountidQueryKey = ReturnType<
  typeof getV1AccountsAccountidQueryKey
>
export function getV1AccountsAccountidQueryOptions(
  accountId: GetV1AccountsAccountidPathParams['accountId'],
  options: GetV1AccountsAccountid['client']['parameters'] = {},
) {
  const queryKey = getV1AccountsAccountidQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountsAccountid['data'],
        GetV1AccountsAccountid['error']
      >({
        method: 'get',
        url: `/v1/accounts/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accounts/:accountId
 */
export function useGetV1AccountsAccountid<
  TData = GetV1AccountsAccountid['response'],
  TQueryData = GetV1AccountsAccountid['response'],
  TQueryKey extends QueryKey = GetV1AccountsAccountidQueryKey,
>(
  accountId: GetV1AccountsAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1AccountsAccountid['response'],
        GetV1AccountsAccountid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1AccountsAccountid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1AccountsAccountid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1AccountsAccountidQueryKey(accountId)
  const query = useQuery({
    ...(getV1AccountsAccountidQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1AccountsAccountid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1AccountsAccountidSuspenseQueryKey = (
  accountId: GetV1AccountsAccountidPathParams['accountId'],
) =>
  [
    { url: '/v1/accounts/:accountId', params: { accountId: accountId } },
  ] as const
export type GetV1AccountsAccountidSuspenseQueryKey = ReturnType<
  typeof getV1AccountsAccountidSuspenseQueryKey
>
export function getV1AccountsAccountidSuspenseQueryOptions(
  accountId: GetV1AccountsAccountidPathParams['accountId'],
  options: GetV1AccountsAccountid['client']['parameters'] = {},
) {
  const queryKey = getV1AccountsAccountidSuspenseQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountsAccountid['data'],
        GetV1AccountsAccountid['error']
      >({
        method: 'get',
        url: `/v1/accounts/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accounts/:accountId
 */
export function useGetV1AccountsAccountidSuspense<
  TData = GetV1AccountsAccountid['response'],
  TQueryKey extends QueryKey = GetV1AccountsAccountidSuspenseQueryKey,
>(
  accountId: GetV1AccountsAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1AccountsAccountid['response'],
        GetV1AccountsAccountid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1AccountsAccountid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1AccountsAccountid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1AccountsAccountidSuspenseQueryKey(accountId)
  const query = useSuspenseQuery({
    ...(getV1AccountsAccountidSuspenseQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1AccountsAccountid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
