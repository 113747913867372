// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1BenchmarksContextSurveyidQueryResponse,
  GetV1BenchmarksContextSurveyidPathParams,
  GetV1BenchmarksContextSurveyidHeaderParams,
  GetV1BenchmarksContextSurveyid401,
  GetV1BenchmarksContextSurveyid403,
} from '../models/GetV1BenchmarksContextSurveyid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1BenchmarksContextSurveyidClient = typeof client<
  GetV1BenchmarksContextSurveyidQueryResponse,
  GetV1BenchmarksContextSurveyid401 | GetV1BenchmarksContextSurveyid403,
  never
>
type GetV1BenchmarksContextSurveyid = {
  data: GetV1BenchmarksContextSurveyidQueryResponse
  error: GetV1BenchmarksContextSurveyid401 | GetV1BenchmarksContextSurveyid403
  request: never
  pathParams: GetV1BenchmarksContextSurveyidPathParams
  queryParams: never
  headerParams: GetV1BenchmarksContextSurveyidHeaderParams
  response: Awaited<ReturnType<GetV1BenchmarksContextSurveyidClient>>
  client: {
    parameters: Partial<Parameters<GetV1BenchmarksContextSurveyidClient>[0]>
    return: Awaited<ReturnType<GetV1BenchmarksContextSurveyidClient>>
  }
}
export const getV1BenchmarksContextSurveyidQueryKey = (
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
) =>
  [
    { url: '/v1/benchmarks/context/:surveyId', params: { surveyId: surveyId } },
  ] as const
export type GetV1BenchmarksContextSurveyidQueryKey = ReturnType<
  typeof getV1BenchmarksContextSurveyidQueryKey
>
export function getV1BenchmarksContextSurveyidQueryOptions(
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
  headers?: GetV1BenchmarksContextSurveyid['headerParams'],
  options: GetV1BenchmarksContextSurveyid['client']['parameters'] = {},
) {
  const queryKey = getV1BenchmarksContextSurveyidQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BenchmarksContextSurveyid['data'],
        GetV1BenchmarksContextSurveyid['error']
      >({
        method: 'get',
        url: `/v1/benchmarks/context/${surveyId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/benchmarks/context/:surveyId
 */
export function useGetV1BenchmarksContextSurveyid<
  TData = GetV1BenchmarksContextSurveyid['response'],
  TQueryData = GetV1BenchmarksContextSurveyid['response'],
  TQueryKey extends QueryKey = GetV1BenchmarksContextSurveyidQueryKey,
>(
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
  headers?: GetV1BenchmarksContextSurveyid['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1BenchmarksContextSurveyid['response'],
        GetV1BenchmarksContextSurveyid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1BenchmarksContextSurveyid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1BenchmarksContextSurveyid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1BenchmarksContextSurveyidQueryKey(surveyId)
  const query = useQuery({
    ...(getV1BenchmarksContextSurveyidQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1BenchmarksContextSurveyid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1BenchmarksContextSurveyidSuspenseQueryKey = (
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
) =>
  [
    { url: '/v1/benchmarks/context/:surveyId', params: { surveyId: surveyId } },
  ] as const
export type GetV1BenchmarksContextSurveyidSuspenseQueryKey = ReturnType<
  typeof getV1BenchmarksContextSurveyidSuspenseQueryKey
>
export function getV1BenchmarksContextSurveyidSuspenseQueryOptions(
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
  headers?: GetV1BenchmarksContextSurveyid['headerParams'],
  options: GetV1BenchmarksContextSurveyid['client']['parameters'] = {},
) {
  const queryKey = getV1BenchmarksContextSurveyidSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1BenchmarksContextSurveyid['data'],
        GetV1BenchmarksContextSurveyid['error']
      >({
        method: 'get',
        url: `/v1/benchmarks/context/${surveyId}`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/benchmarks/context/:surveyId
 */
export function useGetV1BenchmarksContextSurveyidSuspense<
  TData = GetV1BenchmarksContextSurveyid['response'],
  TQueryKey extends QueryKey = GetV1BenchmarksContextSurveyidSuspenseQueryKey,
>(
  surveyId: GetV1BenchmarksContextSurveyidPathParams['surveyId'],
  headers?: GetV1BenchmarksContextSurveyid['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1BenchmarksContextSurveyid['response'],
        GetV1BenchmarksContextSurveyid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1BenchmarksContextSurveyid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1BenchmarksContextSurveyid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1BenchmarksContextSurveyidSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1BenchmarksContextSurveyidSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1BenchmarksContextSurveyid['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
