// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1AccountsAccountidTagsQueryResponse,
  GetV1AccountsAccountidTagsPathParams,
  GetV1AccountsAccountidTags401,
  GetV1AccountsAccountidTags403,
} from '../models/GetV1AccountsAccountidTags'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1AccountsAccountidTagsClient = typeof client<
  GetV1AccountsAccountidTagsQueryResponse,
  GetV1AccountsAccountidTags401 | GetV1AccountsAccountidTags403,
  never
>
type GetV1AccountsAccountidTags = {
  data: GetV1AccountsAccountidTagsQueryResponse
  error: GetV1AccountsAccountidTags401 | GetV1AccountsAccountidTags403
  request: never
  pathParams: GetV1AccountsAccountidTagsPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1AccountsAccountidTagsClient>>
  client: {
    parameters: Partial<Parameters<GetV1AccountsAccountidTagsClient>[0]>
    return: Awaited<ReturnType<GetV1AccountsAccountidTagsClient>>
  }
}
export const getV1AccountsAccountidTagsQueryKey = (
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
) =>
  [
    { url: '/v1/accounts/:accountId/tags', params: { accountId: accountId } },
  ] as const
export type GetV1AccountsAccountidTagsQueryKey = ReturnType<
  typeof getV1AccountsAccountidTagsQueryKey
>
export function getV1AccountsAccountidTagsQueryOptions(
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
  options: GetV1AccountsAccountidTags['client']['parameters'] = {},
) {
  const queryKey = getV1AccountsAccountidTagsQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountsAccountidTags['data'],
        GetV1AccountsAccountidTags['error']
      >({
        method: 'get',
        url: `/v1/accounts/${accountId}/tags`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accounts/:accountId/tags
 */
export function useGetV1AccountsAccountidTags<
  TData = GetV1AccountsAccountidTags['response'],
  TQueryData = GetV1AccountsAccountidTags['response'],
  TQueryKey extends QueryKey = GetV1AccountsAccountidTagsQueryKey,
>(
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1AccountsAccountidTags['response'],
        GetV1AccountsAccountidTags['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1AccountsAccountidTags['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1AccountsAccountidTags['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1AccountsAccountidTagsQueryKey(accountId)
  const query = useQuery({
    ...(getV1AccountsAccountidTagsQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1AccountsAccountidTags['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1AccountsAccountidTagsSuspenseQueryKey = (
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
) =>
  [
    { url: '/v1/accounts/:accountId/tags', params: { accountId: accountId } },
  ] as const
export type GetV1AccountsAccountidTagsSuspenseQueryKey = ReturnType<
  typeof getV1AccountsAccountidTagsSuspenseQueryKey
>
export function getV1AccountsAccountidTagsSuspenseQueryOptions(
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
  options: GetV1AccountsAccountidTags['client']['parameters'] = {},
) {
  const queryKey = getV1AccountsAccountidTagsSuspenseQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountsAccountidTags['data'],
        GetV1AccountsAccountidTags['error']
      >({
        method: 'get',
        url: `/v1/accounts/${accountId}/tags`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accounts/:accountId/tags
 */
export function useGetV1AccountsAccountidTagsSuspense<
  TData = GetV1AccountsAccountidTags['response'],
  TQueryKey extends QueryKey = GetV1AccountsAccountidTagsSuspenseQueryKey,
>(
  accountId: GetV1AccountsAccountidTagsPathParams['accountId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1AccountsAccountidTags['response'],
        GetV1AccountsAccountidTags['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1AccountsAccountidTags['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1AccountsAccountidTags['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1AccountsAccountidTagsSuspenseQueryKey(accountId)
  const query = useSuspenseQuery({
    ...(getV1AccountsAccountidTagsSuspenseQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1AccountsAccountidTags['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
