// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsResponseRateMutationRequest,
  PostV1ResultsResponseRateMutationResponse,
  PostV1ResultsResponseRateHeaderParams,
  PostV1ResultsResponseRate401,
  PostV1ResultsResponseRate403,
} from '../models/PostV1ResultsResponseRate'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsResponseRateClient = typeof client<
  PostV1ResultsResponseRateMutationResponse,
  PostV1ResultsResponseRate401 | PostV1ResultsResponseRate403,
  PostV1ResultsResponseRateMutationRequest
>
type PostV1ResultsResponseRate = {
  data: PostV1ResultsResponseRateMutationResponse
  error: PostV1ResultsResponseRate401 | PostV1ResultsResponseRate403
  request: PostV1ResultsResponseRateMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsResponseRateHeaderParams
  response: Awaited<ReturnType<PostV1ResultsResponseRateClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsResponseRateClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsResponseRateClient>>
  }
}
export const postV1ResultsResponseRateQueryKey = (
  data: PostV1ResultsResponseRate['request'],
) => [{ url: '/v1/results/response-rate' }, ...(data ? [data] : [])] as const
export type PostV1ResultsResponseRateQueryKey = ReturnType<
  typeof postV1ResultsResponseRateQueryKey
>
export function postV1ResultsResponseRateQueryOptions(
  data: PostV1ResultsResponseRate['request'],
  headers?: PostV1ResultsResponseRate['headerParams'],
  options: PostV1ResultsResponseRate['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsResponseRateQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsResponseRate['data'],
        PostV1ResultsResponseRate['error']
      >({
        method: 'post',
        url: `/v1/results/response-rate`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/response-rate
 */
export function usePostV1ResultsResponseRate<
  TData = PostV1ResultsResponseRate['response'],
  TQueryData = PostV1ResultsResponseRate['response'],
  TQueryKey extends QueryKey = PostV1ResultsResponseRateQueryKey,
>(
  data: PostV1ResultsResponseRate['request'],
  headers?: PostV1ResultsResponseRate['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsResponseRate['response'],
        PostV1ResultsResponseRate['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsResponseRate['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsResponseRate['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsResponseRateQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsResponseRateQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsResponseRate['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsResponseRateSuspenseQueryKey = (
  data: PostV1ResultsResponseRate['request'],
) => [{ url: '/v1/results/response-rate' }, ...(data ? [data] : [])] as const
export type PostV1ResultsResponseRateSuspenseQueryKey = ReturnType<
  typeof postV1ResultsResponseRateSuspenseQueryKey
>
export function postV1ResultsResponseRateSuspenseQueryOptions(
  data: PostV1ResultsResponseRate['request'],
  headers?: PostV1ResultsResponseRate['headerParams'],
  options: PostV1ResultsResponseRate['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsResponseRateSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsResponseRate['data'],
        PostV1ResultsResponseRate['error']
      >({
        method: 'post',
        url: `/v1/results/response-rate`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/response-rate
 */
export function usePostV1ResultsResponseRateSuspense<
  TData = PostV1ResultsResponseRate['response'],
  TQueryKey extends QueryKey = PostV1ResultsResponseRateSuspenseQueryKey,
>(
  data: PostV1ResultsResponseRate['request'],
  headers?: PostV1ResultsResponseRate['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsResponseRate['response'],
        PostV1ResultsResponseRate['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsResponseRate['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsResponseRate['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsResponseRateSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsResponseRateSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsResponseRate['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
