// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidServiceAreasMutationRequest,
  PostV1SurveysSurveyidServiceAreasMutationResponse,
  PostV1SurveysSurveyidServiceAreasPathParams,
  PostV1SurveysSurveyidServiceAreasHeaderParams,
  PostV1SurveysSurveyidServiceAreas401,
  PostV1SurveysSurveyidServiceAreas403,
} from '../models/PostV1SurveysSurveyidServiceAreas'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidServiceAreasClient = typeof client<
  PostV1SurveysSurveyidServiceAreasMutationResponse,
  PostV1SurveysSurveyidServiceAreas401 | PostV1SurveysSurveyidServiceAreas403,
  PostV1SurveysSurveyidServiceAreasMutationRequest
>
type PostV1SurveysSurveyidServiceAreas = {
  data: PostV1SurveysSurveyidServiceAreasMutationResponse
  error:
    | PostV1SurveysSurveyidServiceAreas401
    | PostV1SurveysSurveyidServiceAreas403
  request: PostV1SurveysSurveyidServiceAreasMutationRequest
  pathParams: PostV1SurveysSurveyidServiceAreasPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidServiceAreasHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidServiceAreasClient>>
  client: {
    parameters: Partial<Parameters<PostV1SurveysSurveyidServiceAreasClient>[0]>
    return: Awaited<ReturnType<PostV1SurveysSurveyidServiceAreasClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/service-areas
 */
export function usePostV1SurveysSurveyidServiceAreas(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidServiceAreas['response'],
      PostV1SurveysSurveyidServiceAreas['error'],
      {
        surveyId: PostV1SurveysSurveyidServiceAreasPathParams['surveyId']
        headers?: PostV1SurveysSurveyidServiceAreas['headerParams']
        data: PostV1SurveysSurveyidServiceAreas['request']
      }
    >
    client?: PostV1SurveysSurveyidServiceAreas['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidServiceAreas['data'],
        PostV1SurveysSurveyidServiceAreas['error'],
        PostV1SurveysSurveyidServiceAreas['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/service-areas`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
