// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type { GetV1TimezonesQueryResponse } from '../models/GetV1Timezones'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TimezonesClient = typeof client<
  GetV1TimezonesQueryResponse,
  Error,
  never
>
type GetV1Timezones = {
  data: GetV1TimezonesQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1TimezonesClient>>
  client: {
    parameters: Partial<Parameters<GetV1TimezonesClient>[0]>
    return: Awaited<ReturnType<GetV1TimezonesClient>>
  }
}
export const getV1TimezonesQueryKey = () => [{ url: '/v1/Timezones' }] as const
export type GetV1TimezonesQueryKey = ReturnType<typeof getV1TimezonesQueryKey>
export function getV1TimezonesQueryOptions(
  options: GetV1Timezones['client']['parameters'] = {},
) {
  const queryKey = getV1TimezonesQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Timezones['data'], GetV1Timezones['error']>(
        {
          method: 'get',
          url: `/v1/Timezones`,
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/Timezones
 */
export function useGetV1Timezones<
  TData = GetV1Timezones['response'],
  TQueryData = GetV1Timezones['response'],
  TQueryKey extends QueryKey = GetV1TimezonesQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1Timezones['response'],
        GetV1Timezones['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1Timezones['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1Timezones['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TimezonesQueryKey()
  const query = useQuery({
    ...(getV1TimezonesQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1Timezones['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TimezonesSuspenseQueryKey = () =>
  [{ url: '/v1/Timezones' }] as const
export type GetV1TimezonesSuspenseQueryKey = ReturnType<
  typeof getV1TimezonesSuspenseQueryKey
>
export function getV1TimezonesSuspenseQueryOptions(
  options: GetV1Timezones['client']['parameters'] = {},
) {
  const queryKey = getV1TimezonesSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<GetV1Timezones['data'], GetV1Timezones['error']>(
        {
          method: 'get',
          url: `/v1/Timezones`,
          ...options,
        },
      )
      return res
    },
  })
}
/**
 * @link /v1/Timezones
 */
export function useGetV1TimezonesSuspense<
  TData = GetV1Timezones['response'],
  TQueryKey extends QueryKey = GetV1TimezonesSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1Timezones['response'],
        GetV1Timezones['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1Timezones['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1Timezones['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TimezonesSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TimezonesSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1Timezones['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
