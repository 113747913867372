// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidPreviewLinkQueryResponse,
  GetV1SurveysSurveyidPreviewLinkPathParams,
  GetV1SurveysSurveyidPreviewLinkHeaderParams,
  GetV1SurveysSurveyidPreviewLink401,
  GetV1SurveysSurveyidPreviewLink403,
} from '../models/GetV1SurveysSurveyidPreviewLink'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidPreviewLinkClient = typeof client<
  GetV1SurveysSurveyidPreviewLinkQueryResponse,
  GetV1SurveysSurveyidPreviewLink401 | GetV1SurveysSurveyidPreviewLink403,
  never
>
type GetV1SurveysSurveyidPreviewLink = {
  data: GetV1SurveysSurveyidPreviewLinkQueryResponse
  error: GetV1SurveysSurveyidPreviewLink401 | GetV1SurveysSurveyidPreviewLink403
  request: never
  pathParams: GetV1SurveysSurveyidPreviewLinkPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidPreviewLinkHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidPreviewLinkClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysSurveyidPreviewLinkClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysSurveyidPreviewLinkClient>>
  }
}
export const getV1SurveysSurveyidPreviewLinkQueryKey = (
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/preview-link',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidPreviewLinkQueryKey = ReturnType<
  typeof getV1SurveysSurveyidPreviewLinkQueryKey
>
export function getV1SurveysSurveyidPreviewLinkQueryOptions(
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidPreviewLink['headerParams'],
  options: GetV1SurveysSurveyidPreviewLink['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidPreviewLinkQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidPreviewLink['data'],
        GetV1SurveysSurveyidPreviewLink['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/preview-link`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/preview-link
 */
export function useGetV1SurveysSurveyidPreviewLink<
  TData = GetV1SurveysSurveyidPreviewLink['response'],
  TQueryData = GetV1SurveysSurveyidPreviewLink['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidPreviewLinkQueryKey,
>(
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidPreviewLink['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidPreviewLink['response'],
        GetV1SurveysSurveyidPreviewLink['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidPreviewLink['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidPreviewLink['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SurveysSurveyidPreviewLinkQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidPreviewLinkQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyidPreviewLink['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidPreviewLinkSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/preview-link',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidPreviewLinkSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidPreviewLinkSuspenseQueryKey
>
export function getV1SurveysSurveyidPreviewLinkSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidPreviewLink['headerParams'],
  options: GetV1SurveysSurveyidPreviewLink['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidPreviewLinkSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidPreviewLink['data'],
        GetV1SurveysSurveyidPreviewLink['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/preview-link`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/preview-link
 */
export function useGetV1SurveysSurveyidPreviewLinkSuspense<
  TData = GetV1SurveysSurveyidPreviewLink['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidPreviewLinkSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidPreviewLinkPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidPreviewLink['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidPreviewLink['response'],
        GetV1SurveysSurveyidPreviewLink['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidPreviewLink['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysSurveyidPreviewLink['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidPreviewLinkSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidPreviewLinkSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidPreviewLink['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
