// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1IntegrationsProvideridIntegrationWizardMutationRequest,
  PostV1IntegrationsProvideridIntegrationWizardMutationResponse,
  PostV1IntegrationsProvideridIntegrationWizardPathParams,
  PostV1IntegrationsProvideridIntegrationWizardHeaderParams,
  PostV1IntegrationsProvideridIntegrationWizard401,
  PostV1IntegrationsProvideridIntegrationWizard403,
} from '../models/PostV1IntegrationsProvideridIntegrationWizard'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1IntegrationsProvideridIntegrationWizardClient = typeof client<
  PostV1IntegrationsProvideridIntegrationWizardMutationResponse,
  | PostV1IntegrationsProvideridIntegrationWizard401
  | PostV1IntegrationsProvideridIntegrationWizard403,
  PostV1IntegrationsProvideridIntegrationWizardMutationRequest
>
type PostV1IntegrationsProvideridIntegrationWizard = {
  data: PostV1IntegrationsProvideridIntegrationWizardMutationResponse
  error:
    | PostV1IntegrationsProvideridIntegrationWizard401
    | PostV1IntegrationsProvideridIntegrationWizard403
  request: PostV1IntegrationsProvideridIntegrationWizardMutationRequest
  pathParams: PostV1IntegrationsProvideridIntegrationWizardPathParams
  queryParams: never
  headerParams: PostV1IntegrationsProvideridIntegrationWizardHeaderParams
  response: Awaited<
    ReturnType<PostV1IntegrationsProvideridIntegrationWizardClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1IntegrationsProvideridIntegrationWizardClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1IntegrationsProvideridIntegrationWizardClient>
    >
  }
}
/**
 * @link /v1/integrations/:providerId/integration-wizard
 */
export function usePostV1IntegrationsProvideridIntegrationWizard(
  options: {
    mutation?: UseMutationOptions<
      PostV1IntegrationsProvideridIntegrationWizard['response'],
      PostV1IntegrationsProvideridIntegrationWizard['error'],
      {
        providerId: PostV1IntegrationsProvideridIntegrationWizardPathParams['providerId']
        headers?: PostV1IntegrationsProvideridIntegrationWizard['headerParams']
        data: PostV1IntegrationsProvideridIntegrationWizard['request']
      }
    >
    client?: PostV1IntegrationsProvideridIntegrationWizard['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ providerId, data, headers }) => {
      const res = await client<
        PostV1IntegrationsProvideridIntegrationWizard['data'],
        PostV1IntegrationsProvideridIntegrationWizard['error'],
        PostV1IntegrationsProvideridIntegrationWizard['request']
      >({
        method: 'post',
        url: `/v1/integrations/${providerId}/integration-wizard`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
