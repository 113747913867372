// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1IntegrationsQueryResponse,
  GetV1IntegrationsHeaderParams,
  GetV1Integrations401,
  GetV1Integrations403,
} from '../models/GetV1Integrations'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1IntegrationsClient = typeof client<
  GetV1IntegrationsQueryResponse,
  GetV1Integrations401 | GetV1Integrations403,
  never
>
type GetV1Integrations = {
  data: GetV1IntegrationsQueryResponse
  error: GetV1Integrations401 | GetV1Integrations403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1IntegrationsHeaderParams
  response: Awaited<ReturnType<GetV1IntegrationsClient>>
  client: {
    parameters: Partial<Parameters<GetV1IntegrationsClient>[0]>
    return: Awaited<ReturnType<GetV1IntegrationsClient>>
  }
}
export const getV1IntegrationsQueryKey = () =>
  [{ url: '/v1/integrations' }] as const
export type GetV1IntegrationsQueryKey = ReturnType<
  typeof getV1IntegrationsQueryKey
>
export function getV1IntegrationsQueryOptions(
  headers?: GetV1Integrations['headerParams'],
  options: GetV1Integrations['client']['parameters'] = {},
) {
  const queryKey = getV1IntegrationsQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1Integrations['data'],
        GetV1Integrations['error']
      >({
        method: 'get',
        url: `/v1/integrations`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations
 */
export function useGetV1Integrations<
  TData = GetV1Integrations['response'],
  TQueryData = GetV1Integrations['response'],
  TQueryKey extends QueryKey = GetV1IntegrationsQueryKey,
>(
  headers?: GetV1Integrations['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1Integrations['response'],
        GetV1Integrations['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1Integrations['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1Integrations['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1IntegrationsQueryKey()
  const query = useQuery({
    ...(getV1IntegrationsQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1Integrations['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1IntegrationsSuspenseQueryKey = () =>
  [{ url: '/v1/integrations' }] as const
export type GetV1IntegrationsSuspenseQueryKey = ReturnType<
  typeof getV1IntegrationsSuspenseQueryKey
>
export function getV1IntegrationsSuspenseQueryOptions(
  headers?: GetV1Integrations['headerParams'],
  options: GetV1Integrations['client']['parameters'] = {},
) {
  const queryKey = getV1IntegrationsSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1Integrations['data'],
        GetV1Integrations['error']
      >({
        method: 'get',
        url: `/v1/integrations`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations
 */
export function useGetV1IntegrationsSuspense<
  TData = GetV1Integrations['response'],
  TQueryKey extends QueryKey = GetV1IntegrationsSuspenseQueryKey,
>(
  headers?: GetV1Integrations['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1Integrations['response'],
        GetV1Integrations['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1Integrations['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1Integrations['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1IntegrationsSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1IntegrationsSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1Integrations['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
