// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1BillingsAccountidSubscribeMutationRequest,
  PostV1BillingsAccountidSubscribeMutationResponse,
  PostV1BillingsAccountidSubscribePathParams,
  PostV1BillingsAccountidSubscribe401,
  PostV1BillingsAccountidSubscribe403,
} from '../models/PostV1BillingsAccountidSubscribe'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1BillingsAccountidSubscribeClient = typeof client<
  PostV1BillingsAccountidSubscribeMutationResponse,
  PostV1BillingsAccountidSubscribe401 | PostV1BillingsAccountidSubscribe403,
  PostV1BillingsAccountidSubscribeMutationRequest
>
type PostV1BillingsAccountidSubscribe = {
  data: PostV1BillingsAccountidSubscribeMutationResponse
  error:
    | PostV1BillingsAccountidSubscribe401
    | PostV1BillingsAccountidSubscribe403
  request: PostV1BillingsAccountidSubscribeMutationRequest
  pathParams: PostV1BillingsAccountidSubscribePathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1BillingsAccountidSubscribeClient>>
  client: {
    parameters: Partial<Parameters<PostV1BillingsAccountidSubscribeClient>[0]>
    return: Awaited<ReturnType<PostV1BillingsAccountidSubscribeClient>>
  }
}
/**
 * @link /v1/billings/:accountId/subscribe
 */
export function usePostV1BillingsAccountidSubscribe(
  options: {
    mutation?: UseMutationOptions<
      PostV1BillingsAccountidSubscribe['response'],
      PostV1BillingsAccountidSubscribe['error'],
      {
        accountId: PostV1BillingsAccountidSubscribePathParams['accountId']
        data: PostV1BillingsAccountidSubscribe['request']
      }
    >
    client?: PostV1BillingsAccountidSubscribe['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PostV1BillingsAccountidSubscribe['data'],
        PostV1BillingsAccountidSubscribe['error'],
        PostV1BillingsAccountidSubscribe['request']
      >({
        method: 'post',
        url: `/v1/billings/${accountId}/subscribe`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
