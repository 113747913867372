// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidNotificationsQueryResponse,
  GetV1SurveysSurveyidNotificationsPathParams,
  GetV1SurveysSurveyidNotificationsHeaderParams,
  GetV1SurveysSurveyidNotifications401,
  GetV1SurveysSurveyidNotifications403,
} from '../models/GetV1SurveysSurveyidNotifications'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidNotificationsClient = typeof client<
  GetV1SurveysSurveyidNotificationsQueryResponse,
  GetV1SurveysSurveyidNotifications401 | GetV1SurveysSurveyidNotifications403,
  never
>
type GetV1SurveysSurveyidNotifications = {
  data: GetV1SurveysSurveyidNotificationsQueryResponse
  error:
    | GetV1SurveysSurveyidNotifications401
    | GetV1SurveysSurveyidNotifications403
  request: never
  pathParams: GetV1SurveysSurveyidNotificationsPathParams
  queryParams: never
  headerParams: GetV1SurveysSurveyidNotificationsHeaderParams
  response: Awaited<ReturnType<GetV1SurveysSurveyidNotificationsClient>>
  client: {
    parameters: Partial<Parameters<GetV1SurveysSurveyidNotificationsClient>[0]>
    return: Awaited<ReturnType<GetV1SurveysSurveyidNotificationsClient>>
  }
}
export const getV1SurveysSurveyidNotificationsQueryKey = (
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/notifications',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidNotificationsQueryKey = ReturnType<
  typeof getV1SurveysSurveyidNotificationsQueryKey
>
export function getV1SurveysSurveyidNotificationsQueryOptions(
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidNotifications['headerParams'],
  options: GetV1SurveysSurveyidNotifications['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidNotificationsQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidNotifications['data'],
        GetV1SurveysSurveyidNotifications['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/notifications`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/notifications
 */
export function useGetV1SurveysSurveyidNotifications<
  TData = GetV1SurveysSurveyidNotifications['response'],
  TQueryData = GetV1SurveysSurveyidNotifications['response'],
  TQueryKey extends QueryKey = GetV1SurveysSurveyidNotificationsQueryKey,
>(
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidNotifications['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidNotifications['response'],
        GetV1SurveysSurveyidNotifications['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidNotifications['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SurveysSurveyidNotifications['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidNotificationsQueryKey(surveyId)
  const query = useQuery({
    ...(getV1SurveysSurveyidNotificationsQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SurveysSurveyidNotifications['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidNotificationsSuspenseQueryKey = (
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/notifications',
      params: { surveyId: surveyId },
    },
  ] as const
export type GetV1SurveysSurveyidNotificationsSuspenseQueryKey = ReturnType<
  typeof getV1SurveysSurveyidNotificationsSuspenseQueryKey
>
export function getV1SurveysSurveyidNotificationsSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidNotifications['headerParams'],
  options: GetV1SurveysSurveyidNotifications['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidNotificationsSuspenseQueryKey(surveyId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidNotifications['data'],
        GetV1SurveysSurveyidNotifications['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/notifications`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/notifications
 */
export function useGetV1SurveysSurveyidNotificationsSuspense<
  TData = GetV1SurveysSurveyidNotifications['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidNotificationsSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidNotificationsPathParams['surveyId'],
  headers?: GetV1SurveysSurveyidNotifications['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidNotifications['response'],
        GetV1SurveysSurveyidNotifications['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidNotifications['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SurveysSurveyidNotifications['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidNotificationsSuspenseQueryKey(surveyId)
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidNotificationsSuspenseQueryOptions(
      surveyId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidNotifications['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
