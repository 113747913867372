// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionIntervalMutationRequest,
  PutV1SurveysSurveyidQuestionIntervalMutationResponse,
  PutV1SurveysSurveyidQuestionIntervalPathParams,
  PutV1SurveysSurveyidQuestionIntervalHeaderParams,
  PutV1SurveysSurveyidQuestionInterval401,
  PutV1SurveysSurveyidQuestionInterval403,
} from '../models/PutV1SurveysSurveyidQuestionInterval'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionIntervalClient = typeof client<
  PutV1SurveysSurveyidQuestionIntervalMutationResponse,
  | PutV1SurveysSurveyidQuestionInterval401
  | PutV1SurveysSurveyidQuestionInterval403,
  PutV1SurveysSurveyidQuestionIntervalMutationRequest
>
type PutV1SurveysSurveyidQuestionInterval = {
  data: PutV1SurveysSurveyidQuestionIntervalMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionInterval401
    | PutV1SurveysSurveyidQuestionInterval403
  request: PutV1SurveysSurveyidQuestionIntervalMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionIntervalPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionIntervalHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionIntervalClient>>
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionIntervalClient>[0]
    >
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionIntervalClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/interval
 */
export function usePutV1SurveysSurveyidQuestionInterval(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionInterval['response'],
      PutV1SurveysSurveyidQuestionInterval['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionIntervalPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionInterval['headerParams']
        data: PutV1SurveysSurveyidQuestionInterval['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionInterval['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionInterval['data'],
        PutV1SurveysSurveyidQuestionInterval['error'],
        PutV1SurveysSurveyidQuestionInterval['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/interval`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
