// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1IntegrationsProvideridSettingsQueryResponse,
  GetV1IntegrationsProvideridSettingsPathParams,
  GetV1IntegrationsProvideridSettingsHeaderParams,
  GetV1IntegrationsProvideridSettings401,
  GetV1IntegrationsProvideridSettings403,
} from '../models/GetV1IntegrationsProvideridSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1IntegrationsProvideridSettingsClient = typeof client<
  GetV1IntegrationsProvideridSettingsQueryResponse,
  | GetV1IntegrationsProvideridSettings401
  | GetV1IntegrationsProvideridSettings403,
  never
>
type GetV1IntegrationsProvideridSettings = {
  data: GetV1IntegrationsProvideridSettingsQueryResponse
  error:
    | GetV1IntegrationsProvideridSettings401
    | GetV1IntegrationsProvideridSettings403
  request: never
  pathParams: GetV1IntegrationsProvideridSettingsPathParams
  queryParams: never
  headerParams: GetV1IntegrationsProvideridSettingsHeaderParams
  response: Awaited<ReturnType<GetV1IntegrationsProvideridSettingsClient>>
  client: {
    parameters: Partial<
      Parameters<GetV1IntegrationsProvideridSettingsClient>[0]
    >
    return: Awaited<ReturnType<GetV1IntegrationsProvideridSettingsClient>>
  }
}
export const getV1IntegrationsProvideridSettingsQueryKey = (
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
) =>
  [
    {
      url: '/v1/integrations/:providerId/settings',
      params: { providerId: providerId },
    },
  ] as const
export type GetV1IntegrationsProvideridSettingsQueryKey = ReturnType<
  typeof getV1IntegrationsProvideridSettingsQueryKey
>
export function getV1IntegrationsProvideridSettingsQueryOptions(
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridSettings['headerParams'],
  options: GetV1IntegrationsProvideridSettings['client']['parameters'] = {},
) {
  const queryKey = getV1IntegrationsProvideridSettingsQueryKey(providerId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1IntegrationsProvideridSettings['data'],
        GetV1IntegrationsProvideridSettings['error']
      >({
        method: 'get',
        url: `/v1/integrations/${providerId}/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations/:providerId/settings
 */
export function useGetV1IntegrationsProvideridSettings<
  TData = GetV1IntegrationsProvideridSettings['response'],
  TQueryData = GetV1IntegrationsProvideridSettings['response'],
  TQueryKey extends QueryKey = GetV1IntegrationsProvideridSettingsQueryKey,
>(
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1IntegrationsProvideridSettings['response'],
        GetV1IntegrationsProvideridSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1IntegrationsProvideridSettings['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1IntegrationsProvideridSettings['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1IntegrationsProvideridSettingsQueryKey(providerId)
  const query = useQuery({
    ...(getV1IntegrationsProvideridSettingsQueryOptions(
      providerId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1IntegrationsProvideridSettings['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1IntegrationsProvideridSettingsSuspenseQueryKey = (
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
) =>
  [
    {
      url: '/v1/integrations/:providerId/settings',
      params: { providerId: providerId },
    },
  ] as const
export type GetV1IntegrationsProvideridSettingsSuspenseQueryKey = ReturnType<
  typeof getV1IntegrationsProvideridSettingsSuspenseQueryKey
>
export function getV1IntegrationsProvideridSettingsSuspenseQueryOptions(
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridSettings['headerParams'],
  options: GetV1IntegrationsProvideridSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1IntegrationsProvideridSettingsSuspenseQueryKey(providerId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1IntegrationsProvideridSettings['data'],
        GetV1IntegrationsProvideridSettings['error']
      >({
        method: 'get',
        url: `/v1/integrations/${providerId}/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/integrations/:providerId/settings
 */
export function useGetV1IntegrationsProvideridSettingsSuspense<
  TData = GetV1IntegrationsProvideridSettings['response'],
  TQueryKey extends
    QueryKey = GetV1IntegrationsProvideridSettingsSuspenseQueryKey,
>(
  providerId: GetV1IntegrationsProvideridSettingsPathParams['providerId'],
  headers?: GetV1IntegrationsProvideridSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1IntegrationsProvideridSettings['response'],
        GetV1IntegrationsProvideridSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1IntegrationsProvideridSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1IntegrationsProvideridSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1IntegrationsProvideridSettingsSuspenseQueryKey(providerId)
  const query = useSuspenseQuery({
    ...(getV1IntegrationsProvideridSettingsSuspenseQueryOptions(
      providerId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1IntegrationsProvideridSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
