// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryResponse,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsHeaderParams,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettings401,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettings403,
} from '../models/GetV1GenericAccountmetricsAccountmetricidDataentriesSettings'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsClient =
  typeof client<
    GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryResponse,
    | GetV1GenericAccountmetricsAccountmetricidDataentriesSettings401
    | GetV1GenericAccountmetricsAccountmetricidDataentriesSettings403,
    never
  >
type GetV1GenericAccountmetricsAccountmetricidDataentriesSettings = {
  data: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryResponse
  error:
    | GetV1GenericAccountmetricsAccountmetricidDataentriesSettings401
    | GetV1GenericAccountmetricsAccountmetricidDataentriesSettings403
  request: never
  pathParams: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams
  queryParams: never
  headerParams: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsHeaderParams
  response: Awaited<
    ReturnType<GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsClient>
    >
  }
}
export const getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/dataentries/settings',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/dataentries/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/dataentries/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidDataentriesSettings<
  TData = GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['response'],
  TQueryData = GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryKey(
      accountMetricId,
    )
  const query = useQuery({
    ...(getV1GenericAccountmetricsAccountmetricidDataentriesSettingsQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/dataentries/settings',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['data'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/dataentries/settings`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/dataentries/settings
 */
export function useGetV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspense<
  TData = GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesSettingsPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['response'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryKey(
      accountMetricId,
    )
  const query = useSuspenseQuery({
    ...(getV1GenericAccountmetricsAccountmetricidDataentriesSettingsSuspenseQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidDataentriesSettings['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
