// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SignUpMaoSlugQueryResponse,
  GetV1SignUpMaoSlugPathParams,
} from '../models/GetV1SignUpMaoSlug'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SignUpMaoSlugClient = typeof client<
  GetV1SignUpMaoSlugQueryResponse,
  Error,
  never
>
type GetV1SignUpMaoSlug = {
  data: GetV1SignUpMaoSlugQueryResponse
  error: Error
  request: never
  pathParams: GetV1SignUpMaoSlugPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1SignUpMaoSlugClient>>
  client: {
    parameters: Partial<Parameters<GetV1SignUpMaoSlugClient>[0]>
    return: Awaited<ReturnType<GetV1SignUpMaoSlugClient>>
  }
}
export const getV1SignUpMaoSlugQueryKey = (
  slug: GetV1SignUpMaoSlugPathParams['slug'],
) => [{ url: '/v1/sign-up/mao/:slug', params: { slug: slug } }] as const
export type GetV1SignUpMaoSlugQueryKey = ReturnType<
  typeof getV1SignUpMaoSlugQueryKey
>
export function getV1SignUpMaoSlugQueryOptions(
  slug: GetV1SignUpMaoSlugPathParams['slug'],
  options: GetV1SignUpMaoSlug['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpMaoSlugQueryKey(slug)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpMaoSlug['data'],
        GetV1SignUpMaoSlug['error']
      >({
        method: 'get',
        url: `/v1/sign-up/mao/${slug}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/mao/:slug
 */
export function useGetV1SignUpMaoSlug<
  TData = GetV1SignUpMaoSlug['response'],
  TQueryData = GetV1SignUpMaoSlug['response'],
  TQueryKey extends QueryKey = GetV1SignUpMaoSlugQueryKey,
>(
  slug: GetV1SignUpMaoSlugPathParams['slug'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SignUpMaoSlug['response'],
        GetV1SignUpMaoSlug['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SignUpMaoSlug['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SignUpMaoSlug['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1SignUpMaoSlugQueryKey(slug)
  const query = useQuery({
    ...(getV1SignUpMaoSlugQueryOptions(
      slug,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SignUpMaoSlug['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SignUpMaoSlugSuspenseQueryKey = (
  slug: GetV1SignUpMaoSlugPathParams['slug'],
) => [{ url: '/v1/sign-up/mao/:slug', params: { slug: slug } }] as const
export type GetV1SignUpMaoSlugSuspenseQueryKey = ReturnType<
  typeof getV1SignUpMaoSlugSuspenseQueryKey
>
export function getV1SignUpMaoSlugSuspenseQueryOptions(
  slug: GetV1SignUpMaoSlugPathParams['slug'],
  options: GetV1SignUpMaoSlug['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpMaoSlugSuspenseQueryKey(slug)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpMaoSlug['data'],
        GetV1SignUpMaoSlug['error']
      >({
        method: 'get',
        url: `/v1/sign-up/mao/${slug}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/mao/:slug
 */
export function useGetV1SignUpMaoSlugSuspense<
  TData = GetV1SignUpMaoSlug['response'],
  TQueryKey extends QueryKey = GetV1SignUpMaoSlugSuspenseQueryKey,
>(
  slug: GetV1SignUpMaoSlugPathParams['slug'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SignUpMaoSlug['response'],
        GetV1SignUpMaoSlug['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SignUpMaoSlug['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SignUpMaoSlug['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SignUpMaoSlugSuspenseQueryKey(slug)
  const query = useSuspenseQuery({
    ...(getV1SignUpMaoSlugSuspenseQueryOptions(
      slug,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1SignUpMaoSlug['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
