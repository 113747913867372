// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1IntegrationsProvideridMutationResponse,
  DeleteV1IntegrationsProvideridPathParams,
  DeleteV1IntegrationsProvideridHeaderParams,
  DeleteV1IntegrationsProviderid401,
  DeleteV1IntegrationsProviderid403,
} from '../models/DeleteV1IntegrationsProviderid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1IntegrationsProvideridClient = typeof client<
  DeleteV1IntegrationsProvideridMutationResponse,
  DeleteV1IntegrationsProviderid401 | DeleteV1IntegrationsProviderid403,
  never
>
type DeleteV1IntegrationsProviderid = {
  data: DeleteV1IntegrationsProvideridMutationResponse
  error: DeleteV1IntegrationsProviderid401 | DeleteV1IntegrationsProviderid403
  request: never
  pathParams: DeleteV1IntegrationsProvideridPathParams
  queryParams: never
  headerParams: DeleteV1IntegrationsProvideridHeaderParams
  response: Awaited<ReturnType<DeleteV1IntegrationsProvideridClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1IntegrationsProvideridClient>[0]>
    return: Awaited<ReturnType<DeleteV1IntegrationsProvideridClient>>
  }
}
/**
 * @link /v1/integrations/:providerId
 */
export function useDeleteV1IntegrationsProviderid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1IntegrationsProviderid['response'],
      DeleteV1IntegrationsProviderid['error'],
      {
        providerId: DeleteV1IntegrationsProvideridPathParams['providerId']
        headers?: DeleteV1IntegrationsProviderid['headerParams']
      }
    >
    client?: DeleteV1IntegrationsProviderid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ providerId, headers }) => {
      const res = await client<
        DeleteV1IntegrationsProviderid['data'],
        DeleteV1IntegrationsProviderid['error'],
        DeleteV1IntegrationsProviderid['request']
      >({
        method: 'delete',
        url: `/v1/integrations/${providerId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
