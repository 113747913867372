// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ResultsExportPptServiceAreaMutationRequest,
  PostV1ResultsExportPptServiceAreaMutationResponse,
  PostV1ResultsExportPptServiceAreaHeaderParams,
  PostV1ResultsExportPptServiceArea401,
  PostV1ResultsExportPptServiceArea403,
} from '../models/PostV1ResultsExportPptServiceArea'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ResultsExportPptServiceAreaClient = typeof client<
  PostV1ResultsExportPptServiceAreaMutationResponse,
  PostV1ResultsExportPptServiceArea401 | PostV1ResultsExportPptServiceArea403,
  PostV1ResultsExportPptServiceAreaMutationRequest
>
type PostV1ResultsExportPptServiceArea = {
  data: PostV1ResultsExportPptServiceAreaMutationResponse
  error:
    | PostV1ResultsExportPptServiceArea401
    | PostV1ResultsExportPptServiceArea403
  request: PostV1ResultsExportPptServiceAreaMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsExportPptServiceAreaHeaderParams
  response: Awaited<ReturnType<PostV1ResultsExportPptServiceAreaClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsExportPptServiceAreaClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsExportPptServiceAreaClient>>
  }
}
/**
 * @link /v1/results/export/PPT/service-area
 */
export function usePostV1ResultsExportPptServiceArea(
  options: {
    mutation?: UseMutationOptions<
      PostV1ResultsExportPptServiceArea['response'],
      PostV1ResultsExportPptServiceArea['error'],
      {
        headers?: PostV1ResultsExportPptServiceArea['headerParams']
        data: PostV1ResultsExportPptServiceArea['request']
      }
    >
    client?: PostV1ResultsExportPptServiceArea['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1ResultsExportPptServiceArea['data'],
        PostV1ResultsExportPptServiceArea['error'],
        PostV1ResultsExportPptServiceArea['request']
      >({
        method: 'post',
        url: `/v1/results/export/PPT/service-area`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
