// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type { GetV1TestExceptionQueryResponse } from '../models/GetV1TestException'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TestExceptionClient = typeof client<
  GetV1TestExceptionQueryResponse,
  Error,
  never
>
type GetV1TestException = {
  data: GetV1TestExceptionQueryResponse
  error: Error
  request: never
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1TestExceptionClient>>
  client: {
    parameters: Partial<Parameters<GetV1TestExceptionClient>[0]>
    return: Awaited<ReturnType<GetV1TestExceptionClient>>
  }
}
export const getV1TestExceptionQueryKey = () =>
  [{ url: '/v1/test/exception' }] as const
export type GetV1TestExceptionQueryKey = ReturnType<
  typeof getV1TestExceptionQueryKey
>
export function getV1TestExceptionQueryOptions(
  options: GetV1TestException['client']['parameters'] = {},
) {
  const queryKey = getV1TestExceptionQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TestException['data'],
        GetV1TestException['error']
      >({
        method: 'get',
        url: `/v1/test/exception`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/exception
 */
export function useGetV1TestException<
  TData = GetV1TestException['response'],
  TQueryData = GetV1TestException['response'],
  TQueryKey extends QueryKey = GetV1TestExceptionQueryKey,
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TestException['response'],
        GetV1TestException['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TestException['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TestException['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1TestExceptionQueryKey()
  const query = useQuery({
    ...(getV1TestExceptionQueryOptions(
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1TestException['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TestExceptionSuspenseQueryKey = () =>
  [{ url: '/v1/test/exception' }] as const
export type GetV1TestExceptionSuspenseQueryKey = ReturnType<
  typeof getV1TestExceptionSuspenseQueryKey
>
export function getV1TestExceptionSuspenseQueryOptions(
  options: GetV1TestException['client']['parameters'] = {},
) {
  const queryKey = getV1TestExceptionSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TestException['data'],
        GetV1TestException['error']
      >({
        method: 'get',
        url: `/v1/test/exception`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/test/exception
 */
export function useGetV1TestExceptionSuspense<
  TData = GetV1TestException['response'],
  TQueryKey extends QueryKey = GetV1TestExceptionSuspenseQueryKey,
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TestException['response'],
        GetV1TestException['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TestException['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1TestException['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TestExceptionSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TestExceptionSuspenseQueryOptions(
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1TestException['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
