// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1AccountadminAccountidCreateMutationRequest,
  PostV1AccountadminAccountidCreateMutationResponse,
  PostV1AccountadminAccountidCreatePathParams,
  PostV1AccountadminAccountidCreate401,
  PostV1AccountadminAccountidCreate403,
} from '../models/PostV1AccountadminAccountidCreate'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1AccountadminAccountidCreateClient = typeof client<
  PostV1AccountadminAccountidCreateMutationResponse,
  PostV1AccountadminAccountidCreate401 | PostV1AccountadminAccountidCreate403,
  PostV1AccountadminAccountidCreateMutationRequest
>
type PostV1AccountadminAccountidCreate = {
  data: PostV1AccountadminAccountidCreateMutationResponse
  error:
    | PostV1AccountadminAccountidCreate401
    | PostV1AccountadminAccountidCreate403
  request: PostV1AccountadminAccountidCreateMutationRequest
  pathParams: PostV1AccountadminAccountidCreatePathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1AccountadminAccountidCreateClient>>
  client: {
    parameters: Partial<Parameters<PostV1AccountadminAccountidCreateClient>[0]>
    return: Awaited<ReturnType<PostV1AccountadminAccountidCreateClient>>
  }
}
/**
 * @link /v1/accountadmin/:accountId/create
 */
export function usePostV1AccountadminAccountidCreate(
  options: {
    mutation?: UseMutationOptions<
      PostV1AccountadminAccountidCreate['response'],
      PostV1AccountadminAccountidCreate['error'],
      {
        accountId: PostV1AccountadminAccountidCreatePathParams['accountId']
        data: PostV1AccountadminAccountidCreate['request']
      }
    >
    client?: PostV1AccountadminAccountidCreate['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PostV1AccountadminAccountidCreate['data'],
        PostV1AccountadminAccountidCreate['error'],
        PostV1AccountadminAccountidCreate['request']
      >({
        method: 'post',
        url: `/v1/accountadmin/${accountId}/create`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
