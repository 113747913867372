// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1AccountPictureLogoMutationResponse,
  DeleteV1AccountPictureLogoHeaderParams,
  DeleteV1AccountPictureLogo401,
  DeleteV1AccountPictureLogo403,
} from '../models/DeleteV1AccountPictureLogo'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1AccountPictureLogoClient = typeof client<
  DeleteV1AccountPictureLogoMutationResponse,
  DeleteV1AccountPictureLogo401 | DeleteV1AccountPictureLogo403,
  never
>
type DeleteV1AccountPictureLogo = {
  data: DeleteV1AccountPictureLogoMutationResponse
  error: DeleteV1AccountPictureLogo401 | DeleteV1AccountPictureLogo403
  request: never
  pathParams: never
  queryParams: never
  headerParams: DeleteV1AccountPictureLogoHeaderParams
  response: Awaited<ReturnType<DeleteV1AccountPictureLogoClient>>
  client: {
    parameters: Partial<Parameters<DeleteV1AccountPictureLogoClient>[0]>
    return: Awaited<ReturnType<DeleteV1AccountPictureLogoClient>>
  }
}
/**
 * @link /v1/account-picture/logo
 */
export function useDeleteV1AccountPictureLogo(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1AccountPictureLogo['response'],
      DeleteV1AccountPictureLogo['error'],
      {
        headers?: DeleteV1AccountPictureLogo['headerParams']
      }
    >
    client?: DeleteV1AccountPictureLogo['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ headers }) => {
      const res = await client<
        DeleteV1AccountPictureLogo['data'],
        DeleteV1AccountPictureLogo['error'],
        DeleteV1AccountPictureLogo['request']
      >({
        method: 'delete',
        url: `/v1/account-picture/logo`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
