// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1ContextsAccountQueryResponse,
  GetV1ContextsAccountHeaderParams,
  GetV1ContextsAccount401,
  GetV1ContextsAccount403,
} from '../models/GetV1ContextsAccount'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1ContextsAccountClient = typeof client<
  GetV1ContextsAccountQueryResponse,
  GetV1ContextsAccount401 | GetV1ContextsAccount403,
  never
>
type GetV1ContextsAccount = {
  data: GetV1ContextsAccountQueryResponse
  error: GetV1ContextsAccount401 | GetV1ContextsAccount403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1ContextsAccountHeaderParams
  response: Awaited<ReturnType<GetV1ContextsAccountClient>>
  client: {
    parameters: Partial<Parameters<GetV1ContextsAccountClient>[0]>
    return: Awaited<ReturnType<GetV1ContextsAccountClient>>
  }
}
export const getV1ContextsAccountQueryKey = () =>
  [{ url: '/v1/contexts/account' }] as const
export type GetV1ContextsAccountQueryKey = ReturnType<
  typeof getV1ContextsAccountQueryKey
>
export function getV1ContextsAccountQueryOptions(
  headers?: GetV1ContextsAccount['headerParams'],
  options: GetV1ContextsAccount['client']['parameters'] = {},
) {
  const queryKey = getV1ContextsAccountQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ContextsAccount['data'],
        GetV1ContextsAccount['error']
      >({
        method: 'get',
        url: `/v1/contexts/account`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/contexts/account
 */
export function useGetV1ContextsAccount<
  TData = GetV1ContextsAccount['response'],
  TQueryData = GetV1ContextsAccount['response'],
  TQueryKey extends QueryKey = GetV1ContextsAccountQueryKey,
>(
  headers?: GetV1ContextsAccount['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1ContextsAccount['response'],
        GetV1ContextsAccount['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1ContextsAccount['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1ContextsAccount['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1ContextsAccountQueryKey()
  const query = useQuery({
    ...(getV1ContextsAccountQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1ContextsAccount['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1ContextsAccountSuspenseQueryKey = () =>
  [{ url: '/v1/contexts/account' }] as const
export type GetV1ContextsAccountSuspenseQueryKey = ReturnType<
  typeof getV1ContextsAccountSuspenseQueryKey
>
export function getV1ContextsAccountSuspenseQueryOptions(
  headers?: GetV1ContextsAccount['headerParams'],
  options: GetV1ContextsAccount['client']['parameters'] = {},
) {
  const queryKey = getV1ContextsAccountSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1ContextsAccount['data'],
        GetV1ContextsAccount['error']
      >({
        method: 'get',
        url: `/v1/contexts/account`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/contexts/account
 */
export function useGetV1ContextsAccountSuspense<
  TData = GetV1ContextsAccount['response'],
  TQueryKey extends QueryKey = GetV1ContextsAccountSuspenseQueryKey,
>(
  headers?: GetV1ContextsAccount['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1ContextsAccount['response'],
        GetV1ContextsAccount['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1ContextsAccount['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1ContextsAccount['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1ContextsAccountSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1ContextsAccountSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1ContextsAccount['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
