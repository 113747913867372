// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  DeleteV1SurveysSurveyidQuestionQuestionidMutationResponse,
  DeleteV1SurveysSurveyidQuestionQuestionidPathParams,
  DeleteV1SurveysSurveyidQuestionQuestionidHeaderParams,
  DeleteV1SurveysSurveyidQuestionQuestionid401,
  DeleteV1SurveysSurveyidQuestionQuestionid403,
} from '../models/DeleteV1SurveysSurveyidQuestionQuestionid'
import type { UseMutationOptions } from '@tanstack/react-query'

type DeleteV1SurveysSurveyidQuestionQuestionidClient = typeof client<
  DeleteV1SurveysSurveyidQuestionQuestionidMutationResponse,
  | DeleteV1SurveysSurveyidQuestionQuestionid401
  | DeleteV1SurveysSurveyidQuestionQuestionid403,
  never
>
type DeleteV1SurveysSurveyidQuestionQuestionid = {
  data: DeleteV1SurveysSurveyidQuestionQuestionidMutationResponse
  error:
    | DeleteV1SurveysSurveyidQuestionQuestionid401
    | DeleteV1SurveysSurveyidQuestionQuestionid403
  request: never
  pathParams: DeleteV1SurveysSurveyidQuestionQuestionidPathParams
  queryParams: never
  headerParams: DeleteV1SurveysSurveyidQuestionQuestionidHeaderParams
  response: Awaited<ReturnType<DeleteV1SurveysSurveyidQuestionQuestionidClient>>
  client: {
    parameters: Partial<
      Parameters<DeleteV1SurveysSurveyidQuestionQuestionidClient>[0]
    >
    return: Awaited<ReturnType<DeleteV1SurveysSurveyidQuestionQuestionidClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/:questionId
 */
export function useDeleteV1SurveysSurveyidQuestionQuestionid(
  options: {
    mutation?: UseMutationOptions<
      DeleteV1SurveysSurveyidQuestionQuestionid['response'],
      DeleteV1SurveysSurveyidQuestionQuestionid['error'],
      {
        surveyId: DeleteV1SurveysSurveyidQuestionQuestionidPathParams['surveyId']
        questionId: DeleteV1SurveysSurveyidQuestionQuestionidPathParams['questionId']
        headers?: DeleteV1SurveysSurveyidQuestionQuestionid['headerParams']
      }
    >
    client?: DeleteV1SurveysSurveyidQuestionQuestionid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, questionId, headers }) => {
      const res = await client<
        DeleteV1SurveysSurveyidQuestionQuestionid['data'],
        DeleteV1SurveysSurveyidQuestionQuestionid['error'],
        DeleteV1SurveysSurveyidQuestionQuestionid['request']
      >({
        method: 'delete',
        url: `/v1/surveys/${surveyId}/question/${questionId}`,
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
