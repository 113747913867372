// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1AiChatHistoryQueryResponse,
  GetV1AiChatHistoryQueryParams,
  GetV1AiChatHistoryHeaderParams,
  GetV1AiChatHistory400,
  GetV1AiChatHistory401,
  GetV1AiChatHistory403,
  GetV1AiChatHistory500,
} from '../models/GetV1AiChatHistory'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1AiChatHistoryClient = typeof client<
  GetV1AiChatHistoryQueryResponse,
  | GetV1AiChatHistory400
  | GetV1AiChatHistory401
  | GetV1AiChatHistory403
  | GetV1AiChatHistory500,
  never
>
type GetV1AiChatHistory = {
  data: GetV1AiChatHistoryQueryResponse
  error:
    | GetV1AiChatHistory400
    | GetV1AiChatHistory401
    | GetV1AiChatHistory403
    | GetV1AiChatHistory500
  request: never
  pathParams: never
  queryParams: GetV1AiChatHistoryQueryParams
  headerParams: GetV1AiChatHistoryHeaderParams
  response: Awaited<ReturnType<GetV1AiChatHistoryClient>>
  client: {
    parameters: Partial<Parameters<GetV1AiChatHistoryClient>[0]>
    return: Awaited<ReturnType<GetV1AiChatHistoryClient>>
  }
}
export const getV1AiChatHistoryQueryKey = (
  params?: GetV1AiChatHistory['queryParams'],
) => [{ url: '/v1/ai/chat-history' }, ...(params ? [params] : [])] as const
export type GetV1AiChatHistoryQueryKey = ReturnType<
  typeof getV1AiChatHistoryQueryKey
>
export function getV1AiChatHistoryQueryOptions(
  params?: GetV1AiChatHistory['queryParams'],
  headers?: GetV1AiChatHistory['headerParams'],
  options: GetV1AiChatHistory['client']['parameters'] = {},
) {
  const queryKey = getV1AiChatHistoryQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AiChatHistory['data'],
        GetV1AiChatHistory['error']
      >({
        method: 'get',
        url: `/v1/ai/chat-history`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/ai/chat-history
 */
export function useGetV1AiChatHistory<
  TData = GetV1AiChatHistory['response'],
  TQueryData = GetV1AiChatHistory['response'],
  TQueryKey extends QueryKey = GetV1AiChatHistoryQueryKey,
>(
  params?: GetV1AiChatHistory['queryParams'],
  headers?: GetV1AiChatHistory['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1AiChatHistory['response'],
        GetV1AiChatHistory['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1AiChatHistory['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1AiChatHistory['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1AiChatHistoryQueryKey(params)
  const query = useQuery({
    ...(getV1AiChatHistoryQueryOptions(
      params,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1AiChatHistory['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1AiChatHistorySuspenseQueryKey = (
  params?: GetV1AiChatHistory['queryParams'],
) => [{ url: '/v1/ai/chat-history' }, ...(params ? [params] : [])] as const
export type GetV1AiChatHistorySuspenseQueryKey = ReturnType<
  typeof getV1AiChatHistorySuspenseQueryKey
>
export function getV1AiChatHistorySuspenseQueryOptions(
  params?: GetV1AiChatHistory['queryParams'],
  headers?: GetV1AiChatHistory['headerParams'],
  options: GetV1AiChatHistory['client']['parameters'] = {},
) {
  const queryKey = getV1AiChatHistorySuspenseQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AiChatHistory['data'],
        GetV1AiChatHistory['error']
      >({
        method: 'get',
        url: `/v1/ai/chat-history`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/ai/chat-history
 */
export function useGetV1AiChatHistorySuspense<
  TData = GetV1AiChatHistory['response'],
  TQueryKey extends QueryKey = GetV1AiChatHistorySuspenseQueryKey,
>(
  params?: GetV1AiChatHistory['queryParams'],
  headers?: GetV1AiChatHistory['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1AiChatHistory['response'],
        GetV1AiChatHistory['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1AiChatHistory['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1AiChatHistory['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1AiChatHistorySuspenseQueryKey(params)
  const query = useSuspenseQuery({
    ...(getV1AiChatHistorySuspenseQueryOptions(
      params,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1AiChatHistory['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
