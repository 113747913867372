// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1GenericAccountmetricsAccountmetricidDataentriesListQueryResponse,
  GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams,
  GetV1GenericAccountmetricsAccountmetricidDataentriesListHeaderParams,
  GetV1GenericAccountmetricsAccountmetricidDataentriesList401,
  GetV1GenericAccountmetricsAccountmetricidDataentriesList403,
} from '../models/GetV1GenericAccountmetricsAccountmetricidDataentriesList'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1GenericAccountmetricsAccountmetricidDataentriesListClient =
  typeof client<
    GetV1GenericAccountmetricsAccountmetricidDataentriesListQueryResponse,
    | GetV1GenericAccountmetricsAccountmetricidDataentriesList401
    | GetV1GenericAccountmetricsAccountmetricidDataentriesList403,
    never
  >
type GetV1GenericAccountmetricsAccountmetricidDataentriesList = {
  data: GetV1GenericAccountmetricsAccountmetricidDataentriesListQueryResponse
  error:
    | GetV1GenericAccountmetricsAccountmetricidDataentriesList401
    | GetV1GenericAccountmetricsAccountmetricidDataentriesList403
  request: never
  pathParams: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams
  queryParams: never
  headerParams: GetV1GenericAccountmetricsAccountmetricidDataentriesListHeaderParams
  response: Awaited<
    ReturnType<GetV1GenericAccountmetricsAccountmetricidDataentriesListClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1GenericAccountmetricsAccountmetricidDataentriesListClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1GenericAccountmetricsAccountmetricidDataentriesListClient>
    >
  }
}
export const getV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/dataentries/list',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidDataentriesListQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidDataentriesList['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['data'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/dataentries/list`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/dataentries/list
 */
export function useGetV1GenericAccountmetricsAccountmetricidDataentriesList<
  TData = GetV1GenericAccountmetricsAccountmetricidDataentriesList['response'],
  TQueryData = GetV1GenericAccountmetricsAccountmetricidDataentriesList['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['response'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidDataentriesListQueryKey(
      accountMetricId,
    )
  const query = useQuery({
    ...(getV1GenericAccountmetricsAccountmetricidDataentriesListQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey =
  (
    accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  ) =>
    [
      {
        url: '/v1/generic/accountmetrics/:accountMetricId/dataentries/list',
        params: { accountMetricId: accountMetricId },
      },
    ] as const
export type GetV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey =
  ReturnType<
    typeof getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey
  >
export function getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryOptions(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['headerParams'],
  options: GetV1GenericAccountmetricsAccountmetricidDataentriesList['client']['parameters'] = {},
) {
  const queryKey =
    getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey(
      accountMetricId,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['data'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
      >({
        method: 'get',
        url: `/v1/generic/accountmetrics/${accountMetricId}/dataentries/list`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/:accountMetricId/dataentries/list
 */
export function useGetV1GenericAccountmetricsAccountmetricidDataentriesListSuspense<
  TData = GetV1GenericAccountmetricsAccountmetricidDataentriesList['response'],
  TQueryKey extends
    QueryKey = GetV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey,
>(
  accountMetricId: GetV1GenericAccountmetricsAccountmetricidDataentriesListPathParams['accountMetricId'],
  headers?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['response'],
        GetV1GenericAccountmetricsAccountmetricidDataentriesList['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1GenericAccountmetricsAccountmetricidDataentriesList['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryKey(
      accountMetricId,
    )
  const query = useSuspenseQuery({
    ...(getV1GenericAccountmetricsAccountmetricidDataentriesListSuspenseQueryOptions(
      accountMetricId,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1GenericAccountmetricsAccountmetricidDataentriesList['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
