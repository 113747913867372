// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsDevelopmentMutationRequest,
  PostV1ResultsDevelopmentMutationResponse,
  PostV1ResultsDevelopmentHeaderParams,
  PostV1ResultsDevelopment401,
  PostV1ResultsDevelopment403,
} from '../models/PostV1ResultsDevelopment'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsDevelopmentClient = typeof client<
  PostV1ResultsDevelopmentMutationResponse,
  PostV1ResultsDevelopment401 | PostV1ResultsDevelopment403,
  PostV1ResultsDevelopmentMutationRequest
>
type PostV1ResultsDevelopment = {
  data: PostV1ResultsDevelopmentMutationResponse
  error: PostV1ResultsDevelopment401 | PostV1ResultsDevelopment403
  request: PostV1ResultsDevelopmentMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsDevelopmentHeaderParams
  response: Awaited<ReturnType<PostV1ResultsDevelopmentClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsDevelopmentClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsDevelopmentClient>>
  }
}
export const postV1ResultsDevelopmentQueryKey = (
  data: PostV1ResultsDevelopment['request'],
) => [{ url: '/v1/results/development' }, ...(data ? [data] : [])] as const
export type PostV1ResultsDevelopmentQueryKey = ReturnType<
  typeof postV1ResultsDevelopmentQueryKey
>
export function postV1ResultsDevelopmentQueryOptions(
  data: PostV1ResultsDevelopment['request'],
  headers?: PostV1ResultsDevelopment['headerParams'],
  options: PostV1ResultsDevelopment['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDevelopmentQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDevelopment['data'],
        PostV1ResultsDevelopment['error']
      >({
        method: 'post',
        url: `/v1/results/development`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/development
 */
export function usePostV1ResultsDevelopment<
  TData = PostV1ResultsDevelopment['response'],
  TQueryData = PostV1ResultsDevelopment['response'],
  TQueryKey extends QueryKey = PostV1ResultsDevelopmentQueryKey,
>(
  data: PostV1ResultsDevelopment['request'],
  headers?: PostV1ResultsDevelopment['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsDevelopment['response'],
        PostV1ResultsDevelopment['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDevelopment['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsDevelopment['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDevelopmentQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsDevelopmentQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsDevelopment['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsDevelopmentSuspenseQueryKey = (
  data: PostV1ResultsDevelopment['request'],
) => [{ url: '/v1/results/development' }, ...(data ? [data] : [])] as const
export type PostV1ResultsDevelopmentSuspenseQueryKey = ReturnType<
  typeof postV1ResultsDevelopmentSuspenseQueryKey
>
export function postV1ResultsDevelopmentSuspenseQueryOptions(
  data: PostV1ResultsDevelopment['request'],
  headers?: PostV1ResultsDevelopment['headerParams'],
  options: PostV1ResultsDevelopment['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsDevelopmentSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsDevelopment['data'],
        PostV1ResultsDevelopment['error']
      >({
        method: 'post',
        url: `/v1/results/development`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/development
 */
export function usePostV1ResultsDevelopmentSuspense<
  TData = PostV1ResultsDevelopment['response'],
  TQueryKey extends QueryKey = PostV1ResultsDevelopmentSuspenseQueryKey,
>(
  data: PostV1ResultsDevelopment['request'],
  headers?: PostV1ResultsDevelopment['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsDevelopment['response'],
        PostV1ResultsDevelopment['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsDevelopment['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsDevelopment['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsDevelopmentSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsDevelopmentSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1ResultsDevelopment['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
