// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryResponse,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryParams,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRosterHeaderParams,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRoster401,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRoster403,
} from '../models/GetV1SurveysSurveyidGolfGeniusEventsEventidRoster'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SurveysSurveyidGolfGeniusEventsEventidRosterClient = typeof client<
  GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryResponse,
  | GetV1SurveysSurveyidGolfGeniusEventsEventidRoster401
  | GetV1SurveysSurveyidGolfGeniusEventsEventidRoster403,
  never
>
type GetV1SurveysSurveyidGolfGeniusEventsEventidRoster = {
  data: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryResponse
  error:
    | GetV1SurveysSurveyidGolfGeniusEventsEventidRoster401
    | GetV1SurveysSurveyidGolfGeniusEventsEventidRoster403
  request: never
  pathParams: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams
  queryParams: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryParams
  headerParams: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterHeaderParams
  response: Awaited<
    ReturnType<GetV1SurveysSurveyidGolfGeniusEventsEventidRosterClient>
  >
  client: {
    parameters: Partial<
      Parameters<GetV1SurveysSurveyidGolfGeniusEventsEventidRosterClient>[0]
    >
    return: Awaited<
      ReturnType<GetV1SurveysSurveyidGolfGeniusEventsEventidRosterClient>
    >
  }
}
export const getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey = (
  surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
  eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
  params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
) =>
  [
    {
      url: '/v1/surveys/:surveyId/golf-genius/events/:eventId/roster',
      params: { surveyId: surveyId, eventId: eventId },
    },
    ...(params ? [params] : []),
  ] as const
export type GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey =
  ReturnType<typeof getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey>
export function getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryOptions(
  surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
  eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
  params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
  headers?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['headerParams'],
  options: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['client']['parameters'] = {},
) {
  const queryKey = getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey(
    surveyId,
    eventId,
    params,
  )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['data'],
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/golf-genius/events/${eventId}/roster`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/golf-genius/events/:eventId/roster
 */
export function useGetV1SurveysSurveyidGolfGeniusEventsEventidRoster<
  TData = GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['response'],
  TQueryData = GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey,
>(
  surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
  eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
  params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
  headers?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['response'],
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryKey(
      surveyId,
      eventId,
      params,
    )
  const query = useQuery({
    ...(getV1SurveysSurveyidGolfGeniusEventsEventidRosterQueryOptions(
      surveyId,
      eventId,
      params,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey =
  (
    surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
    eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
    params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
  ) =>
    [
      {
        url: '/v1/surveys/:surveyId/golf-genius/events/:eventId/roster',
        params: { surveyId: surveyId, eventId: eventId },
      },
      ...(params ? [params] : []),
    ] as const
export type GetV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey =
  ReturnType<
    typeof getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey
  >
export function getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryOptions(
  surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
  eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
  params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
  headers?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['headerParams'],
  options: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['client']['parameters'] = {},
) {
  const queryKey =
    getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey(
      surveyId,
      eventId,
      params,
    )
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['data'],
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
      >({
        method: 'get',
        url: `/v1/surveys/${surveyId}/golf-genius/events/${eventId}/roster`,
        params,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/surveys/:surveyId/golf-genius/events/:eventId/roster
 */
export function useGetV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspense<
  TData = GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['response'],
  TQueryKey extends
    QueryKey = GetV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey,
>(
  surveyId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['surveyId'],
  eventId: GetV1SurveysSurveyidGolfGeniusEventsEventidRosterPathParams['eventId'],
  params?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['queryParams'],
  headers?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['response'],
        GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryKey(
      surveyId,
      eventId,
      params,
    )
  const query = useSuspenseQuery({
    ...(getV1SurveysSurveyidGolfGeniusEventsEventidRosterSuspenseQueryOptions(
      surveyId,
      eventId,
      params,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1SurveysSurveyidGolfGeniusEventsEventidRoster['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
