// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1BiBookingsResultsMutationRequest,
  PostV1BiBookingsResultsMutationResponse,
  PostV1BiBookingsResultsHeaderParams,
  PostV1BiBookingsResults401,
  PostV1BiBookingsResults403,
} from '../models/PostV1BiBookingsResults'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1BiBookingsResultsClient = typeof client<
  PostV1BiBookingsResultsMutationResponse,
  PostV1BiBookingsResults401 | PostV1BiBookingsResults403,
  PostV1BiBookingsResultsMutationRequest
>
type PostV1BiBookingsResults = {
  data: PostV1BiBookingsResultsMutationResponse
  error: PostV1BiBookingsResults401 | PostV1BiBookingsResults403
  request: PostV1BiBookingsResultsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1BiBookingsResultsHeaderParams
  response: Awaited<ReturnType<PostV1BiBookingsResultsClient>>
  client: {
    parameters: Partial<Parameters<PostV1BiBookingsResultsClient>[0]>
    return: Awaited<ReturnType<PostV1BiBookingsResultsClient>>
  }
}
export const postV1BiBookingsResultsQueryKey = (
  data: PostV1BiBookingsResults['request'],
) => [{ url: '/v1/bi/bookings/results' }, ...(data ? [data] : [])] as const
export type PostV1BiBookingsResultsQueryKey = ReturnType<
  typeof postV1BiBookingsResultsQueryKey
>
export function postV1BiBookingsResultsQueryOptions(
  data: PostV1BiBookingsResults['request'],
  headers?: PostV1BiBookingsResults['headerParams'],
  options: PostV1BiBookingsResults['client']['parameters'] = {},
) {
  const queryKey = postV1BiBookingsResultsQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiBookingsResults['data'],
        PostV1BiBookingsResults['error']
      >({
        method: 'post',
        url: `/v1/bi/bookings/results`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/bookings/results
 */
export function usePostV1BiBookingsResults<
  TData = PostV1BiBookingsResults['response'],
  TQueryData = PostV1BiBookingsResults['response'],
  TQueryKey extends QueryKey = PostV1BiBookingsResultsQueryKey,
>(
  data: PostV1BiBookingsResults['request'],
  headers?: PostV1BiBookingsResults['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1BiBookingsResults['response'],
        PostV1BiBookingsResults['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1BiBookingsResults['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1BiBookingsResults['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1BiBookingsResultsQueryKey(data)
  const query = useQuery({
    ...(postV1BiBookingsResultsQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1BiBookingsResults['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1BiBookingsResultsSuspenseQueryKey = (
  data: PostV1BiBookingsResults['request'],
) => [{ url: '/v1/bi/bookings/results' }, ...(data ? [data] : [])] as const
export type PostV1BiBookingsResultsSuspenseQueryKey = ReturnType<
  typeof postV1BiBookingsResultsSuspenseQueryKey
>
export function postV1BiBookingsResultsSuspenseQueryOptions(
  data: PostV1BiBookingsResults['request'],
  headers?: PostV1BiBookingsResults['headerParams'],
  options: PostV1BiBookingsResults['client']['parameters'] = {},
) {
  const queryKey = postV1BiBookingsResultsSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1BiBookingsResults['data'],
        PostV1BiBookingsResults['error']
      >({
        method: 'post',
        url: `/v1/bi/bookings/results`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/bi/bookings/results
 */
export function usePostV1BiBookingsResultsSuspense<
  TData = PostV1BiBookingsResults['response'],
  TQueryKey extends QueryKey = PostV1BiBookingsResultsSuspenseQueryKey,
>(
  data: PostV1BiBookingsResults['request'],
  headers?: PostV1BiBookingsResults['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1BiBookingsResults['response'],
        PostV1BiBookingsResults['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1BiBookingsResults['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1BiBookingsResults['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1BiBookingsResultsSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1BiBookingsResultsSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, PostV1BiBookingsResults['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
