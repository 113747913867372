// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1ProfileMutationRequest,
  PutV1ProfileMutationResponse,
  PutV1Profile401,
  PutV1Profile403,
} from '../models/PutV1Profile'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1ProfileClient = typeof client<
  PutV1ProfileMutationResponse,
  PutV1Profile401 | PutV1Profile403,
  PutV1ProfileMutationRequest
>
type PutV1Profile = {
  data: PutV1ProfileMutationResponse
  error: PutV1Profile401 | PutV1Profile403
  request: PutV1ProfileMutationRequest
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PutV1ProfileClient>>
  client: {
    parameters: Partial<Parameters<PutV1ProfileClient>[0]>
    return: Awaited<ReturnType<PutV1ProfileClient>>
  }
}
/**
 * @link /v1/profile
 */
export function usePutV1Profile(
  options: {
    mutation?: UseMutationOptions<
      PutV1Profile['response'],
      PutV1Profile['error'],
      {
        data: PutV1Profile['request']
      }
    >
    client?: PutV1Profile['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data }) => {
      const res = await client<
        PutV1Profile['data'],
        PutV1Profile['error'],
        PutV1Profile['request']
      >({
        method: 'put',
        url: `/v1/profile`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
