// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1TournamentsDataCollectionsQueryResponse,
  GetV1TournamentsDataCollectionsHeaderParams,
  GetV1TournamentsDataCollections401,
  GetV1TournamentsDataCollections403,
} from '../models/GetV1TournamentsDataCollections'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1TournamentsDataCollectionsClient = typeof client<
  GetV1TournamentsDataCollectionsQueryResponse,
  GetV1TournamentsDataCollections401 | GetV1TournamentsDataCollections403,
  never
>
type GetV1TournamentsDataCollections = {
  data: GetV1TournamentsDataCollectionsQueryResponse
  error: GetV1TournamentsDataCollections401 | GetV1TournamentsDataCollections403
  request: never
  pathParams: never
  queryParams: never
  headerParams: GetV1TournamentsDataCollectionsHeaderParams
  response: Awaited<ReturnType<GetV1TournamentsDataCollectionsClient>>
  client: {
    parameters: Partial<Parameters<GetV1TournamentsDataCollectionsClient>[0]>
    return: Awaited<ReturnType<GetV1TournamentsDataCollectionsClient>>
  }
}
export const getV1TournamentsDataCollectionsQueryKey = () =>
  [{ url: '/v1/tournaments/data-collections' }] as const
export type GetV1TournamentsDataCollectionsQueryKey = ReturnType<
  typeof getV1TournamentsDataCollectionsQueryKey
>
export function getV1TournamentsDataCollectionsQueryOptions(
  headers?: GetV1TournamentsDataCollections['headerParams'],
  options: GetV1TournamentsDataCollections['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsDataCollectionsQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsDataCollections['data'],
        GetV1TournamentsDataCollections['error']
      >({
        method: 'get',
        url: `/v1/tournaments/data-collections`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/data-collections
 */
export function useGetV1TournamentsDataCollections<
  TData = GetV1TournamentsDataCollections['response'],
  TQueryData = GetV1TournamentsDataCollections['response'],
  TQueryKey extends QueryKey = GetV1TournamentsDataCollectionsQueryKey,
>(
  headers?: GetV1TournamentsDataCollections['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1TournamentsDataCollections['response'],
        GetV1TournamentsDataCollections['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsDataCollections['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1TournamentsDataCollections['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TournamentsDataCollectionsQueryKey()
  const query = useQuery({
    ...(getV1TournamentsDataCollectionsQueryOptions(
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1TournamentsDataCollections['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1TournamentsDataCollectionsSuspenseQueryKey = () =>
  [{ url: '/v1/tournaments/data-collections' }] as const
export type GetV1TournamentsDataCollectionsSuspenseQueryKey = ReturnType<
  typeof getV1TournamentsDataCollectionsSuspenseQueryKey
>
export function getV1TournamentsDataCollectionsSuspenseQueryOptions(
  headers?: GetV1TournamentsDataCollections['headerParams'],
  options: GetV1TournamentsDataCollections['client']['parameters'] = {},
) {
  const queryKey = getV1TournamentsDataCollectionsSuspenseQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1TournamentsDataCollections['data'],
        GetV1TournamentsDataCollections['error']
      >({
        method: 'get',
        url: `/v1/tournaments/data-collections`,
        headers: { ...headers, ...options.headers },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/tournaments/data-collections
 */
export function useGetV1TournamentsDataCollectionsSuspense<
  TData = GetV1TournamentsDataCollections['response'],
  TQueryKey extends QueryKey = GetV1TournamentsDataCollectionsSuspenseQueryKey,
>(
  headers?: GetV1TournamentsDataCollections['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1TournamentsDataCollections['response'],
        GetV1TournamentsDataCollections['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1TournamentsDataCollections['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1TournamentsDataCollections['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1TournamentsDataCollectionsSuspenseQueryKey()
  const query = useSuspenseQuery({
    ...(getV1TournamentsDataCollectionsSuspenseQueryOptions(
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    GetV1TournamentsDataCollections['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
