// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsResultsModuleRankingMutationRequest,
  PostV1GenericAccountmetricsResultsModuleRankingMutationResponse,
  PostV1GenericAccountmetricsResultsModuleRankingHeaderParams,
  PostV1GenericAccountmetricsResultsModuleRanking401,
  PostV1GenericAccountmetricsResultsModuleRanking403,
} from '../models/PostV1GenericAccountmetricsResultsModuleRanking'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1GenericAccountmetricsResultsModuleRankingClient = typeof client<
  PostV1GenericAccountmetricsResultsModuleRankingMutationResponse,
  | PostV1GenericAccountmetricsResultsModuleRanking401
  | PostV1GenericAccountmetricsResultsModuleRanking403,
  PostV1GenericAccountmetricsResultsModuleRankingMutationRequest
>
type PostV1GenericAccountmetricsResultsModuleRanking = {
  data: PostV1GenericAccountmetricsResultsModuleRankingMutationResponse
  error:
    | PostV1GenericAccountmetricsResultsModuleRanking401
    | PostV1GenericAccountmetricsResultsModuleRanking403
  request: PostV1GenericAccountmetricsResultsModuleRankingMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsResultsModuleRankingHeaderParams
  response: Awaited<
    ReturnType<PostV1GenericAccountmetricsResultsModuleRankingClient>
  >
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsResultsModuleRankingClient>[0]
    >
    return: Awaited<
      ReturnType<PostV1GenericAccountmetricsResultsModuleRankingClient>
    >
  }
}
export const postV1GenericAccountmetricsResultsModuleRankingQueryKey = (
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module/ranking' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleRankingQueryKey =
  ReturnType<typeof postV1GenericAccountmetricsResultsModuleRankingQueryKey>
export function postV1GenericAccountmetricsResultsModuleRankingQueryOptions(
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleRanking['headerParams'],
  options: PostV1GenericAccountmetricsResultsModuleRanking['client']['parameters'] = {},
) {
  const queryKey = postV1GenericAccountmetricsResultsModuleRankingQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModuleRanking['data'],
        PostV1GenericAccountmetricsResultsModuleRanking['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module/ranking`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module/ranking
 */
export function usePostV1GenericAccountmetricsResultsModuleRanking<
  TData = PostV1GenericAccountmetricsResultsModuleRanking['response'],
  TQueryData = PostV1GenericAccountmetricsResultsModuleRanking['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsModuleRankingQueryKey,
>(
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleRanking['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1GenericAccountmetricsResultsModuleRanking['response'],
        PostV1GenericAccountmetricsResultsModuleRanking['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModuleRanking['client']['parameters']
  } = {},
): UseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsModuleRanking['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleRankingQueryKey(data)
  const query = useQuery({
    ...(postV1GenericAccountmetricsResultsModuleRankingQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModuleRanking['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey = (
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module/ranking' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey =
  ReturnType<
    typeof postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey
  >
export function postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryOptions(
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleRanking['headerParams'],
  options: PostV1GenericAccountmetricsResultsModuleRanking['client']['parameters'] = {},
) {
  const queryKey =
    postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModuleRanking['data'],
        PostV1GenericAccountmetricsResultsModuleRanking['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module/ranking`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module/ranking
 */
export function usePostV1GenericAccountmetricsResultsModuleRankingSuspense<
  TData = PostV1GenericAccountmetricsResultsModuleRanking['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey,
>(
  data: PostV1GenericAccountmetricsResultsModuleRanking['request'],
  headers?: PostV1GenericAccountmetricsResultsModuleRanking['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1GenericAccountmetricsResultsModuleRanking['response'],
        PostV1GenericAccountmetricsResultsModuleRanking['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModuleRanking['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsModuleRanking['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1GenericAccountmetricsResultsModuleRankingSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModuleRanking['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
