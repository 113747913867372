// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionMultipleMutationRequest,
  PutV1SurveysSurveyidQuestionMultipleMutationResponse,
  PutV1SurveysSurveyidQuestionMultiplePathParams,
  PutV1SurveysSurveyidQuestionMultipleHeaderParams,
  PutV1SurveysSurveyidQuestionMultiple401,
  PutV1SurveysSurveyidQuestionMultiple403,
} from '../models/PutV1SurveysSurveyidQuestionMultiple'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionMultipleClient = typeof client<
  PutV1SurveysSurveyidQuestionMultipleMutationResponse,
  | PutV1SurveysSurveyidQuestionMultiple401
  | PutV1SurveysSurveyidQuestionMultiple403,
  PutV1SurveysSurveyidQuestionMultipleMutationRequest
>
type PutV1SurveysSurveyidQuestionMultiple = {
  data: PutV1SurveysSurveyidQuestionMultipleMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionMultiple401
    | PutV1SurveysSurveyidQuestionMultiple403
  request: PutV1SurveysSurveyidQuestionMultipleMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionMultiplePathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionMultipleHeaderParams
  response: Awaited<ReturnType<PutV1SurveysSurveyidQuestionMultipleClient>>
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionMultipleClient>[0]
    >
    return: Awaited<ReturnType<PutV1SurveysSurveyidQuestionMultipleClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/multiple
 */
export function usePutV1SurveysSurveyidQuestionMultiple(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionMultiple['response'],
      PutV1SurveysSurveyidQuestionMultiple['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionMultiplePathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionMultiple['headerParams']
        data: PutV1SurveysSurveyidQuestionMultiple['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionMultiple['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionMultiple['data'],
        PutV1SurveysSurveyidQuestionMultiple['error'],
        PutV1SurveysSurveyidQuestionMultiple['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/multiple`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
