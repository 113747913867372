// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1AccountadminAccountidQueryResponse,
  GetV1AccountadminAccountidPathParams,
  GetV1AccountadminAccountid401,
  GetV1AccountadminAccountid403,
} from '../models/GetV1AccountadminAccountid'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1AccountadminAccountidClient = typeof client<
  GetV1AccountadminAccountidQueryResponse,
  GetV1AccountadminAccountid401 | GetV1AccountadminAccountid403,
  never
>
type GetV1AccountadminAccountid = {
  data: GetV1AccountadminAccountidQueryResponse
  error: GetV1AccountadminAccountid401 | GetV1AccountadminAccountid403
  request: never
  pathParams: GetV1AccountadminAccountidPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1AccountadminAccountidClient>>
  client: {
    parameters: Partial<Parameters<GetV1AccountadminAccountidClient>[0]>
    return: Awaited<ReturnType<GetV1AccountadminAccountidClient>>
  }
}
export const getV1AccountadminAccountidQueryKey = (
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
) =>
  [
    { url: '/v1/accountadmin/:accountId', params: { accountId: accountId } },
  ] as const
export type GetV1AccountadminAccountidQueryKey = ReturnType<
  typeof getV1AccountadminAccountidQueryKey
>
export function getV1AccountadminAccountidQueryOptions(
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
  options: GetV1AccountadminAccountid['client']['parameters'] = {},
) {
  const queryKey = getV1AccountadminAccountidQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountadminAccountid['data'],
        GetV1AccountadminAccountid['error']
      >({
        method: 'get',
        url: `/v1/accountadmin/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accountadmin/:accountId
 */
export function useGetV1AccountadminAccountid<
  TData = GetV1AccountadminAccountid['response'],
  TQueryData = GetV1AccountadminAccountid['response'],
  TQueryKey extends QueryKey = GetV1AccountadminAccountidQueryKey,
>(
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1AccountadminAccountid['response'],
        GetV1AccountadminAccountid['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1AccountadminAccountid['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1AccountadminAccountid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1AccountadminAccountidQueryKey(accountId)
  const query = useQuery({
    ...(getV1AccountadminAccountidQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1AccountadminAccountid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1AccountadminAccountidSuspenseQueryKey = (
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
) =>
  [
    { url: '/v1/accountadmin/:accountId', params: { accountId: accountId } },
  ] as const
export type GetV1AccountadminAccountidSuspenseQueryKey = ReturnType<
  typeof getV1AccountadminAccountidSuspenseQueryKey
>
export function getV1AccountadminAccountidSuspenseQueryOptions(
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
  options: GetV1AccountadminAccountid['client']['parameters'] = {},
) {
  const queryKey = getV1AccountadminAccountidSuspenseQueryKey(accountId)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1AccountadminAccountid['data'],
        GetV1AccountadminAccountid['error']
      >({
        method: 'get',
        url: `/v1/accountadmin/${accountId}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/accountadmin/:accountId
 */
export function useGetV1AccountadminAccountidSuspense<
  TData = GetV1AccountadminAccountid['response'],
  TQueryKey extends QueryKey = GetV1AccountadminAccountidSuspenseQueryKey,
>(
  accountId: GetV1AccountadminAccountidPathParams['accountId'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1AccountadminAccountid['response'],
        GetV1AccountadminAccountid['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1AccountadminAccountid['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1AccountadminAccountid['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    getV1AccountadminAccountidSuspenseQueryKey(accountId)
  const query = useSuspenseQuery({
    ...(getV1AccountadminAccountidSuspenseQueryOptions(
      accountId,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1AccountadminAccountid['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
