// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1ManualDataCollectionsDatacollectionidRespondentsMutationRequest,
  PutV1ManualDataCollectionsDatacollectionidRespondentsMutationResponse,
  PutV1ManualDataCollectionsDatacollectionidRespondentsPathParams,
  PutV1ManualDataCollectionsDatacollectionidRespondentsHeaderParams,
  PutV1ManualDataCollectionsDatacollectionidRespondents401,
  PutV1ManualDataCollectionsDatacollectionidRespondents403,
} from '../models/PutV1ManualDataCollectionsDatacollectionidRespondents'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1ManualDataCollectionsDatacollectionidRespondentsClient =
  typeof client<
    PutV1ManualDataCollectionsDatacollectionidRespondentsMutationResponse,
    | PutV1ManualDataCollectionsDatacollectionidRespondents401
    | PutV1ManualDataCollectionsDatacollectionidRespondents403,
    PutV1ManualDataCollectionsDatacollectionidRespondentsMutationRequest
  >
type PutV1ManualDataCollectionsDatacollectionidRespondents = {
  data: PutV1ManualDataCollectionsDatacollectionidRespondentsMutationResponse
  error:
    | PutV1ManualDataCollectionsDatacollectionidRespondents401
    | PutV1ManualDataCollectionsDatacollectionidRespondents403
  request: PutV1ManualDataCollectionsDatacollectionidRespondentsMutationRequest
  pathParams: PutV1ManualDataCollectionsDatacollectionidRespondentsPathParams
  queryParams: never
  headerParams: PutV1ManualDataCollectionsDatacollectionidRespondentsHeaderParams
  response: Awaited<
    ReturnType<PutV1ManualDataCollectionsDatacollectionidRespondentsClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1ManualDataCollectionsDatacollectionidRespondentsClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1ManualDataCollectionsDatacollectionidRespondentsClient>
    >
  }
}
/**
 * @link /v1/manual-data-collections/:dataCollectionId/respondents
 */
export function usePutV1ManualDataCollectionsDatacollectionidRespondents(
  options: {
    mutation?: UseMutationOptions<
      PutV1ManualDataCollectionsDatacollectionidRespondents['response'],
      PutV1ManualDataCollectionsDatacollectionidRespondents['error'],
      {
        dataCollectionId: PutV1ManualDataCollectionsDatacollectionidRespondentsPathParams['dataCollectionId']
        headers?: PutV1ManualDataCollectionsDatacollectionidRespondents['headerParams']
        data: PutV1ManualDataCollectionsDatacollectionidRespondents['request']
      }
    >
    client?: PutV1ManualDataCollectionsDatacollectionidRespondents['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ dataCollectionId, data, headers }) => {
      const res = await client<
        PutV1ManualDataCollectionsDatacollectionidRespondents['data'],
        PutV1ManualDataCollectionsDatacollectionidRespondents['error'],
        PutV1ManualDataCollectionsDatacollectionidRespondents['request']
      >({
        method: 'put',
        url: `/v1/manual-data-collections/${dataCollectionId}/respondents`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
