// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ProfilePasswordMutationRequest,
  PostV1ProfilePasswordMutationResponse,
  PostV1ProfilePassword401,
  PostV1ProfilePassword403,
} from '../models/PostV1ProfilePassword'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ProfilePasswordClient = typeof client<
  PostV1ProfilePasswordMutationResponse,
  PostV1ProfilePassword401 | PostV1ProfilePassword403,
  PostV1ProfilePasswordMutationRequest
>
type PostV1ProfilePassword = {
  data: PostV1ProfilePasswordMutationResponse
  error: PostV1ProfilePassword401 | PostV1ProfilePassword403
  request: PostV1ProfilePasswordMutationRequest
  pathParams: never
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1ProfilePasswordClient>>
  client: {
    parameters: Partial<Parameters<PostV1ProfilePasswordClient>[0]>
    return: Awaited<ReturnType<PostV1ProfilePasswordClient>>
  }
}
/**
 * @link /v1/profile/password
 */
export function usePostV1ProfilePassword(
  options: {
    mutation?: UseMutationOptions<
      PostV1ProfilePassword['response'],
      PostV1ProfilePassword['error'],
      {
        data: PostV1ProfilePassword['request']
      }
    >
    client?: PostV1ProfilePassword['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data }) => {
      const res = await client<
        PostV1ProfilePassword['data'],
        PostV1ProfilePassword['error'],
        PostV1ProfilePassword['request']
      >({
        method: 'post',
        url: `/v1/profile/password`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
