// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1ManualDataCollectionsMutationRequest,
  PostV1ManualDataCollectionsMutationResponse,
  PostV1ManualDataCollectionsHeaderParams,
  PostV1ManualDataCollections401,
  PostV1ManualDataCollections403,
} from '../models/PostV1ManualDataCollections'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1ManualDataCollectionsClient = typeof client<
  PostV1ManualDataCollectionsMutationResponse,
  PostV1ManualDataCollections401 | PostV1ManualDataCollections403,
  PostV1ManualDataCollectionsMutationRequest
>
type PostV1ManualDataCollections = {
  data: PostV1ManualDataCollectionsMutationResponse
  error: PostV1ManualDataCollections401 | PostV1ManualDataCollections403
  request: PostV1ManualDataCollectionsMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ManualDataCollectionsHeaderParams
  response: Awaited<ReturnType<PostV1ManualDataCollectionsClient>>
  client: {
    parameters: Partial<Parameters<PostV1ManualDataCollectionsClient>[0]>
    return: Awaited<ReturnType<PostV1ManualDataCollectionsClient>>
  }
}
/**
 * @link /v1/manual-data-collections
 */
export function usePostV1ManualDataCollections(
  options: {
    mutation?: UseMutationOptions<
      PostV1ManualDataCollections['response'],
      PostV1ManualDataCollections['error'],
      {
        headers?: PostV1ManualDataCollections['headerParams']
        data: PostV1ManualDataCollections['request']
      }
    >
    client?: PostV1ManualDataCollections['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ data, headers }) => {
      const res = await client<
        PostV1ManualDataCollections['data'],
        PostV1ManualDataCollections['error'],
        PostV1ManualDataCollections['request']
      >({
        method: 'post',
        url: `/v1/manual-data-collections`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
