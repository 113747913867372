// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1GenericAccountmetricsResultsModuleMutationRequest,
  PostV1GenericAccountmetricsResultsModuleMutationResponse,
  PostV1GenericAccountmetricsResultsModuleHeaderParams,
  PostV1GenericAccountmetricsResultsModule401,
  PostV1GenericAccountmetricsResultsModule403,
} from '../models/PostV1GenericAccountmetricsResultsModule'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1GenericAccountmetricsResultsModuleClient = typeof client<
  PostV1GenericAccountmetricsResultsModuleMutationResponse,
  | PostV1GenericAccountmetricsResultsModule401
  | PostV1GenericAccountmetricsResultsModule403,
  PostV1GenericAccountmetricsResultsModuleMutationRequest
>
type PostV1GenericAccountmetricsResultsModule = {
  data: PostV1GenericAccountmetricsResultsModuleMutationResponse
  error:
    | PostV1GenericAccountmetricsResultsModule401
    | PostV1GenericAccountmetricsResultsModule403
  request: PostV1GenericAccountmetricsResultsModuleMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1GenericAccountmetricsResultsModuleHeaderParams
  response: Awaited<ReturnType<PostV1GenericAccountmetricsResultsModuleClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1GenericAccountmetricsResultsModuleClient>[0]
    >
    return: Awaited<ReturnType<PostV1GenericAccountmetricsResultsModuleClient>>
  }
}
export const postV1GenericAccountmetricsResultsModuleQueryKey = (
  data?: PostV1GenericAccountmetricsResultsModule['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleQueryKey = ReturnType<
  typeof postV1GenericAccountmetricsResultsModuleQueryKey
>
export function postV1GenericAccountmetricsResultsModuleQueryOptions(
  headers?: PostV1GenericAccountmetricsResultsModule['headerParams'],
  data?: PostV1GenericAccountmetricsResultsModule['request'],
  options: PostV1GenericAccountmetricsResultsModule['client']['parameters'] = {},
) {
  const queryKey = postV1GenericAccountmetricsResultsModuleQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModule['data'],
        PostV1GenericAccountmetricsResultsModule['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module
 */
export function usePostV1GenericAccountmetricsResultsModule<
  TData = PostV1GenericAccountmetricsResultsModule['response'],
  TQueryData = PostV1GenericAccountmetricsResultsModule['response'],
  TQueryKey extends QueryKey = PostV1GenericAccountmetricsResultsModuleQueryKey,
>(
  headers?: PostV1GenericAccountmetricsResultsModule['headerParams'],
  data?: PostV1GenericAccountmetricsResultsModule['request'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1GenericAccountmetricsResultsModule['response'],
        PostV1GenericAccountmetricsResultsModule['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModule['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1GenericAccountmetricsResultsModule['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleQueryKey(data)
  const query = useQuery({
    ...(postV1GenericAccountmetricsResultsModuleQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModule['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1GenericAccountmetricsResultsModuleSuspenseQueryKey = (
  data?: PostV1GenericAccountmetricsResultsModule['request'],
) =>
  [
    { url: '/v1/generic/accountmetrics/results/module' },
    ...(data ? [data] : []),
  ] as const
export type PostV1GenericAccountmetricsResultsModuleSuspenseQueryKey =
  ReturnType<typeof postV1GenericAccountmetricsResultsModuleSuspenseQueryKey>
export function postV1GenericAccountmetricsResultsModuleSuspenseQueryOptions(
  headers?: PostV1GenericAccountmetricsResultsModule['headerParams'],
  data?: PostV1GenericAccountmetricsResultsModule['request'],
  options: PostV1GenericAccountmetricsResultsModule['client']['parameters'] = {},
) {
  const queryKey =
    postV1GenericAccountmetricsResultsModuleSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1GenericAccountmetricsResultsModule['data'],
        PostV1GenericAccountmetricsResultsModule['error']
      >({
        method: 'post',
        url: `/v1/generic/accountmetrics/results/module`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/generic/accountmetrics/results/module
 */
export function usePostV1GenericAccountmetricsResultsModuleSuspense<
  TData = PostV1GenericAccountmetricsResultsModule['response'],
  TQueryKey extends
    QueryKey = PostV1GenericAccountmetricsResultsModuleSuspenseQueryKey,
>(
  headers?: PostV1GenericAccountmetricsResultsModule['headerParams'],
  data?: PostV1GenericAccountmetricsResultsModule['request'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1GenericAccountmetricsResultsModule['response'],
        PostV1GenericAccountmetricsResultsModule['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1GenericAccountmetricsResultsModule['client']['parameters']
  } = {},
): UseSuspenseQueryResult<
  TData,
  PostV1GenericAccountmetricsResultsModule['error']
> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1GenericAccountmetricsResultsModuleSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1GenericAccountmetricsResultsModuleSuspenseQueryOptions(
      headers,
      data,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1GenericAccountmetricsResultsModule['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
