// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  GetV1SignUpEnterprisesSlugQueryResponse,
  GetV1SignUpEnterprisesSlugPathParams,
} from '../models/GetV1SignUpEnterprisesSlug'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type GetV1SignUpEnterprisesSlugClient = typeof client<
  GetV1SignUpEnterprisesSlugQueryResponse,
  Error,
  never
>
type GetV1SignUpEnterprisesSlug = {
  data: GetV1SignUpEnterprisesSlugQueryResponse
  error: Error
  request: never
  pathParams: GetV1SignUpEnterprisesSlugPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<GetV1SignUpEnterprisesSlugClient>>
  client: {
    parameters: Partial<Parameters<GetV1SignUpEnterprisesSlugClient>[0]>
    return: Awaited<ReturnType<GetV1SignUpEnterprisesSlugClient>>
  }
}
export const getV1SignUpEnterprisesSlugQueryKey = (
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
) => [{ url: '/v1/sign-up/enterprises/:slug', params: { slug: slug } }] as const
export type GetV1SignUpEnterprisesSlugQueryKey = ReturnType<
  typeof getV1SignUpEnterprisesSlugQueryKey
>
export function getV1SignUpEnterprisesSlugQueryOptions(
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
  options: GetV1SignUpEnterprisesSlug['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpEnterprisesSlugQueryKey(slug)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpEnterprisesSlug['data'],
        GetV1SignUpEnterprisesSlug['error']
      >({
        method: 'get',
        url: `/v1/sign-up/enterprises/${slug}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/enterprises/:slug
 */
export function useGetV1SignUpEnterprisesSlug<
  TData = GetV1SignUpEnterprisesSlug['response'],
  TQueryData = GetV1SignUpEnterprisesSlug['response'],
  TQueryKey extends QueryKey = GetV1SignUpEnterprisesSlugQueryKey,
>(
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetV1SignUpEnterprisesSlug['response'],
        GetV1SignUpEnterprisesSlug['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: GetV1SignUpEnterprisesSlug['client']['parameters']
  } = {},
): UseQueryResult<TData, GetV1SignUpEnterprisesSlug['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SignUpEnterprisesSlugQueryKey(slug)
  const query = useQuery({
    ...(getV1SignUpEnterprisesSlugQueryOptions(
      slug,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetV1SignUpEnterprisesSlug['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const getV1SignUpEnterprisesSlugSuspenseQueryKey = (
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
) => [{ url: '/v1/sign-up/enterprises/:slug', params: { slug: slug } }] as const
export type GetV1SignUpEnterprisesSlugSuspenseQueryKey = ReturnType<
  typeof getV1SignUpEnterprisesSlugSuspenseQueryKey
>
export function getV1SignUpEnterprisesSlugSuspenseQueryOptions(
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
  options: GetV1SignUpEnterprisesSlug['client']['parameters'] = {},
) {
  const queryKey = getV1SignUpEnterprisesSlugSuspenseQueryKey(slug)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetV1SignUpEnterprisesSlug['data'],
        GetV1SignUpEnterprisesSlug['error']
      >({
        method: 'get',
        url: `/v1/sign-up/enterprises/${slug}`,
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/sign-up/enterprises/:slug
 */
export function useGetV1SignUpEnterprisesSlugSuspense<
  TData = GetV1SignUpEnterprisesSlug['response'],
  TQueryKey extends QueryKey = GetV1SignUpEnterprisesSlugSuspenseQueryKey,
>(
  slug: GetV1SignUpEnterprisesSlugPathParams['slug'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetV1SignUpEnterprisesSlug['response'],
        GetV1SignUpEnterprisesSlug['error'],
        TData,
        TQueryKey
      >
    >
    client?: GetV1SignUpEnterprisesSlug['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, GetV1SignUpEnterprisesSlug['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? getV1SignUpEnterprisesSlugSuspenseQueryKey(slug)
  const query = useSuspenseQuery({
    ...(getV1SignUpEnterprisesSlugSuspenseQueryOptions(
      slug,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<TData, GetV1SignUpEnterprisesSlug['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
