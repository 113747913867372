// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationRequest,
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationResponse,
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams,
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidHeaderParams,
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401,
  PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403,
} from '../models/PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient =
  typeof client<
    PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationResponse,
    | PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401
    | PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403,
    PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationRequest
  >
type PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid = {
  data: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationResponse
  error:
    | PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid401
    | PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid403
  request: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidMutationRequest
  pathParams: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams
  queryParams: never
  headerParams: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidHeaderParams
  response: Awaited<
    ReturnType<PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>[0]
    >
    return: Awaited<
      ReturnType<PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidClient>
    >
  }
}
/**
 * @link /v2/surveys/:surveyId/automated-data-collections/:dataCollectionId
 */
export function usePutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid(
  options: {
    mutation?: UseMutationOptions<
      PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['response'],
      PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error'],
      {
        surveyId: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['surveyId']
        dataCollectionId: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionidPathParams['dataCollectionId']
        headers?: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['headerParams']
        data: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['request']
      }
    >
    client?: PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, dataCollectionId, data, headers }) => {
      const res = await client<
        PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['data'],
        PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['error'],
        PutV2SurveysSurveyidAutomatedDataCollectionsDatacollectionid['request']
      >({
        method: 'put',
        url: `/v2/surveys/${surveyId}/automated-data-collections/${dataCollectionId}`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
