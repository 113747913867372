// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV2SurveysSurveyidIntegrationsMemberMutationRequest,
  PutV2SurveysSurveyidIntegrationsMemberMutationResponse,
  PutV2SurveysSurveyidIntegrationsMemberPathParams,
  PutV2SurveysSurveyidIntegrationsMemberHeaderParams,
  PutV2SurveysSurveyidIntegrationsMember401,
  PutV2SurveysSurveyidIntegrationsMember403,
} from '../models/PutV2SurveysSurveyidIntegrationsMember'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV2SurveysSurveyidIntegrationsMemberClient = typeof client<
  PutV2SurveysSurveyidIntegrationsMemberMutationResponse,
  | PutV2SurveysSurveyidIntegrationsMember401
  | PutV2SurveysSurveyidIntegrationsMember403,
  PutV2SurveysSurveyidIntegrationsMemberMutationRequest
>
type PutV2SurveysSurveyidIntegrationsMember = {
  data: PutV2SurveysSurveyidIntegrationsMemberMutationResponse
  error:
    | PutV2SurveysSurveyidIntegrationsMember401
    | PutV2SurveysSurveyidIntegrationsMember403
  request: PutV2SurveysSurveyidIntegrationsMemberMutationRequest
  pathParams: PutV2SurveysSurveyidIntegrationsMemberPathParams
  queryParams: never
  headerParams: PutV2SurveysSurveyidIntegrationsMemberHeaderParams
  response: Awaited<ReturnType<PutV2SurveysSurveyidIntegrationsMemberClient>>
  client: {
    parameters: Partial<
      Parameters<PutV2SurveysSurveyidIntegrationsMemberClient>[0]
    >
    return: Awaited<ReturnType<PutV2SurveysSurveyidIntegrationsMemberClient>>
  }
}
/**
 * @link /v2/surveys/:surveyId/integrations/member
 */
export function usePutV2SurveysSurveyidIntegrationsMember(
  options: {
    mutation?: UseMutationOptions<
      PutV2SurveysSurveyidIntegrationsMember['response'],
      PutV2SurveysSurveyidIntegrationsMember['error'],
      {
        surveyId: PutV2SurveysSurveyidIntegrationsMemberPathParams['surveyId']
        headers?: PutV2SurveysSurveyidIntegrationsMember['headerParams']
        data: PutV2SurveysSurveyidIntegrationsMember['request']
      }
    >
    client?: PutV2SurveysSurveyidIntegrationsMember['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV2SurveysSurveyidIntegrationsMember['data'],
        PutV2SurveysSurveyidIntegrationsMember['error'],
        PutV2SurveysSurveyidIntegrationsMember['request']
      >({
        method: 'put',
        url: `/v2/surveys/${surveyId}/integrations/member`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
