// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1BillingsAccountidCancelSubscriptionMutationRequest,
  PostV1BillingsAccountidCancelSubscriptionMutationResponse,
  PostV1BillingsAccountidCancelSubscriptionPathParams,
  PostV1BillingsAccountidCancelSubscription401,
  PostV1BillingsAccountidCancelSubscription403,
} from '../models/PostV1BillingsAccountidCancelSubscription'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1BillingsAccountidCancelSubscriptionClient = typeof client<
  PostV1BillingsAccountidCancelSubscriptionMutationResponse,
  | PostV1BillingsAccountidCancelSubscription401
  | PostV1BillingsAccountidCancelSubscription403,
  PostV1BillingsAccountidCancelSubscriptionMutationRequest
>
type PostV1BillingsAccountidCancelSubscription = {
  data: PostV1BillingsAccountidCancelSubscriptionMutationResponse
  error:
    | PostV1BillingsAccountidCancelSubscription401
    | PostV1BillingsAccountidCancelSubscription403
  request: PostV1BillingsAccountidCancelSubscriptionMutationRequest
  pathParams: PostV1BillingsAccountidCancelSubscriptionPathParams
  queryParams: never
  headerParams: never
  response: Awaited<ReturnType<PostV1BillingsAccountidCancelSubscriptionClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1BillingsAccountidCancelSubscriptionClient>[0]
    >
    return: Awaited<ReturnType<PostV1BillingsAccountidCancelSubscriptionClient>>
  }
}
/**
 * @link /v1/billings/:accountId/cancel-subscription
 */
export function usePostV1BillingsAccountidCancelSubscription(
  options: {
    mutation?: UseMutationOptions<
      PostV1BillingsAccountidCancelSubscription['response'],
      PostV1BillingsAccountidCancelSubscription['error'],
      {
        accountId: PostV1BillingsAccountidCancelSubscriptionPathParams['accountId']
        data?: PostV1BillingsAccountidCancelSubscription['request']
      }
    >
    client?: PostV1BillingsAccountidCancelSubscription['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ accountId, data }) => {
      const res = await client<
        PostV1BillingsAccountidCancelSubscription['data'],
        PostV1BillingsAccountidCancelSubscription['error'],
        PostV1BillingsAccountidCancelSubscription['request']
      >({
        method: 'post',
        url: `/v1/billings/${accountId}/cancel-subscription`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
