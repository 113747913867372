// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PostV1SurveysSurveyidQuestionIntervalMutationRequest,
  PostV1SurveysSurveyidQuestionIntervalMutationResponse,
  PostV1SurveysSurveyidQuestionIntervalPathParams,
  PostV1SurveysSurveyidQuestionIntervalHeaderParams,
  PostV1SurveysSurveyidQuestionInterval401,
  PostV1SurveysSurveyidQuestionInterval403,
} from '../models/PostV1SurveysSurveyidQuestionInterval'
import type { UseMutationOptions } from '@tanstack/react-query'

type PostV1SurveysSurveyidQuestionIntervalClient = typeof client<
  PostV1SurveysSurveyidQuestionIntervalMutationResponse,
  | PostV1SurveysSurveyidQuestionInterval401
  | PostV1SurveysSurveyidQuestionInterval403,
  PostV1SurveysSurveyidQuestionIntervalMutationRequest
>
type PostV1SurveysSurveyidQuestionInterval = {
  data: PostV1SurveysSurveyidQuestionIntervalMutationResponse
  error:
    | PostV1SurveysSurveyidQuestionInterval401
    | PostV1SurveysSurveyidQuestionInterval403
  request: PostV1SurveysSurveyidQuestionIntervalMutationRequest
  pathParams: PostV1SurveysSurveyidQuestionIntervalPathParams
  queryParams: never
  headerParams: PostV1SurveysSurveyidQuestionIntervalHeaderParams
  response: Awaited<ReturnType<PostV1SurveysSurveyidQuestionIntervalClient>>
  client: {
    parameters: Partial<
      Parameters<PostV1SurveysSurveyidQuestionIntervalClient>[0]
    >
    return: Awaited<ReturnType<PostV1SurveysSurveyidQuestionIntervalClient>>
  }
}
/**
 * @link /v1/surveys/:surveyId/question/interval
 */
export function usePostV1SurveysSurveyidQuestionInterval(
  options: {
    mutation?: UseMutationOptions<
      PostV1SurveysSurveyidQuestionInterval['response'],
      PostV1SurveysSurveyidQuestionInterval['error'],
      {
        surveyId: PostV1SurveysSurveyidQuestionIntervalPathParams['surveyId']
        headers?: PostV1SurveysSurveyidQuestionInterval['headerParams']
        data: PostV1SurveysSurveyidQuestionInterval['request']
      }
    >
    client?: PostV1SurveysSurveyidQuestionInterval['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PostV1SurveysSurveyidQuestionInterval['data'],
        PostV1SurveysSurveyidQuestionInterval['error'],
        PostV1SurveysSurveyidQuestionInterval['request']
      >({
        method: 'post',
        url: `/v1/surveys/${surveyId}/question/interval`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
