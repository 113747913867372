// @ts-nocheck
import client from '@/lib/client-fetch'
import { useQuery, queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import type {
  PostV1ResultsPriorityMapBenchmarkMutationRequest,
  PostV1ResultsPriorityMapBenchmarkMutationResponse,
  PostV1ResultsPriorityMapBenchmarkHeaderParams,
  PostV1ResultsPriorityMapBenchmark401,
  PostV1ResultsPriorityMapBenchmark403,
} from '../models/PostV1ResultsPriorityMapBenchmark'
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'

type PostV1ResultsPriorityMapBenchmarkClient = typeof client<
  PostV1ResultsPriorityMapBenchmarkMutationResponse,
  PostV1ResultsPriorityMapBenchmark401 | PostV1ResultsPriorityMapBenchmark403,
  PostV1ResultsPriorityMapBenchmarkMutationRequest
>
type PostV1ResultsPriorityMapBenchmark = {
  data: PostV1ResultsPriorityMapBenchmarkMutationResponse
  error:
    | PostV1ResultsPriorityMapBenchmark401
    | PostV1ResultsPriorityMapBenchmark403
  request: PostV1ResultsPriorityMapBenchmarkMutationRequest
  pathParams: never
  queryParams: never
  headerParams: PostV1ResultsPriorityMapBenchmarkHeaderParams
  response: Awaited<ReturnType<PostV1ResultsPriorityMapBenchmarkClient>>
  client: {
    parameters: Partial<Parameters<PostV1ResultsPriorityMapBenchmarkClient>[0]>
    return: Awaited<ReturnType<PostV1ResultsPriorityMapBenchmarkClient>>
  }
}
export const postV1ResultsPriorityMapBenchmarkQueryKey = (
  data: PostV1ResultsPriorityMapBenchmark['request'],
) =>
  [
    { url: '/v1/results/priority-map/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsPriorityMapBenchmarkQueryKey = ReturnType<
  typeof postV1ResultsPriorityMapBenchmarkQueryKey
>
export function postV1ResultsPriorityMapBenchmarkQueryOptions(
  data: PostV1ResultsPriorityMapBenchmark['request'],
  headers?: PostV1ResultsPriorityMapBenchmark['headerParams'],
  options: PostV1ResultsPriorityMapBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsPriorityMapBenchmarkQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsPriorityMapBenchmark['data'],
        PostV1ResultsPriorityMapBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/priority-map/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/priority-map/benchmark
 */
export function usePostV1ResultsPriorityMapBenchmark<
  TData = PostV1ResultsPriorityMapBenchmark['response'],
  TQueryData = PostV1ResultsPriorityMapBenchmark['response'],
  TQueryKey extends QueryKey = PostV1ResultsPriorityMapBenchmarkQueryKey,
>(
  data: PostV1ResultsPriorityMapBenchmark['request'],
  headers?: PostV1ResultsPriorityMapBenchmark['headerParams'],
  options: {
    query?: Partial<
      QueryObserverOptions<
        PostV1ResultsPriorityMapBenchmark['response'],
        PostV1ResultsPriorityMapBenchmark['error'],
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: PostV1ResultsPriorityMapBenchmark['client']['parameters']
  } = {},
): UseQueryResult<TData, PostV1ResultsPriorityMapBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ?? postV1ResultsPriorityMapBenchmarkQueryKey(data)
  const query = useQuery({
    ...(postV1ResultsPriorityMapBenchmarkQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, PostV1ResultsPriorityMapBenchmark['error']> & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
export const postV1ResultsPriorityMapBenchmarkSuspenseQueryKey = (
  data: PostV1ResultsPriorityMapBenchmark['request'],
) =>
  [
    { url: '/v1/results/priority-map/benchmark' },
    ...(data ? [data] : []),
  ] as const
export type PostV1ResultsPriorityMapBenchmarkSuspenseQueryKey = ReturnType<
  typeof postV1ResultsPriorityMapBenchmarkSuspenseQueryKey
>
export function postV1ResultsPriorityMapBenchmarkSuspenseQueryOptions(
  data: PostV1ResultsPriorityMapBenchmark['request'],
  headers?: PostV1ResultsPriorityMapBenchmark['headerParams'],
  options: PostV1ResultsPriorityMapBenchmark['client']['parameters'] = {},
) {
  const queryKey = postV1ResultsPriorityMapBenchmarkSuspenseQueryKey(data)
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        PostV1ResultsPriorityMapBenchmark['data'],
        PostV1ResultsPriorityMapBenchmark['error']
      >({
        method: 'post',
        url: `/v1/results/priority-map/benchmark`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...options.headers,
        },
        ...options,
      })
      return res
    },
  })
}
/**
 * @link /v1/results/priority-map/benchmark
 */
export function usePostV1ResultsPriorityMapBenchmarkSuspense<
  TData = PostV1ResultsPriorityMapBenchmark['response'],
  TQueryKey extends
    QueryKey = PostV1ResultsPriorityMapBenchmarkSuspenseQueryKey,
>(
  data: PostV1ResultsPriorityMapBenchmark['request'],
  headers?: PostV1ResultsPriorityMapBenchmark['headerParams'],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        PostV1ResultsPriorityMapBenchmark['response'],
        PostV1ResultsPriorityMapBenchmark['error'],
        TData,
        TQueryKey
      >
    >
    client?: PostV1ResultsPriorityMapBenchmark['client']['parameters']
  } = {},
): UseSuspenseQueryResult<TData, PostV1ResultsPriorityMapBenchmark['error']> & {
  queryKey: TQueryKey
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {}
  const queryKey =
    queryOptions?.queryKey ??
    postV1ResultsPriorityMapBenchmarkSuspenseQueryKey(data)
  const query = useSuspenseQuery({
    ...(postV1ResultsPriorityMapBenchmarkSuspenseQueryOptions(
      data,
      headers,
      clientOptions,
    ) as unknown as UseSuspenseQueryOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<UseSuspenseQueryOptions, 'queryKey'>),
  }) as UseSuspenseQueryResult<
    TData,
    PostV1ResultsPriorityMapBenchmark['error']
  > & {
    queryKey: TQueryKey
  }
  query.queryKey = queryKey as TQueryKey
  return query
}
