// @ts-nocheck
import client from '@/lib/client-fetch'
import { useMutation } from '@tanstack/react-query'
import type {
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationRequest,
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationResponse,
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsPathParams,
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsHeaderParams,
  PutV1SurveysSurveyidQuestionSingleOnlyOptions401,
  PutV1SurveysSurveyidQuestionSingleOnlyOptions403,
} from '../models/PutV1SurveysSurveyidQuestionSingleOnlyOptions'
import type { UseMutationOptions } from '@tanstack/react-query'

type PutV1SurveysSurveyidQuestionSingleOnlyOptionsClient = typeof client<
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationResponse,
  | PutV1SurveysSurveyidQuestionSingleOnlyOptions401
  | PutV1SurveysSurveyidQuestionSingleOnlyOptions403,
  PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationRequest
>
type PutV1SurveysSurveyidQuestionSingleOnlyOptions = {
  data: PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationResponse
  error:
    | PutV1SurveysSurveyidQuestionSingleOnlyOptions401
    | PutV1SurveysSurveyidQuestionSingleOnlyOptions403
  request: PutV1SurveysSurveyidQuestionSingleOnlyOptionsMutationRequest
  pathParams: PutV1SurveysSurveyidQuestionSingleOnlyOptionsPathParams
  queryParams: never
  headerParams: PutV1SurveysSurveyidQuestionSingleOnlyOptionsHeaderParams
  response: Awaited<
    ReturnType<PutV1SurveysSurveyidQuestionSingleOnlyOptionsClient>
  >
  client: {
    parameters: Partial<
      Parameters<PutV1SurveysSurveyidQuestionSingleOnlyOptionsClient>[0]
    >
    return: Awaited<
      ReturnType<PutV1SurveysSurveyidQuestionSingleOnlyOptionsClient>
    >
  }
}
/**
 * @link /v1/surveys/:surveyId/question/single-only-options
 */
export function usePutV1SurveysSurveyidQuestionSingleOnlyOptions(
  options: {
    mutation?: UseMutationOptions<
      PutV1SurveysSurveyidQuestionSingleOnlyOptions['response'],
      PutV1SurveysSurveyidQuestionSingleOnlyOptions['error'],
      {
        surveyId: PutV1SurveysSurveyidQuestionSingleOnlyOptionsPathParams['surveyId']
        headers?: PutV1SurveysSurveyidQuestionSingleOnlyOptions['headerParams']
        data: PutV1SurveysSurveyidQuestionSingleOnlyOptions['request']
      }
    >
    client?: PutV1SurveysSurveyidQuestionSingleOnlyOptions['client']['parameters']
  } = {},
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {}
  return useMutation({
    mutationFn: async ({ surveyId, data, headers }) => {
      const res = await client<
        PutV1SurveysSurveyidQuestionSingleOnlyOptions['data'],
        PutV1SurveysSurveyidQuestionSingleOnlyOptions['error'],
        PutV1SurveysSurveyidQuestionSingleOnlyOptions['request']
      >({
        method: 'put',
        url: `/v1/surveys/${surveyId}/question/single-only-options`,
        data,
        headers: {
          'Content-Type': 'application/*+json',
          ...headers,
          ...clientOptions.headers,
        },
        ...clientOptions,
      })
      return res
    },
    ...mutationOptions,
  })
}
